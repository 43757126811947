import buildRoute from './buildRoute'

export const getCalibrationFiles = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'calibrations',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addCalibrationFile = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'calibration',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const editCalibrationFiles = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'update-calibration-file',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deactivateCalibrationFiles = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'calibration-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deleteCalibration = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'delete-calibration',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const archiveCalibrationFiles = async (calibrations: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'archive-calibration-file',
            data: calibrations,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const calibrationFileRoutes = {
    getCalibrationFiles,
    addCalibrationFile,
    archiveCalibrationFiles,
    editCalibrationFiles,
    deactivateCalibrationFiles,
    deleteCalibration,
}

export default calibrationFileRoutes
