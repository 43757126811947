import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
    Chip,
} from '@mui/material'

import { useEffect, useReducer, Fragment } from 'react'
import { useToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import { UniversalTagToolbar, SaveButton } from '../../../UniversalComponents'
import { FormSection } from '../../../UniversalComponents'
import { getUsers } from '../../../../apiRoutes/userRoutes'
import { editUserNotificationSubscriptions } from '../../../../apiRoutes'
import { basicValidator } from '../../../../utilities/functionTools'
import responseError from '../../../../context/responseError/responseError'

interface UserFormFormData {
    id?: string
    _id?: string
    subscriptionName: string
    subscriptionUsers: []
    userEmail: string
}
interface UserFormTypes {
    row: any
    formDescription?: string
    onClose?: any
    saveCache?: any
    minimizeModal?: () => void
    userAccount?: boolean
}

const UserNotificationSubscriptionForm = ({
    row,
    formDescription,
    onClose = () => {},
    saveCache = () => {},
    minimizeModal,
    userAccount = false,
}: UserFormTypes) => {
    const setToastMessage = useToastMessage()
    const { setMinimized, restoredData } = useMinimizer()

    const defaultState = {
        formData: {
            id: row?._id || '',
            subscriptionName: row?.subscriptionName || '',
            subscribedUsers:
                row?.subscribedUsers && row?.subscribedUsers.length !== 0
                    ? row?.subscribedUsers
                    : [],
        },
        formValidation: {
            subscriptionNameValidation: false,
            subscribedUsersDirty: false,
            isFormDirty: false,
            isFormValid: false,
        },
        isFormDirty: false,
        isFormValid: false,
        closeModal: false,
        users: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, users, formValidation } = state

    const {
        subscriptionNameValidation,
        subscribedUsersDirty,
        isFormDirty,
        isFormValid,
    } = formValidation

    const { subscriptionName, subscribedUsers } = formData

    const handleSubmit = async () => {
        try {
            let parsedFormData = {
                ...formData,
                subscribedUsers,
            }

            let res: any

            res = await editUserNotificationSubscriptions(parsedFormData)
            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `User: ${data.subscriptionName} was edited successfully`,
                        'success'
                    )
                    onClose(row.subscriptionName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`Admin password is incorrect.`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,

            formValidation: defaultState.formValidation,
        })

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'subscribedUsers') {
            setState({
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                    subscribedUsersDirty: true,
                },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        }
    }

    const getUsersData = async () => {
        try {
            let data = await getUsers(true)

            if (data && data.length) {
                data = data.map(({ userEmail, _id }) => ({
                    userEmail,
                    id: _id,
                    _id,
                }))

                setState({
                    users: data,
                })
            } else {
                throw new Error('No manufacturer data can be found')
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
        }
    }
    useEffect(() => {
        getUsersData()
        saveCache(row)
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            subscriptionName &&
            !subscriptionNameValidation &&
            subscribedUsers.length !== 0
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        subscriptionName,
        subscriptionNameValidation,
        subscribedUsers,
    ])

    useEffect(() => {
        if (restoredData?.id !== undefined) {
            setState({ row: restoredData, open: true })
        }
    }, [restoredData])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1, marginTop: 3 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {row.userEmail
                        ? 'Use this form to add or remove users to/from a subscription.'
                        : `${formDescription}`}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <UniversalTagToolbar />
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormSection
                            title="Notification Subscription Name"
                            titleAlignment="center"
                        >
                            <Grid item md={12} xs={12}>
                                <TextField
                                    required={true}
                                    label="Subscription Name"
                                    name="subscriptionName"
                                    fullWidth
                                    onChange={handleChange}
                                    value={subscriptionName}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={
                                        subscriptionNameValidation === true &&
                                        !subscriptionName
                                    }
                                    helperText={
                                        subscriptionNameValidation === false &&
                                        !subscriptionName
                                            ? 'Please enter a valid subscription name.'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Autocomplete
                                    multiple
                                    id="subscribedUsers"
                                    value={subscribedUsers}
                                    onBlur={() =>
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                subscribedUsersDirty: true,
                                            },
                                        })
                                    }
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'subscribedUsers',
                                                value: newValue,
                                            },
                                        }

                                        handleChange(data)
                                    }}
                                    options={users}
                                    getOptionLabel={(option) =>
                                        option.userEmail
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={option.userEmail}
                                                {...getTagProps({ index })}
                                                disabled={
                                                    subscribedUsers.indexOf(
                                                        index
                                                    ) !== -1
                                                }
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="subscribedUsers"
                                            label="Subscribed Users"
                                            placeholder="Subscribed Users"
                                            error={
                                                subscribedUsers?.length === 0 &&
                                                subscribedUsersDirty
                                            }
                                            helperText={
                                                subscribedUsers?.length === 0 &&
                                                subscribedUsersDirty
                                                    ? 'You must have at least one user selected.'
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                        saveTitle={!userAccount ? 'Save & Close' : 'Save'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserNotificationSubscriptionForm
