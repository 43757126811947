import { Grid, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { Authentication } from '../../../../context/AuthenticationContext'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { addMeasurement, getSignalFormulas } from '../../../../apiRoutes'
import {
    FormSection,
    UniversalTagToolbar,
    SaveButton,
    Autocomplete,
} from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'

const UnitOfMeasurementForm = ({
    row,
    formDescription,
    saveCache,
    onClose,
    permissionCheck,
}) => {
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth
    const setToastMessage = useContext(ToastMessage)

    const defaultState = {
        formData: {
            id: row.id || '',
            unitOfMeasurement: row.unitOfMeasurement || '',
            unitOfMeasurementAbrv: row.unitOfMeasurementAbrv || '',
            unitOfMeasurementConversion: row.unitOfMeasurementConversion || {
                signalFormulaName: '',
            },
            unitOfMeasurementConversionAbrv:
                row.unitOfMeasurementConversionAbrv || '',
        },
        formValidation: {
            unitOfMeasurementValidation: false,
            unitOfMeasurementAbrvValidation: false,
            unitOfMeasurementConversionValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        modifiedBy: row.modifiedBy || '',
        signalFormulas: [],
        permissionsCheck: permissionCheck,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, formValidation, signalFormulas } = state

    const {
        unitOfMeasurement,
        unitOfMeasurementAbrv,
        unitOfMeasurementConversion,
        unitOfMeasurementConversionAbrv,
    } = formData

    const {
        unitOfMeasurementValidation,
        unitOfMeasurementAbrvValidation,
        unitOfMeasurementConversionValidation,
        unitOfMeasurementConversionAbrvValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleSubmit = async () => {
        try {
            const parsedFormData = { ...formData, updatedBy: username }
            let res: any
            res = await addMeasurement(parsedFormData)
            if (res) {
                const { data, status } = res
                if (data && status === 200) {
                    setToastMessage(
                        `Sensor: ${data.unitOfMeasurement} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    onClose(row.unitOfMeasurement, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })

        saveCache({ ...formData, [name]: value })
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const getData = async () => {
        try {
            let data = await getSignalFormulas(true)
            if (data && data?.length !== 0) {
                setState({
                    signalFormulas: data,
                })
            } else {
                throw new Error(
                    'There has been an issue fetching signal formulas.'
                )
            }
        } catch (error: any) {
            setToastMessage(error, 'error')
            console.error(error, 'error')
        }
    }

    useEffect(() => {
        if (
            isFormDirty &&
            !unitOfMeasurementValidation &&
            !unitOfMeasurementAbrvValidation &&
            unitOfMeasurement &&
            unitOfMeasurementAbrv
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        unitOfMeasurementValidation,
        unitOfMeasurementAbrvValidation,
        unitOfMeasurement,
        unitOfMeasurementAbrv,
    ])

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 10, marginBottom: -5 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {formDescription}
                </Typography>
            </Grid>

            <UniversalTagToolbar />

            <Grid container spacing={2} sx={{ marginTop: -5 }}>
                <FormSection
                    title={' Measurement Values'}
                    titleAlignment="left"
                >
                    <Grid item xs={12} md={7}>
                        <TextField
                            required={true}
                            name="unitOfMeasurement"
                            label="Unit of Measurement"
                            fullWidth
                            value={unitOfMeasurement}
                            onChange={handleChange}
                            onBlur={validateOnBlur}
                            error={
                                unitOfMeasurementValidation &&
                                !unitOfMeasurement
                            }
                            helperText={
                                unitOfMeasurementValidation &&
                                !unitOfMeasurement
                                    ? 'Please enter a Unit Of Measurement.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            required={true}
                            name="unitOfMeasurementAbrv"
                            label="Measurement Abbreviation"
                            fullWidth
                            value={unitOfMeasurementAbrv}
                            onChange={handleChange}
                            onBlur={validateOnBlur}
                            error={
                                unitOfMeasurementAbrvValidation &&
                                !unitOfMeasurementAbrv
                            }
                            helperText={
                                unitOfMeasurementAbrvValidation &&
                                !unitOfMeasurementAbrv
                                    ? 'Please enter a Unit Of Measurement Abbreviation.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                </FormSection>
                <FormSection title="Conversion Values" titleAlignment="left">
                    <Grid item xs={12} md={7}>
                        <Autocomplete
                            options={signalFormulas.map(
                                ({ signalFormulaName }) => signalFormulaName
                            )}
                            getOptionLabel={(option) => option}
                            value={
                                unitOfMeasurementConversion.signalFormulaName
                            }
                            handleChange={(e) => {
                                const { name, value } = e.target
                                const signalFormula = signalFormulas.find(
                                    ({ signalFormulaName }) =>
                                        signalFormulaName === value
                                )
                                const target = {
                                    name: name,
                                    value: signalFormula,
                                }
                                handleChange({ target })
                            }}
                            required={true}
                            label="Unit of Measurement Conversion Formula"
                            name="unitOfMeasurementConversion"
                            helperText={
                                !unitOfMeasurementConversion
                                    ? 'Please select a conversion formula'
                                    : ''
                            }
                            error={unitOfMeasurementConversionValidation}
                            onBlur={(e) => {
                                setState({
                                    formValidation: {
                                        ...formValidation,
                                        isFormDirty: true,
                                    },
                                })
                            }}
                            clearFunction={() =>
                                setState({
                                    formValidation: {
                                        ...formValidation,
                                        isFormDirty: true,
                                    },
                                    formData: {
                                        ...formData,
                                        unitOfMeasurementConversion: {
                                            signalFormulaName: '',
                                        },
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            required={true}
                            name="unitOfMeasurementConversionAbrv"
                            label="Conversion Measurement Abbreviation"
                            fullWidth
                            value={unitOfMeasurementConversionAbrv}
                            onChange={handleChange}
                            onBlur={validateOnBlur}
                            error={
                                unitOfMeasurementConversionAbrvValidation &&
                                !unitOfMeasurementConversionAbrv
                            }
                            helperText={
                                unitOfMeasurementConversionAbrvValidation &&
                                !unitOfMeasurementConversionAbrv
                                    ? 'Please enter a Unit Of Measurement Conversion Abbreviation.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                </FormSection>

                <Grid item xs={12} md={12} sx={{ marginTop: -2 }}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UnitOfMeasurementForm
