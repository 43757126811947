import buildRoute from './buildRoute'

export const getUserNotificationSubscriptions = async () => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-notification-subscriptions',
        })
        if (res.data) {
            const { data } = res
            return data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const editUserNotificationSubscriptions = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-notification-subscriptions-edit',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        return console.error(err)
    }
}
