import buildRoute from './buildRoute'

export const getSignalFormulas = async (active: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-formulas',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const postSignalFormula = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-formula',
            data: formData,
        })
        if (res) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    signals: []
    activate: boolean
}
export const activateSignalFormulas = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-formula-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    signals: []
    activate: boolean
}
export const deleteSignalFormulas = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-formula-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
