import { useState, createContext, useContext } from 'react'
import OzChatModal from './OzChat'

type Props = {
    children: React.ReactNode
}

export const OzChat = createContext(() => {})

export const OzChatContext = ({ children }: Props) => {
    const [open, setOpen] = useState(false)

    return (
        <OzChat.Provider value={() => setOpen(true)}>
            {children}
            <OzChatModal />
        </OzChat.Provider>
    )
}

export const useOzChat = () => useContext(OzChat)
