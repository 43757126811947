import {
    Button,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Box,
} from '@mui/material'
import { useReducer, useEffect, useContext } from 'react'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { FormSection } from '../../UniversalComponents'
import { deactivateUserRequest } from '../../../apiRoutes/userRoutes'
import logo from '../../../assets/osmosis/Logos/darklogo.png'
import watershedLogo from '../../../assets/osmosis/Logos/watershed-innovations.png'
const DeactivateAccount = () => {
    const defaultState = {
        formData: {
            userEmail: '',
            agreementCheckbox: false,
        },
        formValidation: {
            userEmailValidation: false,
            userFirstNameValidation: false,
            userLastNameValidation: false,
            userPhoneNumberValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        isFormDirty: false,
        isFormValid: false,
        closeModal: false,
        brands: [],
        fleet: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const { formData, formValidation } = state

    const { userEmail, agreementCheckbox } = formData
    const { userEmailValidation, isFormDirty, isFormValid } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })
    }

    useEffect(() => {
        const emailValid = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(userEmail)
        }
        if (
            isFormDirty &&
            !userEmailValidation &&
            emailValid() &&
            userEmail &&
            agreementCheckbox
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [isFormDirty, userEmailValidation, userEmail, agreementCheckbox])

    const emailCheck = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(userEmail)
    }
    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    const handleSubmit = async () => {
        try {
            let res: any
            res = await deactivateUserRequest(userEmail)
            if (res) {
                const { status } = res
                console.log(res, 'res')
                if (status === 200) {
                    setToastMessage(
                        `Your email has been sent successfully. You may close this window.`,
                        'success'
                    )
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `100%` },

                backgroundColor: 'primary',
            }}
        >
            <Grid container>
                <FormSection>
                    <Grid
                        item
                        xs={6}
                        style={{ alignContent: 'center', textAlign: 'center' }}
                    >
                        <img src={logo} width="80%" alt="Osmosis" />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{ alignContent: 'center', textAlign: 'center' }}
                    >
                        <img
                            src={watershedLogo}
                            width="80%"
                            alt="Watershed Innovations"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            color={'primary'}
                            align="center"
                            sx={{ margin: 2 }}
                        >
                            Deactivate your account with Watershed Innovations
                            DBA Osmosis
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required={true}
                            label="E-Mail"
                            name="userEmail"
                            fullWidth
                            onChange={handleChange}
                            value={userEmail}
                            onBlur={validateOnBlur}
                            error={
                                userEmailValidation === false && userEmail
                                    ? !emailCheck()
                                    : userEmailValidation && !userEmail
                            }
                            helperText={
                                userEmailValidation === false && !userEmail
                                    ? userEmailValidation && !userEmail
                                    : !emailCheck()
                                    ? 'Please enter a valid Email Address.'
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    required
                                    onChange={() =>
                                        setState({
                                            formData: {
                                                ...formData,
                                                agreementCheckbox:
                                                    !agreementCheckbox,
                                            },
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                    }
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                    id="agreementCheckbox"
                                    name="agreementCheckbox"
                                    checked={agreementCheckbox}
                                    key="agreementCheckbox"
                                />
                            }
                            label="I understand that deactivating my account removes my boats' telemetry and access to all Osmosis Apps"
                            key="Agree"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!isFormValid}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </FormSection>
            </Grid>
        </Box>
    )
}

export default DeactivateAccount
