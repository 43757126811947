import { useReducer, useContext, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import {
    UniversalToolBar,
    UniversalDataTable,
    UniversalModal,
    UniversalLoadingCircle,
    FormSection,
} from '../../../UniversalComponents'
import EngineModelManagerForm from './EngineModelManagerForm'
import AddBoxIcon from '@mui/icons-material/AddBox'
import {
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    DeleteForever,
    Restore,
} from '@mui/icons-material/'

import {
    getEngineModels,
    deleteEngineModel,
    deactivateEngineModels,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const EngineModelManager = ({ restoredData }): any => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth
    const userPermissions = usePermissions()

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        calibrationFiles: [],
        formCache: {},
        archive: [],
        multipleSelection: [],
        activateData: [],
        viewDeactivated: false,
        data: [],
        engines: {
            engineModel: {
                columns: [
                    {
                        field: 'engineModelName',
                        headerName: 'ENGINE MODEL',
                        width: 300,
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 300,
                    },
                    {
                        field: 'engineModelYear',
                        headerName: 'MODEL YEAR',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.engines?.engineModel?.density ||
            'standard',
    }
    const defaultColumnVisibility = {
        id: true,
        engineMake: true,
        engineModel: true,
        uploadAt: true,
        calibrationFileId: true,
        updatedBy: true,
        hardwarePartNumber: true,
        ecmPartNumber: true,
    }
    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        formCache,
        viewDeactivated,
        data,
        activateData,
        engines,
        density,
    } = state

    const { engineModel } = engines

    const getData = async (active) => {
        try {
            const engineModels = await getEngineModels(active)
            if (engineModels && engineModels.length !== 0) {
                const models = standardColumnMapper(
                    defaultColumns,
                    engineModels
                )
                setState({ data: models })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const engines =
            userPreferences?.dataTableColumns?.engines?.engineModel?.columns
        let setDensity = ''

        if (Array.isArray(engines)) {
            const mapColumns = engines.map((engine) => ({
                field: engine?.field,
                headerName: engine?.headerName,
                width: engine?.width,
                isVisible: engine.isVisible,
            }))

            const format = {
                engineModel: {
                    columns: mapColumns,
                    density: setDensity,
                },
            }

            setState({ ...state, engines: format })
        } else {
            console.log('No Engines Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'engines',
                value: engines,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'engineModelName',
            setState,
            getData,
            route: deactivateEngineModels,
            viewDeactivated,
            setToastMessage,
            page: 'engineModels',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'engineModelName',
            setState,
            getData,
            route: deleteEngineModel,
            viewDeactivated,
            setToastMessage,
            page: 'engineModels',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Engine Models?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.engineModelName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.engineModel
                ? `Edit: ${formCache.engineModel}`
                : 'Add New Engine Model',
            link: '/admin/engine-models',
            data: formCache.id
                ? { ...formCache, tab: 'engines' }
                : { ...formCache, id: 'newModel', tab: 'engines' },
            id: formCache.id ? formCache.id : 'newModel',
        })
        setState({ open: false })
    }

    const buttons = [
        {
            buttonName: 'Add New Engine Model',
            buttonIcon: <AddBoxIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Engine Models'
                : 'View Deactivated Engine Models',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
    ]

    const defaultColumns = [
        'manufacturer.manufacturerName',
        'engineModel.engineModelName',
        'engineModel.engineModelYear',
        'engineModel.manufacturer._id',
        'updatedAt',
        'updatedBy',
        'archived',
        'createdAt',
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setEngines = {
            engineModel: { columns: format, density: setDensity },
        }

        setState({
            engines: setEngines,
        })
    }

    const handleUpdatedVisibility = (columnVisibilityModel) => {
        const updatedVisibility = engines?.columns.reduce((acc, col) => {
            if (col.field in columnVisibilityModel) {
                acc[col.field] = {
                    ...col,
                    isVisible: columnVisibilityModel[col.field],
                }
            } else {
                acc[col.field] = col
            }

            return acc
        }, {})

        const updatedColumns = Object.values(updatedVisibility)

        const setDensity = state.density

        const setengineModel = {
            columns: updatedColumns,
            density: setDensity,
        }

        setState({ ...state, engines: setengineModel })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            engines: {
                engineModel: {
                    ...engineModel,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Engine Models'
                                : 'Deactivated Engine Models'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Engine Models Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={engines?.engineModel?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({ activateData: newSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        getColumnHeaderVisibility={handleUpdatedVisibility}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>

                <UniversalModal
                    title={`${
                        row.engineModelName
                            ? `Edit Engine Model: ${row.engineModelName}`
                            : 'Add New Engine Model'
                    }`}
                    timeStamp={`${
                        row.updatedBy
                            ? ` ${
                                  row.updatedBy
                                      ? ` Modified By ${row.updatedBy}`
                                      : ''
                              } ${
                                  row.updatedAt
                                      ? `| Modified Date ${row.updatedAt
                                            .toString()
                                            .substring(0, 10)}`
                                      : ''
                              }  `
                            : ` Modified By: ${username} | Modified Date: ${today} `
                    }`}
                    open={open}
                    onClose={(tableRow) =>
                        setModalVisbility(tableRow, undefined)
                    }
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                    }}
                    onMinimize={minimizeModal}
                    draggable={true}
                    minimizable={true}
                >
                    <EngineModelManagerForm
                        row={row}
                        formDescription={'Create New Engine Model'}
                        onClose={(tableRow: any, newData: any) =>
                            setModalVisbility(tableRow, newData)
                        }
                        saveCache={(data: any) => setState({ formCache: data })}
                        minimizeModal={minimizeModal}
                        permissionCheck={permissionsCheck(
                            userPermissions,
                            'Engines'
                        )}
                    />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default EngineModelManager
