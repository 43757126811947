import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../context/ToastPopUpContext'
import {
    FormSection,
    SaveButton,
    UniversalTagToolbar,
} from '../UniversalComponents'
import { Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './styles/quillStyle.css'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { datePickerConverter } from '../../utilities/functionTools'
import responseError from '../../context/responseError/responseError'
import { addPatchNote } from '../../apiRoutes'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

const UniversialPatchNotesForm = ({
    row,
    permissionCheck,
    onClose,
    saveCache,
    patchNoteTypeDenotation,
}) => {
    const setToastMessage = useContext(ToastMessage)

    const defaultState = {
        formData: {
            patchDetails: row.patchDetails || '',
            releaseDate: row.releaseDate
                ? datePickerConverter(row.releaseDate)
                : '',
            version: row.version || '',
            upcomingPatchVersion: row.upcomingPatchVersion || '',
            upcomingPatchDetails: row.upcomingPatchDetails || '',
            patchNoteType: patchNoteTypeDenotation,
            id: row.id || '',
        },
        formValidation: {
            isFormDirty: false,
            isFormValid: true,
            patchDetailsValidation: false,
            versionValidation: false,
            releaseDateValidation: false,
            upcomingPatchVersionValidation: false,
            upcomingPatchDetailsValidation: false,
        },
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, formValidation } = state
    const {
        patchDetails,
        releaseDate,
        version,
        upcomingPatchVersion,
        upcomingPatchDetails,
        patchNoteType,
    } = formData
    const {
        isFormDirty,
        isFormValid,
        patchDetailsValidation,
        releaseDateValidation,
        versionValidation,
        upcomingPatchVersionValidation,
        upcomingPatchDetailsValidation,
    } = formValidation

    const handleBodyChange = (content, delta, source, editor) => {
        setState({
            formData: { ...formData, patchDetails: content },
            formValidation: {
                ...formValidation,
                isFormDirty: true,
            },
        })
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
            formValidation: {
                ...formValidation,
                isFormDirty: true,
            },
        })
        saveCache({ ...formData, [name]: value })
    }

    const handleSubmit = async () => {
        try {
            const parsedFormData = {
                ...formData,
            }

            let res: any

            res = await addPatchNote(parsedFormData)
            if (res) {
                const { data, status } = res
                if (data && status === 200) {
                    setToastMessage(
                        `Patch Note: ${data.version} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    onClose(row.version, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const toolbarOptions = [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ]

    const modules = {
        toolbar: toolbarOptions,
    }

    const handleReset = () =>
        setState({
            formData: {
                ...formData,
                releaseDate: defaultState.formData.releaseDate,
                version: defaultState.formData.version,
                upcomingPatchVersion:
                    defaultState.formData.upcomingPatchVersion,
                upcomingPatchDetails:
                    defaultState.formData.upcomingPatchDetails,
            },
            formValidation: defaultState.formValidation,
        })

    const handlePatchNoteReset = () =>
        setState({
            formValidation: {
                ...formValidation,
                isFormDirty: false,
                patchDetailsValidation:
                    defaultState.formValidation.patchDetailsValidation,
            },
            formData: {
                ...formData,
                patchDetails: defaultState.formData.patchDetails,
            },
        })

    useEffect(() => {
        if (
            isFormDirty &&
            !patchDetailsValidation &&
            !releaseDateValidation &&
            !upcomingPatchVersionValidation &&
            !upcomingPatchDetailsValidation &&
            !versionValidation &&
            patchDetails &&
            releaseDate &&
            version &&
            upcomingPatchVersion &&
            upcomingPatchDetails
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        patchDetailsValidation,
        releaseDateValidation,
        upcomingPatchVersionValidation,
        upcomingPatchDetailsValidation,
        versionValidation,
        patchDetails,
        releaseDate,
        version,
        upcomingPatchVersion,
        upcomingPatchDetails,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 2, marginBottom: 3 }} md={12}>
                <Typography align="center" variant="h5" color={'primary'}>
                    This will create a new Patch Note for Osmosis Web App
                </Typography>
            </Grid>

            <UniversalTagToolbar />

            <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormSection
                            title={'New Release Details'}
                            titleAlignment="left"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    name="version"
                                    label="Release Version"
                                    fullWidth
                                    value={version}
                                    onChange={handleChange}
                                    disabled={permissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label="Release Date"
                                        views={['year', 'month', 'day']}
                                        value={releaseDate}
                                        onChange={({ $d }) => {
                                            const event = {
                                                target: {
                                                    value: $d,
                                                    name: 'releaseDate',
                                                },
                                            }
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                            handleChange(event)
                                        }}
                                        sx={{ width: '100%' }}
                                        slotProps={{
                                            textField: {
                                                error: false,
                                                required: true,
                                            },
                                        }}
                                        disabled={permissionCheck}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </FormSection>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSection
                            title={'Upcoming Release Details'}
                            titleAlignment="left"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    name="upcomingPatchVersion"
                                    label="Upcoming Patch Version"
                                    fullWidth
                                    value={upcomingPatchVersion}
                                    onChange={handleChange}
                                    disabled={permissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    name="upcomingPatchDetails"
                                    label=" Upcoming Patch Summary"
                                    fullWidth
                                    value={upcomingPatchDetails}
                                    onChange={handleChange}
                                    disabled={permissionCheck}
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormSection
                        title={'Patch Note'}
                        titleAlignment="left"
                        reset={
                            <IconButton onClick={handlePatchNoteReset}>
                                <Tooltip title="Reset Patch Note">
                                    <RestartAltIcon />
                                </Tooltip>
                            </IconButton>
                        }
                    >
                        <Grid item xs={12} md={12}>
                            <ReactQuill
                                value={patchDetails}
                                onChange={handleBodyChange}
                                modules={modules}
                                placeholder="Patch Notes...."
                                className="custom-quill-theme"
                            />
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: -2 }}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid || permissionCheck}
                        disabledReset={!isFormDirty || permissionCheck}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UniversialPatchNotesForm
