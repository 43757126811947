import { Grid } from '@mui/material'

interface UniversalDataGridLayoutTypes {
    cookieCrumbs?: React.ReactNode
    toolbar?: React.ReactNode
    dataGrid: React.ReactNode
    tabBar?: React.ReactNode
}

export const UniversalDataGridLayout = ({
    cookieCrumbs,
    toolbar,
    tabBar,
    dataGrid,
}: UniversalDataGridLayoutTypes) => {
    return (
        <Grid container spacing={2} sx={{ marginTop: 10 }}>
            <Grid item xs={12}>
                <Grid container sx={{ bgcolor: 'background.paper' }}>
                    <Grid item xs={12} sx={{ zIndex: 100, marginTop: -1 }}>
                        {cookieCrumbs}
                    </Grid>
                    {toolbar && (
                        <Grid
                            item
                            xs={12}
                            sx={{ marginTop: -3, zIndex: 99, padding: 2 }}
                        >
                            {toolbar}
                        </Grid>
                    )}
                    {tabBar && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                zIndex: 99,
                                paddingLeft: 2,
                                paddingRight: 2,
                                bgcolor: 'background.paper',
                                marginTop: -1,
                            }}
                        >
                            {tabBar}
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        sx={
                            toolbar
                                ? {
                                      marginTop: -12,
                                      padding: 2,
                                      bgcolor: 'background.paper',
                                  }
                                : { bgcolor: 'background.paper' }
                        }
                    >
                        {toolbar ? (
                            dataGrid
                        ) : (
                            <Grid container>
                                <Grid item xs={12}>
                                    {dataGrid}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const UniversalLayouts = {
    UniversalDataGridLayout,
}

export default UniversalLayouts
