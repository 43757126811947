import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
    Toolbar,
    Divider,
    ListItem,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer,
    Collapse,
    Box,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useNavMenu } from '../../context/NavMenuContext'
import darklogo from '../../assets/osmosis/Logos/darklogo.png'
import { navPermissionsCheck } from '../../utilities/functionTools'
import { usePermissions } from '../../context/AuthenticationContext'

const NavMenuDrawer = ({
    handleDrawerToggle,
    mobileOpen,
    container,
    drawerWidth,
}) => {
    const { navMenuLinks } = useNavMenu()
    const [state, setNewState] = useState({})
    const setState = (newState) => setNewState({ ...state, ...newState })
    const userPermissions = usePermissions()
    const isSuperAdmin = userPermissions.userRole !== 'Super Admin'

    // This is where you style the menu items
    const menuDrawer = (
        <div>
            <Toolbar style={{ marginBottom: 8 }}>
                <NavLink to="/">
                    <Box
                        component="img"
                        sx={{
                            height: 35,
                            marginLeft: -1,
                            position: 'relative',
                            top: 5,
                        }}
                        alt="Osmosis"
                        src={darklogo}
                    />
                </NavLink>
            </Toolbar>
            <Divider />
            <List key="main-list">
                {
                    // Add icon in the map here and on the child .map
                }
                {navMenuLinks.map(
                    ({ path, name, children, icon, superAdmin }) => {
                        if (!children) {
                            return (
                                <ListItem
                                    key={`${name}.li`}
                                    disablePadding
                                    component={NavLink}
                                    to={path}
                                    sx={{ color: 'inherit' }}
                                >
                                    <ListItemButton key={`${name}.li.button`}>
                                        <ListItemIcon
                                            key={`${name}.li.button.icon`}
                                            children={icon}
                                        />

                                        <ListItemText
                                            sx={{ color: 'inherit' }}
                                            primary={name}
                                            key={`${name}.li.button.text`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        } else {
                            const hasPermission = children?.some(
                                (child) =>
                                    navPermissionsCheck(
                                        userPermissions,
                                        child.name
                                    ) === false
                            )
                            const superAdminProtected =
                                isSuperAdmin && superAdmin === true

                            if (hasPermission && !superAdminProtected) {
                                return (
                                    <div key={`${name}.div`}>
                                        <ListItemButton
                                            onClick={() =>
                                                setState({
                                                    [name]: !state[name],
                                                })
                                            }
                                            key={`${name}.li.button`}
                                        >
                                            <ListItemIcon
                                                key={`${name}.li.button.icon`}
                                                children={icon}
                                            />

                                            <ListItemText
                                                primary={name}
                                                key={`${name}.li.text`}
                                            />
                                            {state[name] ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ListItemButton>

                                        <Collapse
                                            in={state[name]}
                                            timeout="auto"
                                            unmountOnExit
                                            key={`${name}.li.collapse`}
                                        >
                                            <List
                                                component="div"
                                                disablePadding
                                                key={`${name}.li.collapse.list`}
                                            >
                                                {children?.map((child) => {
                                                    if (
                                                        navPermissionsCheck(
                                                            userPermissions,
                                                            child.name
                                                        ) === false
                                                    ) {
                                                        return (
                                                            <ListItemButton
                                                                sx={{
                                                                    pl: 4,
                                                                    color: 'inherit',
                                                                }}
                                                                key={`${child.name}.li.button`}
                                                                component={
                                                                    NavLink
                                                                }
                                                                to={child.path}
                                                            >
                                                                <ListItemText
                                                                    key={`${child.name}.li.text`}
                                                                    primary={
                                                                        child.name
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </List>
                                        </Collapse>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        }
                    }
                )}
            </List>
        </div>
    )
    return (
        <>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                    },
                }}
            >
                {menuDrawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                    },
                }}
                open
            >
                {menuDrawer}
            </Drawer>
        </>
    )
}

export default NavMenuDrawer
