import buildRoute from './buildRoute'

export const getAuthentication = async (uid: object) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'authenticate',
            data: uid,
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
