import {
    Autocomplete,
    Button,
    Switch,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Paper,
    Fab,
    IconButton,
} from '@mui/material'

import { useReducer, useEffect, useContext } from 'react'
import { addBoat, editBoat, getEngines } from '../../../../apiRoutes'
import { datePickerConverter } from '../../../../utilities/functionTools'
import { getDevices } from '../../../../apiRoutes'
import { getDealers } from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { getUsers } from '../../../../apiRoutes'
import { getManufacturers } from '../../../../apiRoutes'
import { getBoatModels } from '../../../../apiRoutes'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import {
    FormSection,
    Autocomplete as UniversalAutocomplete,
    SaveButton,
} from '../../../UniversalComponents'

import { UniversalGoogleMapsAc } from '../../../UniversalComponents/UniversalFormStyles'

interface BoatRegistrationFormProps {
    row?: any
    saveCache?: any
    onClose?: any
}

const BoatRegistrationForm = () => {
    const setToastMessage = useContext(ToastMessage)
    const defaultState = {
        formData: {
            shortHin: '00001',
            fullHin: 'USCTC00001C334',
            manufacturerName: {
                manufacturerName: 'Nautique',
            },
            modelYear: '2024',
            engineMake: {
                engineMake: 'PCM',
            },
            engineSerial: '',
            deviceId: '',
            deviceSerial: '',
            model: { model: 'G23' },
            dmsOwnerEmail: '',
            dealerEmail: '',
            gpsEnabled: false,
            dealerVisibility: true,
            engineModel: { engineModel: '' },
            dealerName: { dealerName: '' },
            ownerName: '',
            salesStatus: '',
            boatModelId: '',
            dealerId: '',
            id: '',
            customerAddress: '',
        },
        formValidation: {
            fullHinValidation: false,
            shortHinValidation: false,
            manufacturerNameValidation: false,
            modelValidation: false,
            deviceSerialValidation: false,
            engineSerialValidation: false,
            dealerNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        closeModal: false,
        defaultValue: '' || null,
        setDefaultValue: '' || null,
        setInputValue: '',
        inputValue: '',
        boatManufacturers: [],
        engineManufacturers: [],
        engines: [],
        boatModels: [],
        dealers: [],
        boats: [],
        devices: [],
        selectedManufacturer: '',
        selectedEngineManufacturer: '',
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        devices,
        dealers,
        users,
        boatManufacturers,
        engineManufacturers,
        engines,
        boatModels,
        selectedManufacturer,
        selectedEngineManufacturer,
        boats,
    } = state

    const {
        fullHin,
        shortHin,
        manufacturerName,
        engineMake,
        engineSerial,
        deviceId,
        deviceSerial,
        model,
        dmsOwnerEmail,

        gpsEnabled,
        dealerVisibility,

        dealerName,
        ownerName,
        customerAddress,
    } = formData

    const {
        fullHinValidation,
        shortHinValidation,
        manufacturerNameValidation,
        modelValidation,
        deviceSerialValidation,
        isFormDirty,
        isFormValid,
        engineSerialValidation,
        dealerNameValidation,
    } = formValidation

    const getDeviceData = async () => {
        try {
            const devices = await getDevices(false)

            if (devices && devices.length !== 0) {
                if (deviceId) {
                    const findDeviceSerial = devices.find(
                        (device) => device.id === deviceId
                    )
                    return setState({
                        devices,
                        deviceSerial: findDeviceSerial.deviceSerial,
                    })
                } else {
                    return setState({ devices })
                }
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const getDealerData = async () => {
        try {
            const dealers = await getDealers(true)
            if (dealers && dealers.length !== 0) {
                setState({ dealers })
            }
        } catch (err: any) {
            setToastMessage(`${err.message}`, 'error')
            console.error(`${err.message}`)
        }
    }

    const getUserEmail = async () => {
        try {
            const users = await getUsers(true)
            if (users && users.length !== 0) {
                setState({ users })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const getBoatModel = async () => {
        try {
            const boatModels = await getBoatModels(true)
            if (boatModels && boatModels.length !== 0) {
                setState({ boatModels })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const getData = async () => {
        await getUserEmail()

        await getDeviceData()
        await getDealerData()
        await getBoatManufacturerData()
        await getEngineManufacturerData()
        await getBoatModel()
        return getEngineModelData()
    }

    useEffect(() => {
        if (
            isFormDirty &&
            !fullHinValidation &&
            !shortHinValidation &&
            !manufacturerNameValidation &&
            !modelValidation &&
            !deviceSerialValidation &&
            !engineSerialValidation &&
            !dealerNameValidation &&
            engineSerial &&
            dealerName.dealerName &&
            fullHin &&
            shortHin &&
            manufacturerName &&
            model &&
            deviceSerial
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        fullHinValidation,
        shortHinValidation,
        manufacturerNameValidation,
        modelValidation,
        deviceSerialValidation,
        engineSerialValidation,
        dealerNameValidation,
        engineSerial,
        dealerName.dealerName,
        fullHin,
        shortHin,
        manufacturerName,
        model,
        deviceSerial,
    ])

    const handleChange = (e: any) => {
        const { name, value } = e.target

        if (name === 'deviceSerial') {
            const findDeviceId =
                devices.length !== 0 &&
                devices?.find((device) => device.deviceSerial === value)

            if (findDeviceId) {
                setState({
                    formData: {
                        ...formData,
                        [name]: value ? value : '',
                        deviceId: findDeviceId?.id ? findDeviceId?.id : '',
                    },
                })
            }
        } else if (name === 'manufacturerName') {
            const selectedManufacturer = value
            const selectedManufacturerId = boatManufacturers.find(
                (manufacturer) =>
                    manufacturer.manufacturerName === selectedManufacturer
            )?.id

            const updatedFormData = {
                ...formData,
                manufacturerName: {
                    manufacturerName: value,
                },
            }

            setState({
                formData: updatedFormData,
                selectedManufacturer: selectedManufacturerId,
            })
        } else if (name === 'model') {
            const selectedModelId = boatModels.find(
                (model) => model.model === value
            )?.id

            const updatedFormData = {
                ...formData,
                model: { model: value },
                boatModelId: selectedModelId,
            }

            setState({
                formData: updatedFormData,
            })
        } else if (name === 'dealerName') {
            const selectedDealerId = dealers.find(
                (dealer) => dealer.dealerName === value
            )?.id

            const updatedFormData = {
                ...formData,
                dealerName: { dealerName: value },
                dealerId: selectedDealerId,
            }

            setState({
                formData: updatedFormData,
            })
        } else if (name === 'engineModel') {
            const selectedEngineIdString = engines.find(
                (model) => model.engineModel === value
            )?.id

            const selectedEngineId = parseInt(selectedEngineIdString, 10)

            const updatedFormData = {
                ...formData,
                engineModel: { engineModel: value },
                engineId: selectedEngineId,
            }

            setState({
                formData: updatedFormData,
            })
        } else if (name === 'engineMake') {
            const selectedEngineManufacturer = value
            const selectedEngineManufacturerId = engineManufacturers.find(
                (manufacturer) =>
                    manufacturer.manufacturerName === selectedEngineManufacturer
            )?.id

            const updatedFormData = {
                ...formData,
                engineMake: {
                    engineMake: value,
                },
            }

            setState({
                formData: updatedFormData,
                selectedEngineManufacturer: selectedEngineManufacturerId,
            })
        } else if (name === 'dmsOwnerEmail') {
            const selectedOwner = users.find(
                (owner) => owner.userEmail === value
            )

            const updatedFormData = {
                ...formData,
                dmsOwnerEmail: value,
                ownerName: selectedOwner
                    ? `${selectedOwner.userFirstName} ${selectedOwner.userLastName}`
                    : '',
            }

            setState({
                formData: updatedFormData,
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
    }

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (name === 'fullHin') {
            if (value && value.length === 14) {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },

                    formData: {
                        ...formData,
                        shortHin: `${value.substring(5, 10)}`,
                    },
                })
            } else {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,
                        isFormDirty: true,
                    },

                    formData: {
                        ...formData,
                        shortHin: '',
                    },
                })
            }
        } else if (name === 'manufacturerName') {
            if (value !== '') {
                const selectedManufacturerId = boatManufacturers.find(
                    (manufacturer) => manufacturer.manufacturerName === value
                )?.id

                setState({
                    selectedManufacturer: selectedManufacturerId,
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },
                })
            } else {
                setState({
                    selectedManufacturer: '',
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,
                        isFormDirty: true,
                    },
                })
            }
        } else {
            if (value !== '') {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },
                })
            } else {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,
                        isFormDirty: true,
                    },
                })
            }
        }
    }
    const handleReset = () => {
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })
    }
    const handleDeviceReset = () => {
        setState(defaultState)
    }

    const handleSubmit = async () => {
        try {
            const parsedFormData = { ...formData }

            delete parsedFormData.deviceSerial
            let res: any
            if (fullHin) {
                res = await editBoat({ ...parsedFormData })

                if (res) {
                    const { data, status } = res
                    if (data && status === 200) {
                        setToastMessage(
                            `${data.fullHin} was edited successfully`,
                            'success'
                        )
                    } else {
                        throw new Error(`ERROR ${status}`)
                    }
                }
            } else {
                res = await addBoat(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `Boat: ${fullHin} was added successfully`,
                            'success'
                        )
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }
    const getEngineManufacturerData = async () => {
        try {
            const engineManufacturers = await getManufacturers(true)

            if (engineManufacturers && engineManufacturers.length !== 0) {
                setState({ engineManufacturers })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const getBoatManufacturerData = async () => {
        try {
            const boatManufacturers = await getManufacturers(true)

            if (boatManufacturers && boatManufacturers.length !== 0) {
                setState({ boatManufacturers })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const getEngineModelData = async () => {
        try {
            const engines = await getEngines(true)

            if (engines && engines.length !== 0) {
                setState({ engines: engines.reverse() })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const extractDeviceSerial = (devices) =>
        devices
            .filter((fullHin) => !fullHin.fullHin)
            .map((device) => device.deviceSerial)

    const deviceSerialNumbers =
        devices && devices.length !== 0 ? extractDeviceSerial(devices) : []

    const extractDealers = (dealers) =>
        dealers.map((dealers) => dealers.dealerName)

    const mapDealerName =
        dealers && dealers.length !== 0 ? extractDealers(dealers) : []

    const extractOwnerEmail = (users) => users.map((users) => users.userEmail)

    const mapOwnerEmail =
        users && users.length !== 0 ? extractOwnerEmail(users) : []

    const extractBoatModel = (boatModels) =>
        boatModels.map((model) => model.model)

    const extractBoatManufacturerName = (boatManufacturers) =>
        boatManufacturers.map((manufacturer) => manufacturer.manufacturerName)

    const mapBoatModel =
        boatModels && boatModels.length !== 0
            ? extractBoatModel(boatModels)
            : []

    const mapBoatManufacturerName =
        boatManufacturers && boatManufacturers.length !== 0
            ? extractBoatManufacturerName(boatManufacturers)
            : []

    const filterModelsByManufacturerId = (selectedManufacturerId) => {
        if (selectedManufacturerId) {
            const filteredModels = boatModels.filter(
                (model) => model.boatManufacturerId === selectedManufacturerId
            )
            const modelNames = filteredModels.map((model) => model.model)
            return modelNames
        } else {
            return []
        }
    }

    const filterEngineModelsByManufacturerId = (
        selectedEngineManufacturerId
    ) => {
        if (selectedEngineManufacturerId) {
            const filteredModels = engines.filter(
                (model) =>
                    model.engineManufacturerId === selectedEngineManufacturerId
            )
            const modelNames = filteredModels.map((model) => model.engineModel)
            return modelNames
        } else {
            return []
        }
    }

    const extractEngineManufacturerName = (engineManufacturers) => {
        return engineManufacturers.map((item) => item.manufacturerName)
    }

    const mapEngineManufacturerName =
        engineManufacturers && engineManufacturers.length !== 0
            ? extractEngineManufacturerName(engineManufacturers)
            : []

    const extractEngineModel = (engines) =>
        engines.map((engines) => engines.engineModel)

    const mapEngineModel =
        engines && engines.length !== 0 ? extractEngineModel(engines) : []

    const clearFunction = () => {
        setState({
            deviceSerial: '',
            deviceId: '',
        })
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (Array.isArray(users) && users.length > 0) {
            const selectedOwner = users.find(
                (owner) => owner.userEmail === dmsOwnerEmail
            )

            if (selectedOwner) {
                const { userFirstName, userLastName } = selectedOwner
                const fullName = `${userFirstName} ${userLastName}`
                setState({
                    formData: {
                        ...formData,
                        ownerName: fullName,
                    },
                })
            }
        }
    }, [dmsOwnerEmail, users])

    useEffect(() => {
        const getDeviceSerial = async () => {
            try {
                if (deviceId && Array.isArray(devices) && devices.length > 0) {
                    const findDevice = devices.find(
                        (device) => device.id === deviceId
                    )
                    if (findDevice) {
                        setState({
                            formData: {
                                ...formData,
                                deviceSerial: findDevice.deviceSerial,
                            },
                        })
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }

        getDeviceSerial()
    }, [deviceId, devices])

    return (
        <Grid container spacing={2} style={{ marginTop: 100 }}>
            <Grid container spacing={2} sx={{ marginTop: -5 }}>
                {
                    /// Left side of Grid
                }
                <Grid item xs={12} md={9}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <FormSection title="Boat Info">
                                <Grid item xs={12}></Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        name="fullHin"
                                        label="HIN"
                                        disabled={!!fullHin}
                                        value={fullHin}
                                        onChange={handleChange}
                                        onBlur={validateOnBlur}
                                        error={
                                            fullHinValidation &&
                                            fullHin.length !== 14
                                        }
                                        helperText={
                                            fullHinValidation &&
                                            fullHin.length !== 14
                                                ? 'Full HIN must be 14 characters long.'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        name="shortHin"
                                        label="Short Hin"
                                        disabled
                                        value={shortHin}
                                        error={shortHinValidation && !shortHin}
                                        helperText={
                                            shortHinValidation && !shortHin
                                                ? 'Please enter a enter a full Hin to populate the short Hin field.'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalAutocomplete
                                        options={mapBoatManufacturerName}
                                        value={
                                            manufacturerName.manufacturerName
                                        }
                                        disabled
                                        handleChange={handleChange}
                                        required={true}
                                        onBlur={validateOnBlur}
                                        label="Boat Manufacturer"
                                        name="manufacturerName"
                                        error={
                                            manufacturerNameValidation &&
                                            !manufacturerName.manufacturerName
                                        }
                                        helperText={
                                            manufacturerNameValidation &&
                                            !manufacturerName.manufacturerName
                                                ? 'Please select a Enter A Manufacturer Name.'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalAutocomplete
                                        id="model"
                                        disabled
                                        onBlur={validateOnBlur}
                                        options={filterModelsByManufacturerId(
                                            selectedManufacturer
                                        )}
                                        handleChange={handleChange}
                                        value={model.model}
                                        fullWidth
                                        label="Boat Model"
                                        name="model"
                                        error={modelValidation && !model.model}
                                        helperText={
                                            modelValidation && !model.model
                                                ? 'Please enter a select a Boat Model.'
                                                : ''
                                        }
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <FormSection title="Owner Info">
                                    <Grid item xs={4}>
                                        <TextField
                                            required={true}
                                            fullWidth
                                            name="firstName"
                                            label="First Name"
                                            onChange={handleChange}
                                            onBlur={validateOnBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required={true}
                                            fullWidth
                                            name="lastName"
                                            label="Last Name"
                                            onChange={handleChange}
                                            onBlur={validateOnBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required={true}
                                            fullWidth
                                            name="email"
                                            label="Email"
                                            onChange={handleChange}
                                            onBlur={validateOnBlur}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <FormSection title="Address">
                                    <Grid item xs={12}>
                                        <UniversalGoogleMapsAc
                                            name="customerAddress"
                                            value={customerAddress}
                                            label="Customer Address"
                                            fullWidth={true}
                                            clearFunction={() =>
                                                setState({
                                                    formData: {
                                                        ...formData,
                                                        customerAddress: '',
                                                    },
                                                })
                                            }
                                            onChange={(value) => {
                                                if (value?.location) {
                                                    const {
                                                        location,
                                                        newValue,
                                                    } = value
                                                    setState({
                                                        formData: {
                                                            ...formData,
                                                            customerAddress:
                                                                location.street,
                                                        },
                                                    })

                                                    setState({
                                                        isFormDirty: true,
                                                    })
                                                }
                                            }}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Grid container sx={{ marginBottom: 2 }}>
                        <FormSection>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ marginTop: -1 }}>
                                    <Typography
                                        align="left"
                                        variant="h5"
                                        color={'primary'}
                                    >
                                        GPS Info
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={() =>
                                                    setState({
                                                        formData: {
                                                            ...formData,
                                                            gpsEnabled:
                                                                !gpsEnabled,
                                                        },
                                                        isFormDirty: true,
                                                    })
                                                }
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                                id="switch.gpsEnabled"
                                                name="switch.gpsEnabled"
                                                checked={gpsEnabled}
                                                key="switch.gpsEnabled"
                                            />
                                        }
                                        label="GPS Enabled"
                                        key="switch.gpsEnabled"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={() =>
                                                    setState({
                                                        formData: {
                                                            ...formData,
                                                            dealerVisibility:
                                                                !dealerVisibility,
                                                        },
                                                        isFormDirty: true,
                                                    })
                                                }
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                                id="switch.dealerVisibility"
                                                name="switch.dealerVisibilty"
                                                checked={dealerVisibility}
                                                key="switch.dealerVisibilty"
                                            />
                                        }
                                        label="Boat Visible to Dealer"
                                        key="switch.dealerVisibilty"
                                    />
                                </Grid>
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BoatRegistrationForm
