import React, { useRef, useEffect } from 'react'
import SendIcon from '@mui/icons-material/Send'
import {
    Paper,
    Grid,
    Box,
    Divider,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Fab,
} from '@mui/material'
import {
    FormSection,
    UniversalCookieCrumb,
} from '../../components/UniversalComponents'

interface OzChatBoxTypes {
    modal?: boolean
}

const OzChatBox = ({ modal }: OzChatBoxTypes) => {
    const [inputText, setInputText] = React.useState('')
    const [messages, setMessages] = React.useState([
        {
            text: 'I am OZ. How may I help you James Smith?',
            timestamp: '',
            align: 'right',
        },
        // ... (your existing messages)
    ])

    const ozResponses = [
        "I'm processing that information.",
        'Interesting question. Let me think...',
        'Give me a moment to check my data banks.',
        'James, can you ever own too many boats? Because I think you would look amazing driving our newest model.',
        'James you can always ask me for help and I will do my best.',
        'James the open water can be a lonely place, but I am always here for you, so are you ever truly alone?',
        'Your Boat James Boat has travelled 822 miles in the last 24 hours.',
        'Your Boat James Boat has had 1523 hours of engine time in the last 8 months. Can I book a maintence appointment at Big Richards Boatyard for you?',
    ]

    const chatListRef = useRef<HTMLUListElement>(null)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value)
    }

    const getRandomResponse = () => {
        const randomIndex = Math.floor(Math.random() * ozResponses.length)
        return ozResponses[randomIndex]
    }

    const handleSend = () => {
        if (inputText.trim() === '') return // Ignore empty messages

        const userMessage = {
            text: inputText,
            timestamp: new Date().toLocaleTimeString(),
            align: 'left',
        }

        const ozResponse = {
            text: getRandomResponse(),
            timestamp: new Date().toLocaleTimeString(),
            align: 'right',
        }

        setMessages([...messages, userMessage, ozResponse])
        setInputText('')
    }

    useEffect(() => {
        // Scroll to the bottom of the chat list when messages are updated
        if (chatListRef.current) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight
        }
    }, [messages])

    const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault() // Prevents the default behavior of the enter key in a text area
            handleSend()
        }
    }

    return (
        <Grid container spacing={2}>
            {!modal && (
                <Grid item xs={12} sx={{ marginTop: 10 }}>
                    <UniversalCookieCrumb customTitle="OZ AI BETA" />
                </Grid>
            )}
            <Grid item xs={12} id="analyticsWrapper">
                <Paper
                    sx={{
                        height: '40vh',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Typography
                        align="center"
                        variant="h5"
                        color={'primary'}
                        sx={{ padding: 1, marginTop: 3 }}
                    >
                        'Chat With James Smith'
                    </Typography>

                    <List
                        ref={chatListRef}
                        sx={{ flex: '1', maxHeight: '70vh', overflowY: 'auto' }}
                    >
                        {messages.map((message, index) => (
                            <ListItem key={index}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <ListItemText
                                            sx={{
                                                align: message.align,
                                                paddingBottom: 1,
                                            }}
                                            primary={message.text}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ListItemText
                                            sx={{ align: message.align }}
                                            secondary={message.timestamp}
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <Grid
                        container
                        alignItems="center"
                        sx={{ marginTop: 1, padding: 1 }}
                    >
                        <Grid item xs={11}>
                            <TextField
                                style={{ width: '100%' }}
                                required={true}
                                name="Send Message To Oz"
                                label="Type your message here"
                                value={inputText}
                                onChange={handleInputChange}
                                onKeyPress={handleEnterKey}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Fab
                                color="primary"
                                aria-label="add"
                                style={{
                                    marginLeft: 10,
                                    padding: '20px',
                                    borderRadius: '75%', // Make it a circle
                                    boxShadow:
                                        '0px 3px 10px rgba(0, 0, 0, 0.1)',
                                }}
                                onClick={handleSend}
                            >
                                <SendIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default OzChatBox
