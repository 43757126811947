import { useReducer, useContext, useEffect } from 'react'
import EngineeringIcon from '@mui/icons-material/Engineering'
import {
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    DeleteForever,
    Restore,
} from '@mui/icons-material/'
import { Grid, Typography } from '@mui/material'
import {
    UniversalToolBar,
    UniversalDataTable,
    UniversalModal,
    UniversalLoadingCircle,
    FormSection,
} from '../../../UniversalComponents'
import CalibrationFileManagerForm from './CalibrationFileManagerForm'
import {
    deactivateCalibrationFiles,
    deleteCalibration,
    getCalibrationFiles,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const CalibrationFileManager = ({ restoredData }): any => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth
    const userPermissions = usePermissions()

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        calibrationFile: [],
        filteredCalibrationFiles: [],
        formCache: {},
        archive: [],
        multipleSelection: [],
        activateData: [],
        viewDeactivated: false,
        data: [],
        engines: {
            engineCalibration: {
                columns: [
                    {
                        field: 'calibrationFileName',
                        headerName: 'CALIBRATION FILE NAME',
                        width: 300,
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 300,
                    },
                    {
                        field: 'custSoftwarePartNumber',
                        headerName: 'SOFTWARE PART NUMBER',
                        width: 150,
                    },
                    {
                        field: 'activeFile',
                        headerName: 'ACTIVE FILE',
                        width: 150,
                    },
                    {
                        field: 'deployedAt',
                        headerName: 'DEPLOYED AT',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.engines?.engineCalibration
                ?.density || 'standard',
    }
    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        formCache,
        viewDeactivated,
        data,
        activateData,
        engines,
        density,
    } = state

    const { engineCalibration } = engines

    const getData = async (active) => {
        try {
            const calibrationFiles = await getCalibrationFiles(active)
            if (calibrationFiles && calibrationFiles.length !== 0) {
                const models = standardColumnMapper(
                    defaultColumns,
                    calibrationFiles
                )

                setState({ data: models })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const engines =
            userPreferences?.dataTableColumns?.engines?.engineCalibration
                ?.columns
        let setDensity = ''

        if (Array.isArray(engines)) {
            const mapColumns = engines.map((engine) => ({
                field: engine?.field,
                headerName: engine?.headerName,
                width: engine?.width,
                isVisible: engine.isVisible,
            }))

            const format = {
                engineCalibration: {
                    columns: mapColumns,
                    density: setDensity,
                },
            }

            setState({ ...state, engines: format })
        } else {
            console.log('No Engines Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'engines',
                value: engines,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'calibrationFileName',
            setState,
            getData,
            route: deactivateCalibrationFiles,
            viewDeactivated,
            setToastMessage,
            page: 'calibration',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'calibrationFileName',
            setState,
            getData,
            route: deleteCalibration,
            viewDeactivated,
            setToastMessage,
            page: 'calibration',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Calibration Files?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.calibrationFileName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.calibrationFile
                ? `Edit: ${formCache.calibrationFile}`
                : 'Add New CalibrationFile',
            link: '/admin/engines',
            data: formCache.id
                ? { ...formCache, tab: 'calibration' }
                : { ...formCache, id: 'newCalibrationl', tab: 'calibration' },
            id: formCache.id ? formCache.id : 'newCalibrationl',
        })
        setState({ open: false })
    }

    const buttons = [
        {
            buttonName: 'Add New Engine Calibration File',
            buttonIcon: <EngineeringIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Calibration Files'
                : 'View Deactivated Calibration Files',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
    ]

    const defaultColumns = [
        'manufacturer.manufacturerName',
        'calibrationFileName',
        'custSoftwarePartNumber',
        'activeFile',
        'deployedAt',
        'calibrationFileUrl',
        'uploadedBy',
        'updatedBy',
        'calibrationFileChecksum',
        'calibrationFileId',
        'archived',
        'active',
    ]

    const visibleColumns = [
        'manufacturerName',
        'calibrationFileName',
        'custSoftwarePartNumber',
        'activeFile',
        'deployedAt',
    ]

    const customColumnNames = [
        'Manufacturer',
        'Calibration File Name',
        'Software Part Number',
        'Active File',
        'Deployed At',
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setEngines = {
            engineCalibration: { columns: format, density: setDensity },
        }

        setState({
            engines: setEngines,
        })
    }

    const handleUpdatedVisibility = (columnVisibilityModel) => {
        const updatedVisibility = engines?.columns.reduce((acc, col) => {
            if (col.field in columnVisibilityModel) {
                acc[col.field] = {
                    ...col,
                    isVisible: columnVisibilityModel[col.field],
                }
            } else {
                acc[col.field] = col
            }

            return acc
        }, {})

        const updatedColumns = Object.values(updatedVisibility)

        const setDensity = state.density

        const setEngineCalibration = {
            columns: updatedColumns,
            density: setDensity,
        }

        setState({ ...state, engines: setEngineCalibration })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            engines: {
                engineCalibration: {
                    ...engineCalibration,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Calibration Files'
                                : 'Deactivated Calibration Files'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Calibration Files Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={engines?.engineCalibration?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        customColumnNames={customColumnNames}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({ activateData: newSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        getColumnHeaderVisibility={handleUpdatedVisibility}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>

                <UniversalModal
                    title={`${
                        row.manufacturer
                            ? ` ${row.manufacturerName}`
                            : 'Calibration File Uploader'
                    }`}
                    timeStamp={`${
                        row.calibrationFileName
                            ? ` ${
                                  row.updatedBy
                                      ? ` Modified By ${row.updatedBy}`
                                      : ''
                              } ${
                                  row.updatedAt
                                      ? `| Modified Date ${row.updatedAt.substring(
                                            0,
                                            10
                                        )}`
                                      : ''
                              }  `
                            : ` Modified By: ${username} | Modified Date: ${today} `
                    }`}
                    open={open}
                    onClose={(tableRow) =>
                        setModalVisbility(tableRow, undefined)
                    }
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                    }}
                    onMinimize={minimizeModal}
                    draggable={true}
                    minimizable={true}
                >
                    <CalibrationFileManagerForm
                        row={row}
                        formDescription={'Calibration File Upload'}
                        onClose={(tableRow: any, newData: any) =>
                            setModalVisbility(tableRow, newData)
                        }
                        permissionCheck={permissionsCheck(
                            userPermissions,
                            'Engines'
                        )}
                    />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default CalibrationFileManager
