import buildRoute from './buildRoute'

export const getEngineModels = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'engine-models',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addEngineModel = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'engine-model',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deactivateEngineModels = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'engine-model-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deleteEngineModel = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'engine-model-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const archiveEngineModels = async (models: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'archive-engine-model',
            data: models,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const engineModelRoutes = {
    getEngineModels,
    addEngineModel,
    archiveEngineModels,
    deleteEngineModel,
    deactivateEngineModels,
}

export default engineModelRoutes
