import { useEffect, createContext, useContext, useReducer } from 'react'
import { io } from 'socket.io-client'
import currentConfig from '../config'

export const Socket = createContext({
    socket: {
        emit: (room: string, data?: any) => {},
        on: (room: string, callback: (data?: any) => void): void => {},
        id: '',
    },
    socketState: {},
    setSocketState: (e: any) => {},
})

const url: any = `${currentConfig.API_URL}/client`
const corsConfig = {
    withCredentials: false,
}

export const SocketContext = ({ children }) => {
    const socket = io(url, corsConfig)
    const defaultState = {}
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    useEffect(() => {
        socket.on('connect', () => {
            if (socket.connected) {
                console.info(`Connected with socket id: ${socket.id}`)
            } else {
                console.error('error!')
            }
        })
    }, [])

    return (
        <Socket.Provider
            value={{
                socket,
                socketState: state,
                setSocketState: setState,
            }}
        >
            {children}
        </Socket.Provider>
    )
}

export const useSocket = () => useContext(Socket)
