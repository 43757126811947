import { useReducer, useEffect } from 'react'
import { pingAPI, pingMongo, pingRedis, pingSocket } from '../../../apiRoutes'
import { useSocket } from '../../../context/SocketContext'
import {
    Grid,
    Typography,
    LinearProgress,
    CircularProgress,
    Button,
} from '@mui/material'

const SystemMonitor = () => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const defaultState = {
        apiStatus: false,
        mongoStatus: false,
        redisStatus: false,
        socketStatus: false,
        loading: true,
    }

    const [state, setState] = useReducer(reducer, defaultState)
    const { socket } = useSocket()

    const { apiStatus, mongoStatus, redisStatus, socketStatus, loading } = state

    const getData = async () => {
        let pingSocketStatus: boolean = false
        socket.on('pingSocket', (status) => {
            if (status) {
                pingSocketStatus = status
                return pingSocketStatus
            }
        })
        await pingSocket()

        const pingAPIStatus = await pingAPI()
        const pingMongoStatus = await pingMongo()
        const pingRedisStatus = await pingRedis()

        setState({
            apiStatus: pingAPIStatus,
            mongoStatus: pingMongoStatus,
            redisStatus: pingRedisStatus,
            socketStatus: pingSocketStatus,
            loading: false,
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const getStatus = (
        statusName: string,
        pingState: boolean,
        color:
            | 'info'
            | 'primary'
            | 'error'
            | 'warning'
            | 'secondary'
            | 'success' = 'info'
    ) => {
        if (loading && !pingState) {
            return (
                <Typography variant="h5" color={color} align="left">
                    {statusName}: <CircularProgress size={24} />
                </Typography>
            )
        }

        if (!loading && pingState) {
            return (
                <Typography variant="h5" color={color} align="left">
                    {statusName}: UP
                </Typography>
            )
        }

        if (!loading && !pingState) {
            return (
                <Typography variant="h5" color={'error'} align="left">
                    {statusName}: DOWN
                </Typography>
            )
        }
    }
    return (
        <Grid container spacing={2} sx={{ marginTop: 10 }}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={7}>
                        <Typography variant="h2" color={'info'} align="left">
                            SYSTEM MONITORING
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setState(defaultState)
                                getData()
                            }}
                            fullWidth
                            sx={{
                                height: 55,
                                marginTop: 1,
                            }}
                        >
                            RUN SYSTEM TEST
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {getStatus('API STATUS', apiStatus)}
                {!apiStatus ? (
                    <LinearProgress color="info" style={{ height: 10 }} />
                ) : (
                    <LinearProgress
                        color={!apiStatus ? 'error' : 'primary'}
                        variant="determinate"
                        value={100}
                        style={{ height: 10 }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {getStatus('MONGO STATUS', mongoStatus)}
                {!apiStatus ? (
                    <LinearProgress color="info" style={{ height: 10 }} />
                ) : (
                    <LinearProgress
                        color={!mongoStatus ? 'error' : 'primary'}
                        variant="determinate"
                        value={100}
                        style={{ height: 10 }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {getStatus('REDIS STATUS', redisStatus)}
                {!apiStatus ? (
                    <LinearProgress color="info" style={{ height: 10 }} />
                ) : (
                    <LinearProgress
                        color={!redisStatus ? 'error' : 'primary'}
                        variant="determinate"
                        value={100}
                        style={{ height: 10 }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {getStatus('SOCKET STATUS', socketStatus)}
                {!apiStatus ? (
                    <LinearProgress color="info" style={{ height: 10 }} />
                ) : (
                    <LinearProgress
                        color={!socketStatus ? 'error' : 'primary'}
                        variant="determinate"
                        value={100}
                        style={{ height: 10 }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default SystemMonitor
