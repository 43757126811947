import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useReducer, useEffect, useContext } from 'react'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { UniversalTagToolbar, FormSection } from '../../UniversalComponents'
import AnalyticsChartFormStack from './AnalyticsChartFormComponents/AnalyticsChartFormStacks'
import AnalyticsChartNode from './AnalyticsChartNode'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { basicValidator } from '../../../utilities/functionTools'

const dataMeasure1 = 19
const dataMeasure2 = 48
const previewData = {
    chartName: 'GPS Pie Chart',
    chartType: 'Doughnut',
    labels: ['GPS Enabled Boats', 'GPS Disabled Boats'],
    datasets: [
        {
            label: '# of Boats',
            data: [dataMeasure1, dataMeasure2],
            backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 2,
        },
    ],
}

const AnalyticsChartForm = ({
    selectedChart,
    formDescription,
    onClose,
    saveCache,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const defaultState = {
        formData: {
            chartType: selectedChart.chartType || '',
            chartName: selectedChart.chartName || '',
            labels: selectedChart.labels || [],
            dataSets: selectedChart.dataSets || [],
            chartLabel: '',
        },
        formValidation: {
            chartTypeValidation: false,
            chartNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, formValidation } = state

    const { chartType, chartName, labels, dataSets, chartLabel } = formData

    const {
        chartTypeValidation,
        chartNameValidation,
        chartLabelValidation,
        isFormDirty,
        isFormValid,
    } = formValidation
    useEffect(() => {
        if (
            isFormDirty &&
            !chartTypeValidation &&
            !chartNameValidation &&
            !chartLabelValidation &&
            chartType &&
            chartName &&
            chartLabel
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        chartTypeValidation,
        chartNameValidation,
        chartLabelValidation,
        chartType,
        chartName,
        chartLabel,
    ])

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setState({
            formData: { ...formData, [name]: value },
        })
        saveCache({ ...formData, [name]: value })
    }

    const handleReset = () => {
        setState(defaultState)
    }

    const handleSubmit = async () => {
        try {
            const parsedFormData = { ...formData }
            let res: any
            if (selectedChart) {
                res = undefined
                if (res) {
                    const { data, status } = res
                    if (data && status === 200) {
                        setToastMessage(
                            `Chart: ${data.chartName} was edited successfully`
                        )
                        onClose(selectedChart.shortHin, data)
                    }
                }
            } else {
                res = undefined
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `Chart: ${data.chartName} was added successfully`
                        )
                        onClose(selectedChart.shortHin, data)
                    } else {
                        throw new Error(`ERROR ${status}`)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(err.message, 'error')
        }
    }

    useEffect(() => {
        saveCache(selectedChart)
    }, [])

    const chartTypes = ['Doughnut']

    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                sx={{ marginTop: -5, marginBottom: 2, marginLeft: -2 }}
            >
                <Typography align="left" variant="h5" color={'primary'}>
                    {selectedChart.chartName
                        ? 'Use this form to edit a Chart within Osmosis Telematics Platform'
                        : 'Use this form to add a Chart within Osmosis Telematics Platform'}
                </Typography>
            </Grid>

            <UniversalTagToolbar />

            <Grid container spacing={2} sx={{ marginTop: -5 }}>
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormSection title="Chart Info">
                                <Grid item xs={4}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        name="chartName"
                                        label="Chart Name"
                                        value={chartName}
                                        onChange={handleChange}
                                        onBlur={(e: any) =>
                                            basicValidator(
                                                e,
                                                formValidation,
                                                setState
                                            )
                                        }
                                        error={chartNameValidation}
                                        helperText={
                                            chartNameValidation
                                                ? 'Please enter a chart name.'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        name="chartLabel"
                                        label="Chart Hover Label"
                                        value={chartLabel}
                                        onChange={handleChange}
                                        onBlur={(e: any) =>
                                            basicValidator(
                                                e,
                                                formValidation,
                                                setState
                                            )
                                        }
                                        error={chartLabelValidation}
                                        helperText={
                                            chartLabelValidation
                                                ? 'Please enter a chart label.'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        id="chartType"
                                        options={chartTypes}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, newValue) => {
                                            const data = {
                                                target: {
                                                    name: 'chartType',
                                                    value:
                                                        typeof newValue ===
                                                        'string'
                                                            ? newValue
                                                            : '',
                                                },
                                            }
                                            handleChange(data)
                                        }}
                                        value={chartType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Chart Type"
                                                name="chartType"
                                                onChange={handleChange}
                                                value={chartType}
                                                onBlur={(e: any) =>
                                                    basicValidator(
                                                        e,
                                                        formValidation,
                                                        setState
                                                    )
                                                }
                                                error={chartTypeValidation}
                                                helperText={
                                                    chartTypeValidation
                                                        ? 'Please select a chart type.'
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 2 }}>
                            <FormSection title="Data Sets">
                                <Grid item xs={12}>
                                    <AnalyticsChartFormStack
                                        chartType={chartType}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Accordion elevation={4}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    disabled={!chartType}
                                >
                                    <Typography variant="h5" color={'primary'}>
                                        Graph Preview
                                    </Typography>
                                </AccordionSummary>
                                <Grid item xs={12}>
                                    <AnalyticsChartNode
                                        chartType={previewData.chartType}
                                        item={previewData}
                                        onClick={() => {}}
                                        title={previewData.chartName}
                                        size={12}
                                        editDisabled={true}
                                    />
                                </Grid>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormSection title="Required fields are marked with an *">
                        <Grid item xs={8}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!isFormValid}
                                sx={{
                                    width: '100%',
                                    height: 55,
                                }}
                            >
                                Save & Close
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleReset}
                                disabled={!isFormDirty}
                                sx={{
                                    width: '100%',
                                    height: 55,
                                }}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AnalyticsChartForm
