import { useReducer, useEffect } from 'react'
import { UniversalCookieCrumb, DataGridLayout } from '../../UniversalComponents'
import { useMinimizer } from '../../../context/MinimizeContext'
import UserAdmin from './UserAdmin'
import UserNotificationSubscription from './UserNotificationSubscription'

import { Grid, useTheme, AppBar, Tab, Tabs } from '@mui/material'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Grid
            container
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid container>
                    <Grid item xs={12} padding={2}>
                        {children}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

const Users = () => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const defaultState = {
        currentTab: 0,
    }

    const [state, setState] = useReducer(reducer, defaultState)

    const { currentTab } = state

    const setCurrentTab = (event: React.SyntheticEvent, newValue: number) =>
        setState({ currentTab: newValue })

    const theme = useTheme()
    const { restoredData } = useMinimizer()

    useEffect(() => {
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'useradmin'
        ) {
            setState({ currentTab: 0 })
        }
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'usernotificationsubscription'
        ) {
            setState({ currentTab: 1 })
        }
    }, [restoredData])

    return (
        <DataGridLayout
            cookieCrumbs={<UniversalCookieCrumb />}
            tabBar={
                <AppBar position="static">
                    <Tabs
                        value={currentTab}
                        onChange={setCurrentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        variant="scrollable"
                        aria-label="Osmosis"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab label="User Admin" />
                        <Tab label="User Notification Subscriptions" />
                    </Tabs>
                </AppBar>
            }
            dataGrid={
                <>
                    <TabPanel
                        value={currentTab}
                        index={0}
                        dir={theme.direction}
                    >
                        <UserAdmin />
                    </TabPanel>
                    <TabPanel
                        value={currentTab}
                        index={1}
                        dir={theme.direction}
                    >
                        <UserNotificationSubscription />
                    </TabPanel>
                </>
            }
        />
    )
}

export default Users
