const responseError = (res: any, row: any) => {
    const maxDisplayedCharacters = 100

    const errorMessage = res.response?.data || ''

    if (errorMessage.length > maxDisplayedCharacters) {
        const truncatedMessage = `${errorMessage.substring(
            0,
            maxDisplayedCharacters
        )} ${'...'} `
        throw new Error(truncatedMessage)
    }

    throw new Error(errorMessage)
}

export default responseError
