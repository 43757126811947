import { createContext, useReducer, useContext } from 'react'
import { Alert, Stack, Snackbar, Button } from '@mui/material'

interface DefaultState {
    toastMessage?: string
    toastVisible?: boolean
    toastType?: string
    toastAction?: () => void
    setToastMessage?: (
        message: string,
        toastType?: string,
        toastAction?: any
    ) => {}
}
const defaultState: DefaultState = {
    toastMessage: '',
    toastVisible: false,
    toastType: 'success',
    toastAction: () => {},
}

export const ToastMessage = createContext(
    (message: string, toastType?: string, toastAction?: any): void => {}
)

const AlertToast = ({
    toastVisible,
    toastMessage,
    toastType,
    handleClose,
    toastAction,
}) => {
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                open={toastVisible}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={toastType || defaultState.toastType}
                    sx={{ width: '100%' }}
                >
                    {toastMessage}
                    {toastType === 'warning' && (
                        <Button
                            color="error"
                            size="small"
                            onClick={toastAction}
                        >
                            OK
                        </Button>
                    )}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export const ToastMessagePopup = ({ children }) => {
    const reducer = (oldState: any, newState: any) => ({
        ...oldState,
        ...newState,
    })
    const [state, setState] = useReducer(reducer, defaultState)

    const setToastMessage = (
        message: string,
        toastType?: string,
        toastAction?: () => {}
    ) =>
        setState({
            toastMessage: message,
            toastVisible: true,
            toastType,
            toastAction,
        })

    const handleClose = () => setState({ toastVisible: false })

    const { toastMessage, toastVisible, toastType, toastAction } = state

    return (
        <ToastMessage.Provider value={setToastMessage}>
            <AlertToast
                toastVisible={toastVisible}
                toastMessage={toastMessage}
                toastType={toastType}
                toastAction={toastAction}
                handleClose={handleClose}
            />
            {children}
        </ToastMessage.Provider>
    )
}

export const useToastMessage = () => useContext(ToastMessage)
