import buildRoute from './buildRoute'

export const addBoat = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const getBoats = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boats',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const editBoat = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
interface SelectionType {
    data: []
    activate: boolean
}

export const deactivateBoats = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deleteBoat = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const boatRoutes = {
    getBoats,
    addBoat,
    editBoat,
    deactivateBoats,
    deleteBoat,
}

export default boatRoutes
