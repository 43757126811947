import * as XLSX from 'xlsx/xlsx.mjs'

const universalExcelExport = async (
    visibleColumns: string[],
    customColumnNames: string[],
    data: any[],
    excelSelectedRows: number[],
    fileName: string
) => {
    const filteredData =
        excelSelectedRows.length !== 0
            ? data.filter((row) => excelSelectedRows.includes(row.id))
            : data

    let formattedData: any[] = []

    filteredData.forEach((object) => {
        const newData: any[] = []

        customColumnNames.forEach((columnName) => {
            const customColumnIndex = customColumnNames.indexOf(columnName)
            if (customColumnIndex !== -1) {
                const visibleColumnName = visibleColumns[customColumnIndex]

                const value = object[visibleColumnName] ?? null
                newData.push([value])
            } else {
                newData.push([null])
            }
        })

        formattedData.push(newData)
    })

    const workbook = XLSX.utils.book_new()
    const ws_data = [
        customColumnNames.map((columnName) => columnName.toUpperCase()),
        ...formattedData,
    ]
    const worksheet = XLSX.utils.aoa_to_sheet(ws_data)
    XLSX.utils.book_append_sheet(workbook, worksheet)
    XLSX.utils.sheet_add_aoa(worksheet, [[]])

    return XLSX.writeFile(workbook, fileName, { compression: true })
}

export default universalExcelExport
