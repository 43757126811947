import { Grid, Typography } from '@mui/material'
import {
    FormSection,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../../UniversalComponents'
import UniversalDataTable from '../../../UniversalComponents/UniversalDataTable'
import { useContext, useEffect, useReducer } from 'react'
import SignalProfileManagerForm from './SignalProfileManagerForm'
import {
    Sensors as SensorsIcon,
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    Restore,
    ContentCopy,
    SensorOccupied as SensorOccupiedIcon,
} from '@mui/icons-material'

import { useMinimizer } from '../../../../context/MinimizeContext'
import { useSocket } from '../../../../context/SocketContext'
import {
    getSignalProfiles,
    activateSignalProfiles,
    deleteSignalProfiles,
    cloneSignalProfiles,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import {
    standardColumnMapper,
    permissionsCheck,
} from '../../../../utilities/functionTools'
import {
    usePermissions,
    Authentication,
} from '../../../../context/AuthenticationContext'
import {
    handleActivation,
    handleDeletion,
    handleClone,
} from '../../../../utilities/functionTools'

const today = new Date().toISOString().substring(0, 10)

const SignalProfileManager = ({ restoredData }) => {
    const { socket } = useSocket()
    const reducer = (state, newState) => ({ ...state, ...newState })
    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        data: [],
        viewDeactivated: false,
        activateData: [],
    }

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const { open, row, formCache, data, viewDeactivated, activateData } = state

    const { setMinimized } = useMinimizer()
    const currentUser = useContext(Authentication)
    const { username, userRole, activeManufacturer } = currentUser.auth

    const userPermissions = usePermissions()

    const getData = async (active: boolean) => {
        try {
            const getSignalProfilesData = await getSignalProfiles(active)
            if (getSignalProfilesData && getSignalProfilesData.length !== 0) {
                const signalProfiles = standardColumnMapper(
                    defaultColumns,
                    getSignalProfilesData
                )

                console.log(signalProfiles, 'profiles')

                setState({
                    data: signalProfiles,
                })
            } else {
                setState({
                    data: [],
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'profileName',
            setState,
            getData,
            route: activateSignalProfiles,
            viewDeactivated,
            setToastMessage,
            page: 'profiles',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'profileName',
            setState,
            getData,
            route: deleteSignalProfiles,
            viewDeactivated,
            setToastMessage,
            page: 'profiles',
        })
    }

    const handleCloneData = () => {
        handleClone({
            cloneData: activateData,
            data,
            returnKey: 'profileName',
            setState,
            getData,
            route: cloneSignalProfiles,
            viewDeactivated,
            setToastMessage,
            page: 'profiles',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these signal profiles?`,
            'warning',
            handleDeletionData
        )
    }

    const handleCloneCheck = () => {
        if (activateData && activateData.length !== 0) {
            setToastMessage(
                `Are you sure you want to clone these signal profiles?`,
                'warning',
                handleCloneData
            )
        } else {
            setToastMessage(
                `Please select profiles to clone from table.`,
                'error'
            )
        }
    }

    useEffect(() => {
        getData(true)
        socket.on('signal-profiles', (newData) => {
            if (newData) {
                getData(!viewDeactivated)
                setToastMessage(`Signal profiles have just updated.`)
            }
        })
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.signalFriendlyName
                ? `Edit: ${formCache.signalFriendlyName}`
                : 'Add New Signal Profile',
            link: '/data-hub/signals',
            data: formCache.id
                ? { ...formCache, tab: 'signal-profiles' }
                : { ...formCache, id: 'newSignal', tab: 'signal-profiles' },
            id: formCache.id ? formCache.id : 'newSignal',
        })
        setState({ open: false })
    }

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.profileName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const setViewDeactivated = () =>
        setState({ viewDeactivated: !viewDeactivated })

    const buttons = [
        {
            buttonName: 'Add New Signal Profile',
            buttonIcon: <SensorOccupiedIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: 'Clone Signal Profiles',
            buttonIcon: <ContentCopy />,
            buttonFunction: () => handleCloneCheck(),
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated
                ? 'View Deactivated Signal Profiles'
                : 'View Active Signal Profiles',
            buttonIcon: <Restore />,
            buttonFunction: setViewDeactivated,
        },
    ]

    const defaultColumns = ['profileName', 'manufacturer.manufacturerName']

    const visibleColumns = ['profileName', 'manufacturerName']

    const customColumnNames = ['Profile Name', 'Manufacturer']

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar
                    buttons={
                        !viewDeactivated
                            ? buttons
                            : [
                                  ...buttons,
                                  {
                                      buttonName: 'Delete',
                                      buttonIcon: <DeleteIcon />,
                                      buttonFunction: () =>
                                          handleDeletionCheck(),
                                  },
                              ]
                    }
                />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            Signal Profile Manager
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !viewDeactivated
                            ? 'No Active Signal Profiles Available'
                            : 'No Deactivated Signal Profiles Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        visibleColumns={visibleColumns}
                        customColumnNames={customColumnNames}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(rowSelection) => {
                            setState({ activateData: rowSelection })
                        }}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={`${
                    row.signalName
                        ? ` ${row.signalFriendlyName}`
                        : 'Signal Profile Manager'
                }`}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <SignalProfileManagerForm
                    row={row}
                    formDescription={
                        row.signalName
                            ? 'Use this form to edit a Signal Profile within Osmosis Telematics Platform'
                            : 'Use this form to add a new Signal Profile within Osmosis Telematics Platform'
                    }
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Signals'
                    )}
                />
            </UniversalModal>
        </Grid>
    )
}

export default SignalProfileManager
