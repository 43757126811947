import buildRoute from './buildRoute'

export const getBoatModels = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat-models',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addBoatModel = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'boat-model',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const editBoatModels = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'update-boat-model',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        return console.error(err)
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deactivateBoatModels = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat-model-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deleteBoatModel = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat-model-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const cloneBoatModels = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'boat-model-clones',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const boatModelRoutes = {
    getBoatModels,
    addBoatModel,
    editBoatModels,
    deleteBoatModel,
    deactivateBoatModels,
    cloneBoatModels,
}

export default boatModelRoutes
