import { Button, Grid, Toolbar, Typography, AppBar } from '@mui/material'

const UniversalToolBar = ({ buttons }) => {
    return (
        <Grid container spacing={2}>
            <Grid item sx={{ flexGrow: 5 }} xs={12}>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Typography
                            variant="h6"
                            color="inherit"
                            component="div"
                            margin={0.5}
                        >
                            {buttons.map(
                                ({
                                    buttonName,
                                    buttonIcon,
                                    buttonFunction,
                                    disabled,
                                    display,
                                }) => (
                                    <Button
                                        sx={{ flexGrow: 8, display: display }}
                                        startIcon={buttonIcon}
                                        onClick={buttonFunction}
                                        key={`button.${buttonName}`}
                                        disabled={disabled}
                                    >
                                        {buttonName}
                                    </Button>
                                )
                            )}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Grid>
        </Grid>
    )
}

export default UniversalToolBar
