import buildRoute from './buildRoute'

export const getMeasurement = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'units-of-measurement',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addMeasurement = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'unit-of-measurement',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const editMeasurement = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'update-unit-of-measurement',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        return console.error(err)
    }
}

interface SelectionType {
    data: []
    activate: boolean
}
export const deactivateMeasurements = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'unitOfMeasurement-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deleteMeasurements = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'unitOfMeasurement-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const measurementRoutes = {
    getMeasurement,
    addMeasurement,
    editMeasurement,
    deleteMeasurements,
    deactivateMeasurements,
}

export default measurementRoutes
