import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Box,
    CircularProgress,
} from '@mui/material'
import { useRef, useEffect } from 'react'

interface BoatInsightsProps {
    image?: string
    description?: string
    sx?: {}
    unitOfMeasurement?: string
    title: string
    disabled?: boolean
}

const BoatInsightCards = ({
    image,
    description,
    sx = {},
    unitOfMeasurement,
    title,
    disabled = false,
}: BoatInsightsProps) => {
    const timeout = useRef(false)
    useEffect(() => {
        setTimeout(() => {
            timeout.current = true
            return timeout
        }, 10000)
    }, [])

    return (
        <Card
            sx={{
                width: { xs: '100%', md: '45%', lg: '30%' },
                display: 'flex',
                margin: 0.5,
                cursor: !disabled ? 'grabbing' : 'arrow',
            }}
        >
            <CardMedia
                component="img"
                sx={{
                    width: 75,
                    height: 75,
                    position: 'relative',
                    top: 13,
                    left: 10,
                    marginRight: 2,
                }}
                image={image}
                alt={description}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                    >
                        {title && title?.length > 18
                            ? `${title?.substring(0, 18)}...`
                            : title}
                    </Typography>
                    <Typography component="div" variant="h5">
                        {description === 'No Data' &&
                        timeout.current === false ? (
                            <CircularProgress color="primary" size={25} />
                        ) : description !== 'No Data' ? (
                            `${description} ${unitOfMeasurement}`
                        ) : (
                            description
                        )}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    )
}

export default BoatInsightCards
