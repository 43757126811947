import buildRoute from './buildRoute'

export const getSignalProfiles = async (active: boolean) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-profiles',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const postSignalProfile = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-profile',
            data: formData,
        })
        if (res) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    signals: []
    activate: boolean
}
export const activateSignalProfiles = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-profile-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    signals: []
    activate: boolean
}
export const deleteSignalProfiles = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-profile-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const cloneSignalProfiles = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-profile-clones',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
