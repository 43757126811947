import { useReducer } from 'react'
import {
    Stack,
    styled,
    Paper,
    Autocomplete,
    TextField,
    Grid,
    Button,
} from '@mui/material'
import { MuiColorInput } from 'mui-color-input'
import { FormSection } from '../../../UniversalComponents'

interface AnalyticsChartFormStackTypes {
    dataSets?: any
    chartType?: string
}

const initialDataSet = {
    data: ['Gps Enabled', 'Gps Disabled'],
    backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
    borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
    borderWidth: '2',
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: 5,
}))

const DoughnutChartStack = ({ chartData, index }) => {
    const defaultState = {
        data: chartData.data,
        backgroundColor: chartData.backgroundColor,
        borderColor: chartData.borderColor,
        borderWidth: chartData.borderWidth,
    }

    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { data, backgroundColor, borderColor, borderWidth } = state

    const dataSets = [
        'Gps Enabled',
        'Gps Disabled',
        'Model Year 2022',
        'Model Year 2023',
    ]

    const borderWidthSelection = ['1', '2', '3', '4', '5']
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setState({
            [name]: value,
        })
    }

    const handleColorChange = (name, value, index) => {
        let newDataIndex
        if (name === 'backgroundColor') {
            newDataIndex = [...backgroundColor]
        } else {
            newDataIndex = [...borderColor]
        }

        newDataIndex[index] = value
        setState({
            [name]: newDataIndex,
        })
    }
    return (
        <Grid item xs={12}>
            <FormSection title={`Data set ${index}`}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {data.map((chartData, dataIndex) => (
                            <Item key={`${data[dataIndex]}.Item.${dataIndex}`}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            disablePortal
                                            id={`dataSet.${dataIndex}`}
                                            options={dataSets.filter(
                                                (dataItem) =>
                                                    !data.includes(dataItem)
                                            )}
                                            value={
                                                data &&
                                                data.length !== 0 &&
                                                data[dataIndex]
                                            }
                                            onChange={(event, newValue) => {
                                                const newDataArray = [...data]
                                                newDataArray[dataIndex] =
                                                    newValue
                                                const newData = {
                                                    target: {
                                                        name: 'data',
                                                        value: newDataArray,
                                                    },
                                                }
                                                handleChange(newData)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Data Set ${
                                                        dataIndex + 1
                                                    }`}
                                                    name={`dataSet.${dataIndex}`}
                                                    onChange={handleChange}
                                                    value={
                                                        data &&
                                                        data.length !== 0 &&
                                                        data[dataIndex]
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiColorInput
                                            value={backgroundColor[dataIndex]}
                                            onChange={(value) =>
                                                handleColorChange(
                                                    'backgroundColor',
                                                    value,
                                                    dataIndex
                                                )
                                            }
                                            label="Background Color"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiColorInput
                                            value={borderColor[dataIndex]}
                                            onChange={(value) =>
                                                handleColorChange(
                                                    'borderColor',
                                                    value,
                                                    dataIndex
                                                )
                                            }
                                            label="Border Color"
                                        />
                                    </Grid>
                                    {dataIndex < 1 && (
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                disablePortal
                                                id={`borderWidth.${dataIndex}`}
                                                options={borderWidthSelection}
                                                value={borderWidth}
                                                onChange={(event, newValue) => {
                                                    const newData = {
                                                        target: {
                                                            name: 'borderWidth',
                                                            value: newValue,
                                                        },
                                                    }
                                                    handleChange(newData)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Border Width`}
                                                        name={`borderWidth.${dataIndex}`}
                                                        onChange={handleChange}
                                                        value={borderWidth}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Item>
                        ))}
                    </Grid>
                </Grid>
            </FormSection>
        </Grid>
    )
}

const AnalyticsChartFormStack = ({
    dataSets,
    chartType,
}: AnalyticsChartFormStackTypes) => {
    const defaultState = {
        currentDataSets: dataSets && dataSets.length !== 0 ? dataSets : [],
    }
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { currentDataSets } = state
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack>
                    <Grid container spacing={2}>
                        {currentDataSets &&
                            currentDataSets?.length !== 0 &&
                            currentDataSets.map(
                                (chartData, index) =>
                                    chartType === 'Doughnut' && (
                                        <DoughnutChartStack
                                            chartData={chartData}
                                            index={index + 1}
                                            key={`Doughnut.${index + 1}`}
                                        />
                                    )
                            )}
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!chartType}
                    onClick={() =>
                        setState({
                            currentDataSets: [
                                initialDataSet,
                                ...currentDataSets,
                            ],
                        })
                    }
                    sx={{
                        width: '100%',
                        height: 55,
                    }}
                >
                    Add New Data Set
                </Button>
            </Grid>
        </Grid>
    )
}

export default AnalyticsChartFormStack
