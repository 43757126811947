import buildRoute from './buildRoute'

export const getUsers = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'users',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const getUser = async (_id: object) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user',
            data: _id,
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const addUser = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'auth-create-user',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const editUser = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-edit',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const archiveUsers = async (user: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'archive-user',
            data: user,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
export const deactivateUserRequest = async (userEmail: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-deactivation-request',
            data: { userEmail },
        })
        if (res.status) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    users: []
    activate: boolean
}

export const activateUsers = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-activations',
            data: selection,
        })
        if (res.status) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const userRoutes = {
    getUsers,
    archiveUsers,
    getUser,
    activateUsers,
    editUser,
}

export default userRoutes
