import {
    Stack,
    Grid,
    CircularProgress,
    Typography,
    Button,
} from '@mui/material'
import { useEffect, useReducer, ReactNode } from 'react'
import ErrorIcon from '@mui/icons-material/Error'

interface Props {
    data: any[]
    children?: ReactNode
    timeoutMessage?: string
    reloadFunction?: () => {}
    customTimeoutMessage?: string
}

const UniversalLoadingCircle: any = ({
    children,
    data = [],
    timeoutMessage = 'There is no data or response from the server, check connection and reload...',
    reloadFunction,
    customTimeoutMessage = '',
}) => {
    const defaultState = {
        loading: true,
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { loading } = state
    const timeoutSeconds = 10
    const timeout = timeoutSeconds * 1000

    const loaderTimeout = () =>
        setTimeout(() => setState({ loading: false }), timeout)

    useEffect(() => {
        if (data.length === 0) {
            loaderTimeout()
        } else {
            setState({ loading: false })
        }
    }, [data])

    const CircularLoadingContainer = () => (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid xs={12} item>
                <Stack
                    sx={{
                        color: 'grey.500',
                        direction: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 25,
                    }}
                    spacing={2}
                    direction="row"
                >
                    <CircularProgress color="primary" size={100} />
                </Stack>
            </Grid>
        </Grid>
    )

    const TimeoutMessageContainer = () => (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            marginTop={25}
        >
            <Grid xs={12} item>
                <Typography color={'primary'} variant="h4">
                    <Grid container spacing={0.5}>
                        <Grid xs={2} item>
                            <ErrorIcon fontSize="large" sx={{ marginTop: 2 }} />
                        </Grid>
                        <Grid xs={10} item>
                            {customTimeoutMessage
                                ? customTimeoutMessage
                                : timeoutMessage}
                        </Grid>
                    </Grid>
                    {reloadFunction !== undefined && (
                        <Button
                            onClick={() => {
                                reloadFunction()
                                setState({ loading: true })
                                loaderTimeout()
                            }}
                            variant="contained"
                            color="primary"
                            style={{ width: '100%', marginTop: 15 }}
                        >
                            Reload
                        </Button>
                    )}
                </Typography>
            </Grid>
        </Grid>
    )

    return (
        <>
            {(loading && data?.length === 0 && <CircularLoadingContainer />) ||
                (!loading && data?.length === 0 && (
                    <TimeoutMessageContainer />
                )) ||
                (!loading && data?.length !== 0 && children)}
        </>
    )
}

export default UniversalLoadingCircle
