import { useContext, useEffect, useReducer } from 'react'
import {
    FormControlLabel,
    Switch,
    Autocomplete,
    Grid,
    TextField,
    Typography,
    styled,
    IconButton,
    Box,
    Tooltip,
    Chip,
} from '@mui/material'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { datePickerConverter } from '../../../../utilities/functionTools'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import { addCalibrationFile, getManufacturers } from '../../../../apiRoutes'
import { parseFormWithFile } from '../../../../utilities/functionTools'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { FormSection, SaveButton } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import FilePresentIcon from '@mui/icons-material/FilePresent'

const CalibrationFileManagerForm = ({
    row,
    formDescription,
    onClose,
    permissionCheck,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth
    const defaultState = {
        formData: {
            id: row.id || '',
            calibrationFileName: row.calibrationFileName || '',
            calibrationFileUrl: row.calibrationFileUrl || '',
            calibrationFileChecksum: row.calibrationFileChecksum || '',
            custSoftwarePartNumber: row.custSoftwarePartNumber || '',
            deployedAt: datePickerConverter(row.deployedAt) || '',
            activeFile: row.activeFile || false,
            updatedAt: datePickerConverter(row.updatedAt) || '',
            updatedBy: row.updateBy || username,
            archived: row.archived || false,
            manufacturerName: row.manufacturerName,
        },

        formValidation: {
            calibrationFileNameValidation: false,
            custSoftwarePartNumberValidation: false,
            deploayedAtValidation: false,
            manufacturerNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        selectedFile: { name: row.calibrationFileName || undefined },
        setVersionNameInput: '',
        closeModal: false,
        permissionsCheck: permissionCheck,
        calibrationFileNameDisabled: row.calibrationFileName ? true : false,
        manufacturerChoiceDisabled: row.manufacturerName ? true : false,
        manufacturerOptions: [],
        manufacturers: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        selectedFile,
        formValidation,

        calibrationFileNameDisabled,
        manufacturerChoiceDisabled,
        manufacturerOptions,
        manufacturers,
    } = state

    const {
        calibrationFileName,
        calibrationFileUrl,
        calibrationFileChecksum,
        custSoftwarePartNumber,
        deployedAt,
        activeFile,
        manufacturerName,
    } = formData

    const {
        calibrationFileNameValidation,
        custSoftwarePartNumberValidation,
        manufacturerNameValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const getEngineManufacturerData = async () => {
        try {
            const data = await getManufacturers(true)
            if (data && data.length !== 0) {
                setState({
                    manufacturerOptions: data
                        .filter(
                            (manufacturer) => manufacturer.type === 'engine'
                        )
                        .map(({ manufacturerName }) => manufacturerName),
                    manufacturers: data.filter(
                        (manufacturer) => manufacturer.type === 'engine'
                    ),
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleSubmit = async () => {
        try {
            delete formData.updatedAt

            const newFormData = { ...formData }
            const parsedFormData = parseFormWithFile(newFormData, [
                selectedFile,
            ])

            let res: any

            res = await addCalibrationFile(parsedFormData)

            if (res) {
                const { data, status } = res
                if (data && status === 200) {
                    setToastMessage(
                        `Calibration File: ${data.calibrationFileName} was added successfully`,
                        'success'
                    )
                    onClose(row.id, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'calibrationFileName') {
            const newFileName = e.target.value
            setState({
                formData: {
                    ...formData,
                    calibrationFileName: newFileName,
                },
                selectedFile:
                    selectedFile.name === undefined
                        ? ''
                        : { ...selectedFile, name: newFileName },
            })
        } else if (name === 'manufacturerName') {
            const newEngineManufacturerId = manufacturers.find(
                (file) => file.manufacturerName === value
            )

            setState({
                formData: {
                    ...formData,
                    manufacturer: newEngineManufacturerId
                        ? newEngineManufacturerId._id
                        : '',
                },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
    }

    const handleFileSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]

            setState({
                selectedFile: file,
                formValidation: { ...formValidation, isFormDirty: true },
            })
        }
    }

    const handleFileRemove = () => {
        setState({
            selectedFile: [],
        })
    }

    const handleReset = () => {
        if (row.calibrationFileName) {
            setState({
                selectedFile: { name: row.calibrationFileName || undefined },
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
            })
        } else {
            setState({
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
                selectedFile: '',
            })
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    useEffect(() => {
        getEngineManufacturerData()
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !calibrationFileNameValidation &&
            !custSoftwarePartNumberValidation &&
            calibrationFileName &&
            custSoftwarePartNumber &&
            selectedFile.name &&
            selectedFile.length !== 0
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        calibrationFileNameValidation,
        custSoftwarePartNumberValidation,
        calibrationFileName,
        custSoftwarePartNumber,
        selectedFile,
    ])

    return (
        <Grid container spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ padding: 1, marginTop: 3 }}>
                    <Typography align="center" variant="h5" color={'primary'}>
                        {row.calibrationFileName
                            ? 'Use this form to edit an Calibration File within Osmosis Telematics Platform'
                            : 'Use this form to add an Calibration File within Osmosis Telematics Platform'}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <UniversalTagToolbar />
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormSection title="Calibration File Information">
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                id="manufacturerName"
                                options={manufacturerOptions}
                                value={manufacturerName}
                                fullWidth
                                disabled={manufacturerChoiceDisabled}
                                onChange={(event, newValue) => {
                                    const data = {
                                        target: {
                                            name: 'manufacturerName',
                                            value: newValue,
                                        },
                                    }
                                    handleChange(data)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Engine Manufacturer"
                                        name="manufacturerName"
                                        onChange={handleChange}
                                        required
                                        disabled={permissionCheck}
                                        value={manufacturerName}
                                        onBlur={(e: any) =>
                                            basicValidator(
                                                e,
                                                formValidation,
                                                setState
                                            )
                                        }
                                        error={manufacturerNameValidation}
                                        helperText={
                                            manufacturerNameValidation
                                                ? 'Please enter a manufacturer name.'
                                                : ''
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required={true}
                                disabled={calibrationFileNameDisabled}
                                name="calibrationFileName"
                                label="Calibration File Name"
                                value={calibrationFileName}
                                fullWidth
                                onChange={(event) => {
                                    const newValue = event.target.value
                                    const data = {
                                        target: {
                                            name: 'calibrationFileName',
                                            value:
                                                typeof newValue === 'string'
                                                    ? newValue
                                                    : '',
                                        },
                                    }
                                    handleChange(data)
                                }}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={calibrationFileNameValidation}
                                helperText={
                                    calibrationFileNameValidation
                                        ? 'Please enter a enter a notification name.'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                disabled={permissionCheck}
                                name="custSoftwarePartNumber"
                                label="Customer Software Part Number"
                                value={custSoftwarePartNumber}
                                onChange={handleChange}
                                fullWidth
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={custSoftwarePartNumberValidation}
                                helperText={
                                    custSoftwarePartNumberValidation
                                        ? 'Please enter a enter a notification name.'
                                        : ''
                                }
                            />
                        </Grid>

                        {calibrationFileChecksum && (
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    name="calibrationFileChecksum"
                                    label="Calibration File Checksum"
                                    value={calibrationFileChecksum}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </FormSection>
                </Grid>

                <Grid item xs={12} md={2.5}>
                    <FormSection title="Deployment">
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Deployment Date"
                                    views={['year', 'month', 'day']}
                                    value={deployedAt}
                                    onChange={({ $d }) => {
                                        const event = {
                                            target: {
                                                value: $d,
                                                name: 'deployedAt',
                                            },
                                        }
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                        handleChange(event)
                                    }}
                                    sx={{ width: '100%' }}
                                    slotProps={{
                                        textField: {
                                            error: false,
                                        },
                                    }}
                                    disabled={permissionCheck}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    activeFile: !activeFile,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                        id="switch.activeFile"
                                        name="switch.dealerVisibilty"
                                        checked={activeFile}
                                        key="switch.activeFile"
                                        disabled={permissionCheck}
                                    />
                                }
                                label="Active File"
                                key="switch.activeFile"
                                disabled={permissionCheck}
                            />
                        </Grid>
                        {calibrationFileUrl && (
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    name="calibrationFileUrl"
                                    label="Calibration File URL"
                                    value={calibrationFileUrl}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </FormSection>
                </Grid>

                <Grid item xs={12} md={5.5}>
                    <FormSection title="Calibration File Upload">
                        <Grid item xs={12}>
                            <label htmlFor="raised-button-file">
                                <Tooltip
                                    title={
                                        calibrationFileName ? (
                                            calibrationFileUrl ? (
                                                <h3
                                                    style={{ color: 'primary' }}
                                                >
                                                    Replace Calibration File
                                                </h3>
                                            ) : (
                                                <h3
                                                    style={{ color: 'inherit' }}
                                                >
                                                    Upload Default Calibration
                                                    File
                                                </h3>
                                            )
                                        ) : (
                                            <h3 style={{ color: 'inherit' }}>
                                                Please Add a Calibration File
                                                Name Before Uploading a
                                                Calibration File
                                            </h3>
                                        )
                                    }
                                >
                                    <center>
                                        {calibrationFileUrl ? (
                                            <IconButton component="label">
                                                <Box>
                                                    <UploadFileIcon
                                                        sx={{
                                                            fontSize: 50,
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        onChange={
                                                            handleFileSelect
                                                        }
                                                        type="file"
                                                        accept="image/*"
                                                        id='htmlFor="raised-button-file'
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                    />
                                                </Box>
                                            </IconButton>
                                        ) : (
                                            <IconButton component="label">
                                                <DriveFolderUploadIcon
                                                    sx={{
                                                        fontSize: 50,
                                                    }}
                                                />
                                                <VisuallyHiddenInput
                                                    onChange={handleFileSelect}
                                                    disabled={
                                                        calibrationFileName
                                                            ? false
                                                            : true
                                                    }
                                                    type="file"
                                                    accept="image/*"
                                                    id='htmlFor="raised-button-file'
                                                />
                                            </IconButton>
                                        )}
                                    </center>
                                </Tooltip>
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            {selectedFile?.name && (
                                <Chip
                                    label={selectedFile?.name}
                                    variant="outlined"
                                    color="info"
                                    icon={
                                        <FilePresentIcon
                                            sx={{
                                                color: 'primary',
                                            }}
                                        />
                                    }
                                    onDelete={() => {
                                        handleFileRemove()
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                    }}
                                    sx={{ color: 'grey', fontSize: 25 }}
                                    deleteIcon={<RemoveCircleIcon />}
                                    disabled={permissionCheck}
                                ></Chip>
                            )}
                        </Grid>
                    </FormSection>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <SaveButton
                            handleSubmit={handleSubmit}
                            handleReset={handleReset}
                            disabledSave={!isFormValid && permissionCheck}
                            disabledReset={!isFormDirty && permissionCheck}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CalibrationFileManagerForm
