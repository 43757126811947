import {
    Card,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    IconButtonProps,
    Collapse,
    Grid,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

const SignalProfileEventCard = ({
    notificationName,
    logEvents,
    pushNotifications,
    index,
    setEvent,
    removeEvent,
    type,
}) => {
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    return (
        <Card
            sx={{
                width: { xs: '100%' },
                display: 'flex',
                margin: 0.5,
                cursor: 'grabbing',
            }}
        >
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {notificationName}
                </Typography>
            </CardContent>
            <CardActions>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
                <Tooltip title="Remove Event" sx={{ marginLeft: '30%' }}>
                    <IconButton
                        aria-label="Remove Event"
                        onClick={() => removeEvent(type, notificationName)}
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() =>
                                        setEvent('logEvents', index)
                                    }
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                    id="switch.logEvents"
                                    name="switch.logEvents"
                                    checked={logEvents}
                                    key="switch.logEvents"
                                />
                            }
                            label="Log Events"
                            key="switch.logEvents"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() =>
                                        setEvent('pushNotifications', index)
                                    }
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                    id="switch.pushNotifications"
                                    name="switch.pushNotifications"
                                    checked={pushNotifications}
                                    key="switch.pushNotifications"
                                />
                            }
                            label="Enable Mobile Push Notifications"
                            key="switch.pushNotifications"
                        />
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default SignalProfileEventCard
