/* eslint-disable */
import { useRouteError } from 'react-router-dom'
import { Box, Button, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'

interface Error {
    statusText?: any
    message?: any
}

const ErrorPage = () => {
    const error: Error = useRouteError() || {}
    console.error(error)

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#121212',
                // backgroundImage:
                //     'url("https://c.tenor.com/1fzs0qNnlkMAAAAC/waterski-fail.gif")',
                // backgroundSize: '400px',
                // backgroundRepeat: 'repeat',
                // backgroundBlendMode: 'soft-light',
            }}
        >
            <Container maxWidth="xl">
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12}>
                            <Typography
                                variant="h1"
                                color="primary"
                                align="center"
                            >
                                404
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ marginTop: 5 }}>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                                color={'red'}
                            >
                                Oh no! The page you're looking for doesn't
                                exist.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            src="https://c.tenor.com/WKWonIB6gjIAAAAC/police-siren-siren.gif"
                            // src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                            alt="Error"
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ marginTop: 5 }}>
                        <Typography align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                href="/"
                                sx={{
                                    width: '100%',
                                    height: 55,
                                }}
                            >
                                Back to Home
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default ErrorPage
