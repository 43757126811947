import universalAddNewUser from './UniversalAddNewUser'
import universalCookieCrumb from './UniversalCookieCrumb'
import universalDataTable from './UniversalDataTable'
import universalEditUser from './UniversalEditUser'
import universalExcelExport from './UniversalExcelExport'
import universalForm from './UniversalForm'
import universalGoogleMap from './UniversalGoogleMap'
import universalLoadingCircle from './UniversalLoadingCircle'
import universalModal from './UniversalModal'
import universalReactSorter from './UniversalReactSorter'
import universalToolBar from './UniversalToolbar'
import {
    UniversalAutoComplete,
    UniversalFormSection,
    UniversalTitle,
    UniversalSaveButton,
} from './UniversalFormStyles'
import universalTagToolbar from './UniversalTagToolbar'
import { UniversalDataGridLayout } from './UniversalLayouts'
import UniversalPdfViewer from './UniversalPdfViewer'

// Form components
export const UniversalAddNewUser = universalAddNewUser
export const UniversalCookieCrumb = universalCookieCrumb
export const UniversalDataTable = universalDataTable
export const UniversalEditUser = universalEditUser
export const UniversalExcelExport = universalExcelExport
export const UniversalForm = universalForm
export const UniversalGoogleMap = universalGoogleMap
export const UniversalLoadingCircle = universalLoadingCircle
export const UniversalModal = universalModal
export const UniversalReactSorter = universalReactSorter
export const UniversalToolBar = universalToolBar
export const UniversalTagToolbar = universalTagToolbar
export const SaveButton = UniversalSaveButton

//Formatters
export const FormSection = UniversalFormSection
export const Autocomplete = UniversalAutoComplete
export const Title = UniversalTitle

//Layouts
export const DataGridLayout = UniversalDataGridLayout

// Readers
export const PDFViewer = UniversalPdfViewer
