import { Grid, Typography } from '@mui/material'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import {
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    AddBox as AddBoxIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import react, { useContext, useEffect, useReducer } from 'react'
import {
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
    FormSection,
} from '../../../UniversalComponents'
import EngineManufacturerForm from './EngineManufacturerForm'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    deactivateManufacturers,
    deleteManufacturer,
    getManufacturers,
} from '../../../../apiRoutes'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    columnMapper,
    handleActivation,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import {
    permissionsCheck,
    handleDeletion,
} from '../../../../utilities/functionTools'
import {
    usePermissions,
    useAuth,
} from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const EngineManufacturer = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth
    const userPermissions = usePermissions()

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        manufacturers: [],
        filteredEngineManufacturers: [],
        formCache: {},
        multipleSelection: [],
        activateData: [],
        viewDeactivated: false,
        data: [],
        engines: {
            engineManufacturers: {
                columns: [
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 300,
                    },

                    {
                        field: 'manufacturerContactName',
                        headerName: 'CONTACT NAME',
                        width: 150,
                    },
                    {
                        field: 'manufacturerContactEmail',
                        headerName: 'EMAIL',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.engines?.engineManufacturers
                ?.density || 'standard',
    }

    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        manufacturer,
        formCache,
        multipleSelection,
        filteredEngineManufacturers,
        viewDeactivated,
        data,
        activateData,
        engines,
        density,
    } = state
    const { engineManufacturers } = engines

    const getData = async (active) => {
        try {
            const manufacturers = await getManufacturers(active)

            if (manufacturers && manufacturers.length !== 0) {
                const filterManufacturers = manufacturers.filter(
                    (manufacturer) => manufacturer.type === 'engine'
                )
                const engineManufacturers = standardColumnMapper(
                    defaultColumns,
                    filterManufacturers
                )

                setState({ data: engineManufacturers })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const engines =
            userPreferences?.dataTableColumns?.engines?.engineManufacturers
                ?.columns
        let setDensity = ''

        if (Array.isArray(engines)) {
            const mapColumns = engines.map((engine) => ({
                field: engine?.field,
                headerName: engine?.headerName,
                width: engine?.width,
                isVisible: engine.isVisible,
            }))

            const format = {
                engineManufacturers: {
                    columns: mapColumns,
                    density: setDensity,
                },
            }

            setState({ ...state, engines: format })
        } else {
            console.log('No Engines Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'engines',
                value: engines,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'manufacturerName',
            setState,
            getData,
            route: deactivateManufacturers,
            viewDeactivated,
            setToastMessage,
            page: 'manufacturers',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'manufacturerName',
            setState,
            getData,
            route: deleteManufacturer,
            viewDeactivated,
            setToastMessage,
            page: 'manufacturers',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Manufacturers?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.manufacturerName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.manufacturerName
                ? `Edit: ${formCache.manufacturerName}`
                : 'Add New Engine Manufacturer',
            link: '/admin/engines',
            data: formCache.id
                ? { ...formCache, tab: 'manufacturer' }
                : { ...formCache, id: 'newManfacturer', tab: 'manufacturer' },
            id: formCache.id ? formCache.id : 'newManufactuer',
        })
        setState({ open: false })
    }
    const defaultColumns = [
        'manufacturerName',
        'manufacturerContactName',
        'manufacturerContactEmail',
        'updatedBy',
        'updatedAt',
        'archived',
    ]

    const buttons = [
        {
            buttonName: 'Add New Engine Manufacturer',
            buttonIcon: <PrecisionManufacturingIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },

        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Engines'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Manufacturers'
                : 'View Deactivated Manufacturers',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = {
            engineManufacturers: { columns: format, density: setDensity },
        }

        setState({
            engines: setData,
        })
    }

    const handleUpdatedVisibility = (columnVisibilityModel) => {
        const updatedVisibility = engines?.columns.reduce((acc, col) => {
            if (col.field in columnVisibilityModel) {
                acc[col.field] = {
                    ...col,
                    isVisible: columnVisibilityModel[col.field],
                }
            } else {
                acc[col.field] = col
            }

            return acc
        }, {})

        const updatedColumns = Object.values(updatedVisibility)

        const setDensity = state.density

        const setData = {
            engineManufacturers: {
                columns: updatedColumns,
                density: setDensity,
            },
        }

        setState({ ...state, engines: setData })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            engines: {
                engineManufacturers: {
                    ...engineManufacturers,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>{<UniversalToolBar buttons={buttons} />}</Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Manufacturers'
                                : 'Deactivated Manufacturers'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Manufacturers Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={engines?.engineManufacturers?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({ activateData: newSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        getColumnHeaderVisibility={handleUpdatedVisibility}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>

                <UniversalModal
                    title={
                        row.manufacturerName
                            ? row.manufacturerName
                            : 'Add New Engine Manufacturer'
                    }
                    open={open}
                    onClose={(tableRow) =>
                        setModalVisbility(tableRow, undefined)
                    }
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                        marginBottom: 5,
                    }}
                    timeStamp={`${
                        row.updatedBy
                            ? ` ${
                                  row.updatedBy
                                      ? ` Modified By ${row.updatedBy}`
                                      : ''
                              } ${
                                  row.updatedAt
                                      ? `| Modified Date ${row.updatedAt.substring(
                                            0,
                                            10
                                        )}`
                                      : ''
                              }  `
                            : ` Modified By: ${username} | Modified Date: ${today} `
                    }`}
                    onMinimize={minimizeModal}
                    draggable={true}
                    minimizable={true}
                >
                    <EngineManufacturerForm
                        row={row}
                        formDescription="Add a new Engine Manufacturer"
                        onClose={(tableRow: any, newData: any) =>
                            setModalVisbility(tableRow, newData)
                        }
                        permissionCheck={permissionsCheck(
                            userPermissions,
                            'Engines'
                        )}
                    />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default EngineManufacturer
