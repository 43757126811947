import { useReducer } from 'react'
import {
    Grid,
    Paper,
    Avatar,
    TextField,
    Button,
    Typography,
} from '@mui/material'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../context/AuthenticationContext'
import loginLogo from '../../assets/osmosis/Logos/loginLogo.png'
import UniversalModal from '../UniversalComponents/UniversalModal'
import { FormSection } from '../UniversalComponents'

const LoginPage = () => {
    const defaultState = {
        formData: {
            username: '',
            password: '',
        },
        formValidation: {
            usernameValidation: true,
            passwordValidation: true,
        },
        openResetPassword: false,
    }
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { formData, formValidation, openResetPassword } = state
    const { username, password } = formData
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setState({
            formData: { ...formData, [name]: value },
        })
    }
    const avatarStyle = {
        backgroundColor: '#8cc63f',
        color: '#fff',
        variant: 'contained',
        marginLeft: 'auto',
        marginRight: 'auto',
    }

    const linkStyle = {
        color: '#8cc63f',
        variant: 'contained',
    }
    const btnstyle = { margin: '8px 0' }

    const { signIn } = useAuth()

    return (
        <div
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    signIn({ username, password })
                }
            }}
        >
            <Paper id="login">
                <Grid item xs={12} textAlign="center">
                    <img
                        src={loginLogo}
                        // src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                        alt="Error"
                        width="55%"
                    />
                </Grid>

                <TextField
                    label="Username"
                    placeholder="Enter username"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="username"
                    value={username}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    required
                    value={password}
                    onChange={handleChange}
                />

                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                    onClick={() => signIn({ username, password })}
                >
                    Sign in
                </Button>

                <Button
                    onClick={() =>
                        setState({ openResetPassword: !openResetPassword })
                    }
                    type="submit"
                    color="primary"
                    variant="text"
                    style={btnstyle}
                >
                    Reset Your Password
                </Button>

                <Typography margin="normal">
                    <NavLink
                        to="https://osmosis-os.com/deactivate-account"
                        style={linkStyle}
                    >
                        Delete Account
                    </NavLink>
                </Typography>
            </Paper>
            <UniversalModal
                title={'Reset Password'}
                open={openResetPassword}
                onClose={() => {
                    setState({ openResetPassword: !openResetPassword })
                }}
                fullScreen={false}
                wrapperStyle={{
                    margin: 'auto',
                    width: '80%',
                    marginTop: 5,
                }}
            >
                <FormSection
                    title="Please enter your registered email address to recover your account password."
                    titleAlignment="center"
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <TextField
                                margin="normal"
                                name="Registered Email Address"
                                label="Registered Email Address"
                                placeholder="Enter Your Email Address"
                                variant="outlined"
                                fullWidth
                                required
                                onChange={handleChange}
                            />

                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                style={btnstyle}
                                fullWidth
                                onClick={() => ({})}
                            >
                                Submit Email Address
                            </Button>
                        </Grid>
                    </Grid>
                </FormSection>
            </UniversalModal>
        </div>
    )
}

export default LoginPage
