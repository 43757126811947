import React, { useState } from 'react'
import {
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Box,
    Button,
    IconButton,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'

import OsmosisEULA from '../../assets/osmosis/Files/Osmosis_EULA.pdf'
import OsmosisPrivacyPolicy from '../../assets/osmosis/Files/Osmosis_PrivacyPolicy_v1_orig.pdf'

function LegalUploader({ onClose }) {
    const [eulaFile, setEulaFile] = useState<File | null>(null) // Explicitly type the file variable
    const [privacyFile, setPrivacyFile] = useState<File | null>(null) // Explicitly type the file variable

    const defaultEulaURL = OsmosisEULA
    const defaultPrivacyURL = OsmosisPrivacyPolicy

    const handleEulaUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            setEulaFile(file)
        }
    }

    const handlePrivacyUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0]
            setPrivacyFile(file)
        }
    }

    // Create download links for uploaded PDFs with unique file names
    const eulaDownloadLink = eulaFile
        ? URL.createObjectURL(eulaFile)
        : defaultEulaURL
    const privacyDownloadLink = privacyFile
        ? URL.createObjectURL(privacyFile)
        : defaultPrivacyURL

    return (
        <Grid container>
            <Grid item xs={12} sx={{ padding: 1, marginTop: 10 }}>
                <Grid
                    item
                    xs={12}
                    sx={{ padding: 1, marginTop: 5, marginBottom: 10 }}
                >
                    <Typography variant="h5" color={'primary'} align={'center'}>
                        EULA and Privacy Policy PDFs
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                component="label"
                                style={{ width: '100%' }}
                            >
                                EULA Upload
                                <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={handleEulaUpload}
                                    style={{ display: 'none' }}
                                />
                            </Button>
                        </Grid>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h5" color={'primary'}>
                                    EULA PDF
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <a
                                    href={eulaDownloadLink}
                                    download={
                                        eulaFile ? eulaFile.name : 'EULA.pdf'
                                    }
                                    style={{
                                        color: 'inherit',
                                    }}
                                >
                                    Download EULA
                                </a>
                                <Grid container sx={{ height: '100vh' }}>
                                    <iframe
                                        title="FAQ"
                                        width="100%"
                                        height="auto"
                                        style={{ overflow: 'scroll' }}
                                        src={OsmosisEULA}
                                    ></iframe>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                component="label"
                                style={{ width: '100%' }}
                            >
                                Privacy Policy Upload
                                <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={handlePrivacyUpload}
                                    style={{ display: 'none' }}
                                />
                            </Button>
                        </Grid>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h5" color={'primary'}>
                                    Privacy Policy PDF
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <a
                                    href={privacyDownloadLink}
                                    download={
                                        privacyFile
                                            ? privacyFile.name
                                            : 'PrivacyPolicy.pdf'
                                    }
                                    style={{
                                        color: 'inherit',
                                    }}
                                >
                                    Download Privacy Policy
                                </a>
                                <Grid container sx={{ height: '100vh' }}>
                                    <iframe
                                        title="FAQ"
                                        width="100%"
                                        height="auto"
                                        style={{ overflow: 'scroll' }}
                                        src={OsmosisPrivacyPolicy}
                                    ></iframe>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LegalUploader
