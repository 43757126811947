import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api'
import { useState, useCallback, memo } from 'react'
import { Grid } from '@mui/material'
import { osmosisGoogleMapApi } from '../../config/osmosisConfig'

// Google Maps Documentation here: https://www.npmjs.com/package/@react-google-maps/api

const UniversalGoogleMap = ({ gps, style }) => {
    const lat = parseFloat(gps?.lat)
    const lng = parseFloat(gps?.lng)

    const containerStyle = {
        width: style.width,
        height: style.height,
    }
    const center = {
        lat,
        lng,
        zoom: 6,
    }
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: osmosisGoogleMapApi, // This will need to be imported from connection file later
    })

    const [googleMap, setGoogleMap] = useState(null)

    const onMapLoad = useCallback((map) => {
        const bounds = new google.maps.LatLngBounds(center)
        map.fitBounds(bounds)
        let listener = google.maps.event.addListener(map, 'idle', function () {
            if (map.getZoom() > 13) {
                map.setZoom(13)
            }
            google.maps.event.removeListener(listener)
        })

        setGoogleMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setGoogleMap(null)
    }, [])

    return (
        <Grid
            container
            sx={{
                backgroundColor: style.backgroundColor,
                margin: style.margin,
            }}
        >
            {isLoaded && gps.lng && gps.lat ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={9}
                    onLoad={onMapLoad}
                    onUnmount={onUnmount}
                >
                    <MarkerF clickable={true} position={{ lat, lng }} />
                </GoogleMap>
            ) : (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <h4>GPS IS CURRENTLY UNAVAILABLE</h4>
                </Grid>
            )}
        </Grid>
    )
}

export default memo(UniversalGoogleMap)
