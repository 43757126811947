import { Box, Grid, Typography } from '@mui/material'
import { FormSection } from '.'

interface ImageDisplayerProps {
    url: string
    alt?: string
    width?: string | number
    height?: string | number
    style?: React.CSSProperties
    description?: any
    title?: any
    subtitle?: any
}

const UniversalImageDisplayer = ({
    url,
    alt = 'Image',
    width = '90%',
    height = '90%',
    style,
    description,
    title,
    subtitle,
}: ImageDisplayerProps) => {
    return (
        <>
            <Box style={{ ...style }}>
                {title || subtitle ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography
                                    alignItems="center"
                                    color={'primary'}
                                    align="center"
                                    sx={{ marginTop: 1 }}
                                    variant="h2"
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    alignItems="center"
                                    color={'primary'}
                                    align="center"
                                    sx={{ marginTop: 2.5, marginBottom: -2.5 }}
                                    variant="h5"
                                >
                                    {subtitle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <img
                    src={url}
                    alt={alt}
                    width={width}
                    height={height}
                    style={{ display: 'block', margin: 'auto' }}
                />

                {description ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <FormSection>{description} </FormSection>
                        </Grid>
                    </Grid>
                ) : null}
            </Box>
        </>
    )
}

export default UniversalImageDisplayer
