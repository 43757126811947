import axios from 'axios'
import currentConfig from '../config'

const currentApi = currentConfig?.API_URL

interface buildRouteArg {
    type: string
    route: string
    id?: string | number
    data?: any
}

const buildRoute = async ({ type, route, data }: buildRouteArg) => {
    let apiRoute: any = ''
    if (type === 'get') {
        apiRoute = await axios.get(`${currentApi}/${route}`)
    }

    if (type === 'post') {
        if (route === 'authenticate') {
            apiRoute = await axios.post(`${currentApi}/${route}`, data)
            globalThis.username = apiRoute?.data.username
        } else if (route === 'user-deactivation-request') {
            apiRoute = await axios.post(`${currentApi}/${route}`, data)
        } else {
            apiRoute = await axios.post(`${currentApi}/${route}`, {
                ...data,
                updatedBy: globalThis.username,
                currentUserManufacturer: globalThis.userManufacturer,
            })
        }
    }
    if (type === 'file') {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }
        apiRoute = await axios.post(`${currentApi}/${route}`, data, config)
    }
    return apiRoute
}

export default buildRoute
