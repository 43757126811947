import { useContext, useEffect, useReducer } from 'react'
import { TextField, Button, Grid, Typography } from '@mui/material'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import { addManufacturer } from '../../../../apiRoutes'
import { FormSection, SaveButton } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'

const EngineManufacturerForm = ({
    row,
    formDescription,
    onClose,
    permissionCheck,
}) => {
    const defaultState = {
        formData: {
            manufacturerName: row.manufacturerName || '',
            manufacturerContactName: row.manufacturerContactName || '',
            manufacturerContactEmail: row.manufacturerContactEmail || '',
            id: row.id || '',
        },
        formValidation: {
            manufacturerName: false,
            manufacturerNameValidation: false,
            manufacturerContactNameValidation: false,
            manufacturerContactEmailValidation: false,
            isFormValid: false,
            isFormDirty: false,
        },

        engineOEM: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth

    const { formData, engineOEM, formValidation } = state
    const {
        manufacturerName,
        manufacturerContactEmail,
        manufacturerContactName,
    } = formData
    const {
        manufacturerNameValidation,
        manufacturerContactNameValidation,
        manufacturerContactEmailValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })
    }

    const handleSubmit = async () => {
        try {
            const parsedFormData = { ...formData, type: 'engine' }
            let res: any

            res = await addManufacturer(parsedFormData)
            if (res) {
                const { data, status } = res
                if (status === 200) {
                    setToastMessage(
                        `Engine Manufactuer: ${data.manufacturerName} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )
                    onClose(row.manufacturerName, true)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    useEffect(() => {
        const emailValid = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(manufacturerContactEmail)
        }
        if (
            isFormDirty &&
            !manufacturerNameValidation &&
            !manufacturerContactNameValidation &&
            !manufacturerContactEmailValidation &&
            emailValid() &&
            manufacturerName &&
            manufacturerContactName &&
            manufacturerContactEmail
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerNameValidation,
        manufacturerContactNameValidation,
        manufacturerContactEmailValidation,
        manufacturerName,
        manufacturerContactName,
        manufacturerContactEmail,
    ])

    const emailCheck = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(manufacturerContactEmail)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {row.manufacturerName
                        ? 'Use this form to edit an Engine Manufacturer within Osmosis Telematics Platform'
                        : 'Use this form to add an Engine Manufacturer within Osmosis Telematics Platform'}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <UniversalTagToolbar />
            </Grid>

            <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                    <FormSection title="Engine Manufacturer">
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                label="Manufacturer Name"
                                name="manufacturerName"
                                fullWidth
                                onChange={handleChange}
                                value={manufacturerName}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={manufacturerNameValidation}
                                helperText={
                                    manufacturerNameValidation
                                        ? 'Please enter a manufacturer name.'
                                        : ''
                                }
                                disabled={permissionCheck}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Manufacturer Contact Name"
                                name="manufacturerContactName"
                                onChange={handleChange}
                                fullWidth
                                required
                                value={manufacturerContactName}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={manufacturerContactNameValidation}
                                helperText={
                                    manufacturerContactNameValidation
                                        ? 'Please enter a enter a notification name.'
                                        : ''
                                }
                                disabled={permissionCheck}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Manufacturer Contact Email"
                                name="manufacturerContactEmail"
                                fullWidth
                                required
                                onChange={handleChange}
                                value={manufacturerContactEmail}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={
                                    manufacturerContactEmailValidation ===
                                        false && manufacturerContactEmail
                                        ? !emailCheck()
                                        : manufacturerContactEmailValidation &&
                                          !manufacturerContactEmail
                                }
                                helperText={
                                    manufacturerContactEmailValidation ===
                                        false && !manufacturerContactEmail
                                        ? manufacturerContactEmailValidation &&
                                          !manufacturerContactEmail
                                        : !emailCheck()
                                        ? 'Please enter a valid Email Address.'
                                        : ''
                                }
                                disabled={permissionCheck}
                            />
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
                <SaveButton
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid && permissionCheck}
                    disabledReset={!isFormDirty && permissionCheck}
                />
            </Grid>
        </Grid>
    )
}

export default EngineManufacturerForm
