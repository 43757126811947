import { useReducer, useEffect } from 'react'
import {
    DataGridLayout,
    UniversalCookieCrumb,
} from '../../UniversalComponents/'
import { useMinimizer } from '../../../context/MinimizeContext'
import BoatManagement from './BoatConfiguration'
import BoatModelConfig from './BoatModel'
import BoatManufacturer from './BoatManufacturer'

import { Grid, useTheme, AppBar, Tab, Tabs } from '@mui/material'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Grid
            container
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid container>
                    <Grid item xs={12} padding={2}>
                        {children}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

const Boats = () => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const defaultState = {
        currentTab: 0,
    }

    const [state, setState] = useReducer(reducer, defaultState)

    const { currentTab } = state

    const setCurrentTab = (event: React.SyntheticEvent, newValue: number) =>
        setState({ currentTab: newValue })

    const theme = useTheme()
    const { restoredData } = useMinimizer()

    useEffect(() => {
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'configuration'
        ) {
            setState({ currentTab: 0 })
        }
        if (restoredData?.id !== undefined && restoredData?.tab === 'model') {
            setState({ currentTab: 1 })
        }
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'manufacturer'
        ) {
            setState({ currentTab: 2 })
        }
    }, [restoredData])

    return (
        <>
            <DataGridLayout
                cookieCrumbs={<UniversalCookieCrumb />}
                tabBar={
                    <AppBar position="static">
                        <Tabs
                            value={currentTab}
                            onChange={setCurrentTab}
                            indicatorColor="secondary"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="Osmosis"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab label="Boat Configuration" />
                            <Tab label="Boat Model" />
                            <Tab label="Boat Manufacturer" />
                        </Tabs>
                    </AppBar>
                }
                dataGrid={
                    <>
                        <TabPanel
                            value={currentTab}
                            index={0}
                            dir={theme.direction}
                        >
                            <BoatManagement />
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={1}
                            dir={theme.direction}
                        >
                            <BoatModelConfig restoredData={restoredData} />
                        </TabPanel>
                        <TabPanel
                            value={currentTab}
                            index={2}
                            dir={theme.direction}
                        >
                            <BoatManufacturer restoredData={restoredData} />
                        </TabPanel>
                    </>
                }
            />
        </>
    )
}

export default Boats
