import { useReducer, useRef, useEffect } from 'react'
import { useSocket } from '../../context/SocketContext'
import {
    FormSection,
    UniversalToolBar,
    UniversalCookieCrumb,
    UniversalModal,
} from '../UniversalComponents'
import { Grid, TextField } from '@mui/material'
import { AddBox } from '@mui/icons-material'
import {
    SleepingPieChart,
    ModelsBarGraph,
    EngineModelsBarGraph,
    EngineHoursHistogram,
} from './ChartJsGraphics'

const Home = () => {
    const defaultState = {
        telemetry: [],
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { telemetry } = state

    const { socket } = useSocket()

    const refreshCount = useRef(0)
    const interval = useRef<any>()

    useEffect(() => {
        socket.emit('allFleetLiveData', { userRole: 'Super Admin' })
        socket.on('allFleetLiveDataRes', (data) => {
            if (data) {
                setState({
                    telemetry: data.filter(
                        (obj: any) => obj.redisObj !== 'noRedisObj'
                    ),
                })
            }
            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [])

    useEffect(() => {
        interval.current = setInterval(() => {
            socket.emit('allFleetLiveData', { userRole: 'Super Admin' })
            socket.on('allFleetLiveDataRes', (data) => {
                if (data) {
                    setState({
                        telemetry: data.filter(
                            (obj: any) => obj.redisObj !== 'noRedisObj'
                        ),
                    })
                }
                refreshCount.current = refreshCount.current + 1
                return refreshCount
            })

            return () => socket.emit('leave:room', '')
        }, 10000)

        return () => {
            clearInterval(interval.current)
        }
    }, [interval])

    return (
        <Grid container spacing={2} sx={{ marginTop: 10 }}>
            <Grid item xs={12}>
                <FormSection title="Chart JS">
                    <Grid item xs={6}>
                        <Grid container>
                            {telemetry && (
                                <SleepingPieChart
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        boatStatus: boat.redisObj._boatStatus,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={5} sx={{ marginTop: '10%' }}>
                        <Grid container>
                            {telemetry && (
                                <EngineHoursHistogram
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        engineHours:
                                            boat.redisObj.Totalenginehours_0,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            {telemetry && (
                                <ModelsBarGraph
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        model: boat._doc.boatModel.model,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            {telemetry && (
                                <EngineModelsBarGraph
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        engineModel:
                                            boat._doc.engine.engineModel
                                                .engineModelName,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    )
}

export default Home
