/* eslint-disable */
import { forwardRef, useRef, useReducer, ReactNode } from 'react'
import {
    Dialog,
    DialogActions,
    AppBar,
    Toolbar,
    Typography,
    Slide,
    IconButton,
    Grid,
    Breakpoint,
    Button,
    Paper,
    Box,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import {
    Close as CloseIcon,
    Minimize as MinimizeIcon,
} from '@mui/icons-material'
import Draggable from 'react-draggable'

interface UniversalModalProps {
    children?: React.ReactNode
    data?: any[]
    open?: boolean
    onClose?: any
    onMinimize?: any
    onOpen?: any
    ariaLabel?: string
    ariaDescription?: string
    title?: string
    description?: string
    fullScreen?: boolean
    wrapperStyle?: any
    formTitle?: string
    sx?: any
    maxWidth?: Breakpoint
    draggable?: boolean
    minimizable?: boolean
    timeStamp?: string
    buttons?: any
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const DraggableModal = (props, { title }) => {
    const nodeRef = useRef(null)
    return (
        <Draggable
            handle={`#draggable-dialog-title`}
            cancel={'[class*="MuiDialogContent-root"]'}
            nodeRef={nodeRef}
        >
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    )
}

const UniversalModal: React.FC<UniversalModalProps> = ({
    open = false,
    onClose = () => {},
    onMinimize = () => {},
    title = '',
    timeStamp = '',
    fullScreen = true,
    children,
    wrapperStyle,
    sx = {},
    maxWidth = 'lg',
    draggable = true,
    minimizable = false,
    buttons = [],
}) => {
    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={(event: any, reason: any) => {
                if (!fullScreen && reason !== 'backdropClick') {
                    return onClose
                } else {
                    return onMinimize()
                }
            }}
            TransitionComponent={Transition}
            sx={sx}
            PaperComponent={
                draggable && !fullScreen ? DraggableModal : undefined
            }
            aria-labelledby="draggable-dialog-title"
        >
            <AppBar
                sx={{ position: 'relative' }}
                id={
                    draggable && !fullScreen
                        ? `draggable-dialog-title`
                        : `non-draggable-dialog-title`
                }
                style={{ cursor: 'move' }}
            >
                <Toolbar>
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h5"
                        component="div"
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{ ml: 2, flex: 'flex', textAlign: 'right' }}
                        variant="h6"
                        component="div"
                        color={'primary'}
                    >
                        {timeStamp}
                    </Typography>
                    {minimizable && (
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={onMinimize}
                            aria-label="minimize"
                            style={{ marginRight: 2 }}
                        >
                            <MinimizeIcon />
                        </IconButton>
                    )}
                    <IconButton
                        edge="end"
                        color="primary"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container sx={wrapperStyle}>
                {children}
                <DialogActions>
                    {buttons.map((button) => (
                        <Button onClick={button.onClick}>{button.name}</Button>
                    ))}
                </DialogActions>
            </Grid>
        </Dialog>
    )
}

export default UniversalModal
