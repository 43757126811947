import { useReducer, MouseEvent, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import LogoutIcon from '@mui/icons-material/Logout'
import Badge from '@mui/material/Badge'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import BalanceIcon from '@mui/icons-material/Balance'
import UserForm from '../Admin/Users/UserAdmin/UserForm'
import {
    Menu as MenuIcon,
    DynamicFeed as DynamicFeedIcon,
    PhoneIphone as PhoneIphoneIcon,
    Insights,
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    MenuBookOutlined as MenuBookOutlinedIcon,
    Checklist as ChecklistIcon,
    Close as CloseIcon,
} from '@mui/icons-material'
import FAQ from '../FAQ'
import UserGuidePdf from '../UserGuide'
import { getUser, editUser } from '../../apiRoutes/userRoutes'
import { useToastMessage } from '../../context/ToastPopUpContext'

import {
    IconButton,
    Box,
    Tooltip,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItem,
    Typography,
} from '@mui/material'
import { useAuth } from '../../context/AuthenticationContext'
import { useSocket } from '../../context/SocketContext'
import { UniversalModal } from '../UniversalComponents'
import LegalUploader from '../LegalDocumentationUploader/LegalUploader'
import NavMenuNotificationsModal from './NavMenuNotificationsModal'
import { useVersionChecker } from '../../context/VersionCheckerContext'
import { permissionsCheck } from '../../utilities/functionTools'
import { usePermissions } from '../../context/AuthenticationContext'

const NavMenuAvatar = () => {
    const authentication = useAuth()
    const { socket } = useSocket()
    const openChangeLog = useVersionChecker()
    const { auth, setAuth, signOutApp } = authentication
    const defaultState = {
        anchorEl: null,
        anchorUser: null,
        username: auth?.username?.toUpperCase(),
        openUser: false,
        openBoats: false,
        openLegal: false, // Initialize openLegal to false
        userNotifications: [],
        notificationsEnabled: true,
        openNotifications: false,
        user: {},
    }
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const currentUser = useAuth()
    const { username, _id } = currentUser.auth
    const {
        notifications,
        openFaq,
        openUserGuide,
        openLegal,
        notificationsEnabled,
        user,
        userNotifications,
    } = state

    const setToastMessage = useToastMessage()
    const userPermissions = usePermissions()

    const handleClearNotifications = async () => {
        try {
            await editUser({
                ...user,
                id: user._id,
                userNotifications: [],
            })
            setState({ userNotifications: [] })
        } catch (err) {
            console.error(err)
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleDeleteNotification = async (deleteId: any) => {
        const newNotifications = userNotifications.filter(
            ({ _id }) => _id !== deleteId
        )
        try {
            await editUser({
                ...user,
                id: user._id,
                userNotifications: newNotifications,
            })
            setState({ userNotifications: newNotifications })
        } catch (err) {
            console.error(err)
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setState({
            anchorUser: event.currentTarget,
            anchorEl: event.currentTarget,
        })
    }

    const disableNotifications = () => {
        setState({ notificationsEnabled: !state.notificationsEnabled })
    }

    const handleCloseUserMenu = () => {
        setState({ anchorUser: defaultState.anchorUser })
    }

    const handleOpenNotifications = () => {
        setState({ openNotifications: !state.openNotifications })
    }

    const logout = () => {
        signOutApp()
    }

    const loggedIn = [
        {
            title: 'Account',
            onClick: () =>
                setState({ openUser: !state.openUser, anchorUser: null }),
            icon: <PeopleOutlineIcon />,
            disabled: permissionsCheck(userPermissions, 'Account'),
        },
        {
            title: 'Change Log',
            onClick: openChangeLog,
            icon: <ChecklistIcon />,
        },
        {
            title: 'FAQ',
            onClick: () => setState({ openFaq: !openFaq, anchorUser: null }),
            icon: <HelpOutlineOutlinedIcon />,
        },
        {
            title: 'User Guide',
            onClick: () =>
                setState({ openUserGuide: !openUserGuide, anchorUser: null }),
            icon: <MenuBookOutlinedIcon />,
        },
        {
            title: 'Legal Agreements',
            onClick: () =>
                setState({ openLegal: !openLegal, anchorUser: null }),
            icon: <BalanceIcon />,
        },
        { title: 'Logout', onClick: logout, icon: <LogoutIcon /> },
    ]

    const getData = async () => {
        try {
            const user = await getUser({ _id })
            console.log(user)
            if (user) {
                setState({ user, userNotifications: user.userNotifications })
            } else {
                throw new Error('No user found')
            }
        } catch (error) {
            console.log('error')
        }
    }

    useEffect(() => {
        socket.on(_id, () => {
            getData()
        })
        getData()
    }, [])

    const setModalVisbility = async (user: any, newData: any) => {
        if (user) {
            setState({ row: user })
        } else {
            setState({
                row: defaultState.user,
            })
        }
        if (newData !== undefined) {
            return getData()
        }
    }
    const userFormPermissions =
        userPermissions?.userRole === 'Super Admin' ||
        userPermissions?.userRole === 'Admin'

    return (
        <Grid id="menu-grid">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Open settings">
                    <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{ p: 0, mr: 2 }}
                    >
                        <Avatar
                            src=""
                            sx={{ color: 'inherit' }}
                            variant="rounded"
                        >
                            {state.username &&
                                state.username?.length !== 0 &&
                                state.username?.substring(0, 1)}
                        </Avatar>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Open Notifications Panel">
                    <Typography
                        color={'primary'}
                        align="center"
                        sx={{ margin: 2 }}
                    >
                        <Badge
                            badgeContent={
                                state.notificationsEnabled
                                    ? userNotifications &&
                                      userNotifications?.length
                                    : null
                            }
                            color="primary"
                        >
                            <IconButton
                                onClick={handleOpenNotifications}
                                sx={{ color: 'primary' }}
                            >
                                {state.notificationsEnabled ? (
                                    <NotificationsActiveIcon />
                                ) : (
                                    <NotificationsOffIcon />
                                )}
                            </IconButton>
                        </Badge>
                    </Typography>
                </Tooltip>
            </Box>
            <Menu
                sx={{
                    mt: '65px',
                    display: {
                        xs: 'flex',
                        sm: 'flex',
                        md: 'flex',
                        lg: 'flex',
                    },
                }}
                id="menu-appbar"
                anchorEl={state.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(state.anchorUser)}
                onClose={handleCloseUserMenu}
            >
                <List>
                    {loggedIn.map(({ title, onClick, icon }) => (
                        <ListItem
                            sx={{
                                color: 'inherit',
                                display: 'block',
                            }}
                            key={`${title}.button`}
                            onClick={onClick}
                        >
                            <ListItemButton>
                                <ListItemIcon
                                    key={`${icon}.iconbutton`}
                                    children={icon}
                                    sx={{ marginRight: -3 }}
                                />
                                <ListItemText
                                    sx={{
                                        my: -2,
                                    }}
                                    primary={title}
                                    key={`${title}.li.button.title`}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Menu>

            <UniversalModal
                onClose={() => setState({ openLegal: false })}
                open={state.openLegal}
                title="Legal Agreements"
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                {state.openLegal && (
                    <LegalUploader
                        onClose={() => setState({ openLegal: false })}
                    />
                )}
            </UniversalModal>
            <UniversalModal
                onClose={() => setState({ openUser: false })}
                open={state.openUser}
                title={` User ID: ${_id}`}
                timeStamp={` Username: ${username}`}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <UserForm
                    row={user}
                    userAccount={true}
                    onClose={(user: any, newData?: any) =>
                        setModalVisbility(user, newData)
                    }
                    userFormPermissionCheck={false}
                    userFormPermissionBlockCheck={userFormPermissions}
                />
            </UniversalModal>

            <NavMenuNotificationsModal
                open={state.openNotifications}
                onClose={handleOpenNotifications}
                userNotifications={userNotifications}
                onClearNotifications={handleClearNotifications}
                handleDeleteNotification={handleDeleteNotification}
                disableNotifications={disableNotifications}
                notificationsEnabled={notificationsEnabled}
            />

            <UniversalModal
                onClose={() => setState({ openFaq: !openFaq })}
                open={openFaq}
                title="FAQ"
            >
                <FAQ />
            </UniversalModal>

            <UniversalModal
                onClose={() => setState({ openUserGuide: !openUserGuide })}
                open={openUserGuide}
                title="User Guide"
            >
                <UserGuidePdf />
            </UniversalModal>
        </Grid>
    )
}

export default NavMenuAvatar
