import { ReactNode } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { Grid } from '@mui/material'

interface UniversalReactSorterTypes {
    list: any[]
    setList: any
    group: string
    style?: {}
    disabled?: boolean
    children: ReactNode
}
const UniversalReactSorter = ({
    list,
    setList,
    group,
    style,
    disabled = false,
    children,
}: UniversalReactSorterTypes) => {
    return (
        <ReactSortable
            list={list}
            setList={setList}
            group={group}
            style={style}
            disabled={disabled}
        >
            {children}
        </ReactSortable>
    )
}

export default UniversalReactSorter
