import {
    Card,
    CardContent,
    Grid,
    CardHeader,
    Typography,
    CardMedia,
    Box,
    IconButton,
    CircularProgress,
    Tooltip,
} from '@mui/material'
import { useRef, useEffect } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

interface SignalProfileCardsProps {
    image?: string
    description?: string
    sx?: {}
    unitOfMeasurement?: string
    title: string
    disabled?: boolean
    size?: string
    removeSignal: (type: string, name: string) => void
    type: string
}

const SignalProfileCards = ({
    image,
    description,
    sx = {},
    unitOfMeasurement,
    title,
    disabled = false,
    size,
    removeSignal,
    type,
}: SignalProfileCardsProps) => {
    const timeout = useRef(false)
    useEffect(() => {
        setTimeout(() => {
            timeout.current = true
            return timeout
        }, 10000)
    }, [])

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                width: {
                    xs: '100%',
                    md: size === 'web' ? '33%' : '48%',
                },
            }}
        >
            <Grid item xs={12}>
                <Card
                    sx={{
                        height: 100,
                        display: 'flex',
                        margin: 0.5,
                        cursor: 'grabbing',
                    }}
                >
                    <Tooltip title="Remove Signal">
                        <IconButton
                            aria-label="Remove Signal"
                            onClick={() => removeSignal(type, title)}
                            sx={{ position: 'relative', bottom: 2, right: 2 }}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </Tooltip>
                    <CardMedia
                        component="img"
                        sx={{
                            width: 65,
                            height: 65,
                            position: 'relative',
                            top: 13,
                            left: 10,
                            marginLeft: -2,
                        }}
                        image={image}
                        alt={description}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                            >
                                {title && title?.length > 18
                                    ? `${title?.substring(0, 18)}...`
                                    : title}
                            </Typography>
                            <Typography component="div" variant="h5">
                                {description === 'No Data' &&
                                timeout.current === false ? (
                                    <CircularProgress
                                        color="primary"
                                        size={25}
                                    />
                                ) : description !== 'No Data' ? (
                                    `${description} ${unitOfMeasurement}`
                                ) : (
                                    description
                                )}
                            </Typography>
                        </CardContent>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    )
}

export default SignalProfileCards
