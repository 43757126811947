import React, { useReducer } from 'react'
import {
    TextField,
    Autocomplete,
    Button,
    Chip,
    Grid,
    Toolbar,
    AppBar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Tooltip,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import StyleIcon from '@mui/icons-material/Style'

const UniversalTagToolbar = () => {
    const defaultState = {
        newTag: '',
        tags: [],
        availableTags: ['Osmosis', 'Indmar', 'PCM'],
        open: false,
        addingTags: false,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { newTag, tags, availableTags, open, addingTags } = state

    const handleStartTagging = () => {
        setState({ addingTags: true })
    }

    const handleClickOpen = () => {
        setState({ open: true })
    }

    const handleClose = () => {
        setState({ open: false })
    }

    const handleTagChange = (event, newValue) => {
        setState({ tags: newValue })
    }

    const handleCustomTagChange = (event) => {
        setState({ newTag: event.target.value })
    }

    const saveCustomTag = () => {
        if (newTag && !tags.includes(newTag)) {
            setState({ tags: [...tags, newTag] })

            if (!availableTags.includes(newTag)) {
                setState({ availableTags: [...availableTags, newTag] })
            }

            setState({ newTag: '' })
            handleClose()
        }
    }

    const removeTag = (tag) => {
        const updatedTags = tags.filter((t) => t !== tag)
        setState({ tags: updatedTags })
    }

    const filterOptions = (options, state) => {
        return options.filter((option) => !state.includes(option))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginBottom: 5 }}>
                <AppBar position="static">
                    <Toolbar
                        variant="dense"
                        style={{ marginBottom: 10, marginTop: 10 }}
                    >
                        {!addingTags && (
                            <Grid item xs={2}>
                                <Tooltip title={'Add Tags'}>
                                    <IconButton onClick={handleStartTagging}>
                                        <StyleIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {addingTags && (
                            <Grid item xs={3}>
                                <Autocomplete
                                    multiple
                                    id="tag-select"
                                    options={filterOptions(availableTags, tags)}
                                    value={tags}
                                    isOptionEqualToValue={(option, value) =>
                                        option === value
                                    }
                                    onChange={handleTagChange}
                                    style={{ width: '100%' }}
                                    renderTags={() => ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Tags"
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        {addingTags && (
                            <Grid item xs={2}>
                                <Grid item xs={2} style={{ marginLeft: 30 }}>
                                    <Tooltip title={'Add New Tag'}>
                                        <IconButton onClick={handleClickOpen}>
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                        position: 'fixed',
                                        top: -200,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 20,
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogTitle id="alert-dialog-title">
                                        Add New Tag
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ marginTop: 5 }}
                                        >
                                            <TextField
                                                label="New Tag"
                                                value={newTag}
                                                onChange={handleCustomTagChange}
                                            />
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    width: '100%',
                                                    height: 50,
                                                }}
                                                onClick={saveCustomTag}
                                            >
                                                Save Custom Tag
                                            </Button>
                                        </Grid>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            {tags.map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    onDelete={() => removeTag(tag)}
                                    color="primary"
                                    sx={{ margin: 1 }}
                                />
                            ))}
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
        </Grid>
    )
}

export default UniversalTagToolbar
