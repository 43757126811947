import buildRoute from './buildRoute'

export const getParts = async () => {
    try {
        let res: any
        res = await buildRoute({ type: 'post', route: 'parts' })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

// export const addEngine = async (formData: any) => {
//     try {
//         let res: any
//         res = await buildRoute({
//             type: 'post',
//             route: 'engine',
//             data: formData,
//         })
//         if (res.data) {
//             return res
//         }
//     } catch (err: any) {
//         console.error(err)
//         return err
//     }
// }

// export const archiveEngines = async (engines: any) => {
//     try {
//         let res: any
//         res = await buildRoute({
//             type: 'post',
//             route: 'archive-engine',
//             data: engines,
//         })
//         if (res.data) {
//             return res
//         }
//     } catch (err: any) {
//         console.error(err)
//         return err
//     }
// }

// export const editEngines = async (formData: any) => {
//     try {
//         let res: any
//         res = await buildRoute({
//             type: 'post',
//             route: 'update-engine',
//             data: formData,
//         })
//         if (res.data) {
//             return res
//         }
//     } catch (err: any) {
//         return console.error(err)
//     }
// }

const partRoutes = {
    getParts,
}

export default partRoutes
