import {
    Drawer,
    Grid,
    Tooltip,
    Button,
    Stack,
    styled,
    Paper,
    IconButton,
    Typography,
    Toolbar,
} from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material/'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import {
    Close as CloseIcon,
    Minimize as MinimizeIcon,
} from '@mui/icons-material'

const NavMenuNotificationsModal = ({
    open,
    onClose,
    userNotifications,
    onClearNotifications,
    handleDeleteNotification,
    disableNotifications,
    notificationsEnabled,
}) => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#222' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: 5,
    }))

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Toolbar
                sx={{
                    background: 'secondary',
                    alignContent: 'center',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h4" color="primary">
                    Notification Center
                </Typography>
                <div>
                    <Tooltip
                        title={
                            notificationsEnabled
                                ? 'Disable Notifications'
                                : 'Enable Notifications'
                        }
                    >
                        <IconButton
                            onClick={disableNotifications}
                            sx={{ color: 'primary' }}
                        >
                            {notificationsEnabled ? (
                                <NotificationsActiveIcon />
                            ) : (
                                <NotificationsOffIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        edge="end"
                        color="primary"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </Toolbar>

            <Grid
                container
                sx={{ alignContent: 'center', padding: 3 }}
                spacing={2}
            >
                <Grid item xs={12} sx={{ width: 350 }}>
                    <Stack sx={{ height: 650, overflowX: 'scroll' }}>
                        {userNotifications &&
                            userNotifications?.length !== 0 &&
                            userNotifications.map(
                                ({
                                    _id,
                                    message,
                                    updatedAt,
                                    count,
                                    boatState,
                                }) => (
                                    <Item
                                        key={_id}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                                <Tooltip
                                                    title="Delete Notification"
                                                    key={`${_id}.delete`}
                                                >
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() =>
                                                            handleDeleteNotification(
                                                                _id
                                                            )
                                                        }
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography
                                                    variant="h6"
                                                    color={'primary'}
                                                    align="left"
                                                    sx={{ margin: 2 }}
                                                >
                                                    {message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color={'primary'}
                                                    align="left"
                                                    sx={{ margin: 2 }}
                                                >
                                                    {`Most Recent: ${new Date(
                                                        updatedAt
                                                    )
                                                        .toString()
                                                        .substring(0, 25)}`}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    color={'primary'}
                                                    align="left"
                                                    sx={{ margin: 2 }}
                                                >
                                                    {`Boat State: ${boatState}`}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    color={'primary'}
                                                    align="left"
                                                    sx={{ margin: 2 }}
                                                >
                                                    {`Number Of Occurrences: ${count}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                )
                            )}
                    </Stack>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        position: 'relative',
                        bottom: 10,
                        left: 10,
                        background: '#444',
                    }}
                >
                    <Grid item xs={12}>
                        <Button
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                            onClick={onClearNotifications}
                            color="error"
                            disabled={
                                userNotifications &&
                                userNotifications?.length === 0
                            }
                            variant="contained"
                        >
                            Clear Notifications
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onClose}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            Close Notifications
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default NavMenuNotificationsModal
