import { useReducer, useRef, useEffect } from 'react'
import { FormSection } from '../../UniversalComponents'
import { Grid, TextField } from '@mui/material'
import { AddBox } from '@mui/icons-material'
import {
    UniversalToolBar,
    UniversalCookieCrumb,
    UniversalModal,
} from '../../UniversalComponents'
import AnalyticsChartForm from './AnalyticsChartForm'
import AnalyticsChartNode from './AnalyticsChartNode'
import { convertShortDate } from '../../../utilities/functionTools'

const dataMeasure1 = 19
const dataMeasure2 = 48

const data = [
    {
        chartName: 'GPS Pie Chart',
        chartType: 'Doughnut',
        labels: ['GPS Enabled Boats', 'GPS Disabled Boats'],
        datasets: [
            {
                label: '# of Boats',
                data: [dataMeasure1, dataMeasure2],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
                borderWidth: 2,
            },
        ],
    },
    {
        chartName: 'Test Bar Graph',
        chartType: 'Chart',
        labels: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
        ],
        datasets: [
            {
                type: 'line' as const,
                label: 'Dataset 10',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: [2, 3, 4, 6, 8],
            },
            {
                type: 'bar' as const,
                label: 'Dataset 2',
                backgroundColor: 'rgb(75, 192, 192)',
                data: [2, 3, 4, 6, 8],
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar' as const,
                label: 'Dataset 3',
                backgroundColor: 'rgb(53, 162, 235)',
                data: [2, 3, 4, 6, 8],
            },
        ],
    },
]

const Analytics = () => {
    const defaultState = {
        selectedChart: {},
        open: false,
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { selectedChart, open } = state

    const refreshCount = useRef(0)

    const setModalVisbility = async (selectChart: any, newData?: any) => {
        if (selectChart?.chartName) {
            setState({ selectedChart: selectChart, open: !open })
        } else {
            return setState({
                selectedChart: defaultState.selectedChart,
                open: !open,
            })
        }
        // if (newData !== undefined) {
        //     return getData()
        // }
    }
    const toolbarButtons = [
        {
            buttonName: 'Add New Chart',
            buttonIcon: <AddBox />,
            buttonFunction: setModalVisbility,
        },
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 10 }}>
                <UniversalCookieCrumb customTitle="Analytics" />
            </Grid>
            <Grid item xs={12} id="analyticsWrapper">
                <Grid container spacing={2} sx={{ marginBottom: -8 }}>
                    <Grid item xs={12}>
                        <UniversalToolBar buttons={toolbarButtons} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSection title="Filters">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="upcomingFilter"
                                    label="Filter Placeholder"
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: -2 }}>
                        <FormSection title="Graphs">
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {data &&
                                        data.length !== 0 &&
                                        data.map((item) => (
                                            <AnalyticsChartNode
                                                chartType={item.chartType}
                                                item={item}
                                                onClick={setModalVisbility}
                                                title={item.chartName}
                                                size={4}
                                                key={`${item.chartName}.ChartNode`}
                                            />
                                        ))}
                                </Grid>
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>
            </Grid>
            <UniversalModal
                title={'Configure Analytics'}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                timeStamp={`${
                    selectedChart.chartName
                        ? ` ${
                              selectedChart.updatedBy
                                  ? ` Modified By ${selectedChart.updatedBy}`
                                  : ''
                          } ${
                              selectedChart.updatedAt
                                  ? `| Modified Date ${selectedChart.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${
                              selectedChart?.username
                          } | Modified Date: ${convertShortDate(new Date())} `
                }`}
                // onMinimize={minimizeModal}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 10,
                    marginBottom: 5,
                }}
                draggable={true}
                minimizable={false}
            >
                <AnalyticsChartForm
                    selectedChart={selectedChart}
                    formDescription="Enter Boat Details To Add A New Boat."
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                />
            </UniversalModal>
        </Grid>
    )
}

export default Analytics
