import { useState, useEffect, createContext, useContext } from 'react'
import localforage from 'localforage'
import packageJson from '../../package.json'
import VersionCheckerModal from './VersionCheckerModal/VersionCheckerModal'
import { useAuth } from './AuthenticationContext'

type Props = {
    children: React.ReactNode
}

export const VersionChecker = createContext(() => {})

export const VersionCheckerContext = ({ children }: Props) => {
    const [open, setOpen] = useState(false)
    const { auth } = useAuth()
    const authenticated = auth?.authenticated
    useEffect(() => {
        const checkVersionDiff = async () => {
            const getVersion: any = await localforage.getItem('version')
            const getLiveVersion = packageJson.version
                .substring(6, packageJson.version.length)
                .replaceAll('.', '')

            const liveVersion = parseInt(getLiveVersion, 10)
            if (
                (getVersion === undefined && authenticated) ||
                (getVersion !== liveVersion && authenticated)
            ) {
                setOpen(true)
            }
            if (authenticated) {
                localforage.setItem('version', liveVersion, (err) =>
                    console.log(err)
                )
            }
        }

        checkVersionDiff()
    }, [authenticated])

    return (
        <VersionChecker.Provider value={() => setOpen(true)}>
            {children}
            <VersionCheckerModal open={open} onClose={() => setOpen(false)} />
        </VersionChecker.Provider>
    )
}

export const useVersionChecker = () => useContext(VersionChecker)
