import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
    signOut,
} from 'firebase/auth'

export const createUser = async ({
    email,
    adminEmail,
    adminPassword,
    firebaseUser,
    authConnection,
}) => {
    let checkAdminPassword: any
    let uid: any
    try {
        if (firebaseUser) {
            const credential = EmailAuthProvider.credential(
                adminEmail,
                adminPassword
            )
            checkAdminPassword = await reauthenticateWithCredential(
                firebaseUser,
                credential
            )

            if (checkAdminPassword) {
                console.log(checkAdminPassword, 'checkl password')
                const createNewUserAuth = await createUserWithEmailAndPassword(
                    authConnection,
                    email,
                    'Osmosis1!'
                )
                uid = createNewUserAuth && createNewUserAuth.user.uid

                if (createNewUserAuth && uid) {
                    await signOut(authConnection)
                    await signInWithEmailAndPassword(
                        authConnection,
                        adminEmail,
                        adminPassword
                    )
                    return uid
                }
            }
        }
    } catch (err: any) {
        return err.code
    }
}

const firebaseRoutes = {
    createUser,
}

export default firebaseRoutes
