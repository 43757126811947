import { useRef } from 'react'
import {
    Grid,
    Button,
    Typography,
    Tooltip as TooltipIcon,
    IconButton,
} from '@mui/material'
import AddHomeIcon from '@mui/icons-material/AddHome'
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    ArcElement,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    CategoryScale,
    BarElement,
    BarController,
} from 'chart.js'
import {
    Chart,
    getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent,
    Pie,
    Doughnut,
} from 'react-chartjs-2'
ChartJS.register(
    ArcElement,
    LineController,
    LinearScale,
    CategoryScale,
    BarElement,
    BarController,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title
)

const options = {
    scales: {
        y: {
            beginAtZero: true,
        },
    },
}

interface AnalyticsChartNodeTypes {
    chartType: string
    item: any
    onClick: any
    title: string
    size?: number
    onChartClick?: any
    editDisabled?: boolean
}

const AnalyticsChartNode = ({
    chartType,
    item,
    onClick,
    size = 6,
    onChartClick = () => {},
    editDisabled,
}: AnalyticsChartNodeTypes) => {
    const chartRef = useRef<ChartJS>(null)
    return (
        <Grid
            item={true}
            xs={size}
            sx={{
                margin: 2,
                border: 'solid 1px rgb(140,198,63)',
                borderRadius: 2,
                padding: 2,
            }}
            key={`${chartType}.${item.chartName}.Wrapper`}
        >
            <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                    <Typography align="center" variant="h6" color={'primary'}>
                        {item.chartName}
                        {!editDisabled && (
                            <TooltipIcon
                                title="Pin To Homepage"
                                sx={{ position: 'relative', top: -3 }}
                            >
                                <IconButton>
                                    <AddHomeIcon />
                                </IconButton>
                            </TooltipIcon>
                        )}
                    </Typography>
                </Grid>

                {(chartType === 'Doughnut' && (
                    <Doughnut data={item} key={`${item.chartName}.Doughnut`} />
                )) ||
                    (chartType === 'Chart' && (
                        <Chart
                            ref={chartRef}
                            type="bar"
                            onClick={onChartClick}
                            options={options}
                            data={item}
                            key={`${item.chartName}.Chart`}
                        />
                    ))}

                {!editDisabled && (
                    <Grid item={true} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onClick(item)}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            Edit
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default AnalyticsChartNode
