import {
    Grid,
    Typography,
    TextField,
    Button,
    Autocomplete,
} from '@mui/material'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import { getManufacturers } from '../../../../apiRoutes'
import { addManufacturer } from '../../../../apiRoutes'
import { FormSection, SaveButton } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import BrandConfigurationForm from './ManufacturerConfigurationForm'

const BoatManufacturerForm = ({
    row,
    formDescription,
    onClose,
    permissionCheck,
}) => {
    const defaultState = {
        formData: {
            manufacturerName: row.manufacturerName || '',
            manufacturerContactName: row.manufacturerContactName || '',
            manufacturerContactEmail: row.manufacturerContactEmail || '',
            id: row.id || '',
        },
        formValidation: {
            manufacturerNameValidation: false,
            manufacturerContactNameValidation: false,
            manufacturerContactEmailValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        engineOEM: [],
        permissionsCheck: permissionCheck,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth

    const { formData, formValidation, engineOEM, permissionsCheck } = state
    const {
        manufacturerName,
        manufacturerContactEmail,
        manufacturerContactName,
    } = formData

    const {
        manufacturerContactNameValidation,
        manufacturerContactEmailValidation,
        manufacturerNameValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })
    }

    const handleSubmit = async () => {
        try {
            const parsedFormData = { ...formData, type: 'boat' }
            let res: any
            {
                res = await addManufacturer(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `Boat Manufactuer: ${data.manufacturerName} was ${
                                row.id ? 'edited' : 'added'
                            } successfully`,
                            'success'
                        )

                        onClose(row.manufacturerName, data)
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const getEngineManufacturerData = async () => {
        try {
            const engineOEM = await getManufacturers(true)

            if (engineOEM && engineOEM.length !== 0) {
                setState({ engineOEM })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    useEffect(() => {
        getEngineManufacturerData()
    }, [])

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    useEffect(() => {
        const emailValid = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(manufacturerContactEmail)
        }
        if (
            isFormDirty &&
            !manufacturerContactNameValidation &&
            !manufacturerContactEmailValidation &&
            !manufacturerNameValidation &&
            emailValid() &&
            manufacturerContactName &&
            manufacturerContactEmail &&
            manufacturerName
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerNameValidation,
        manufacturerContactNameValidation,
        manufacturerContactEmailValidation,
        manufacturerNameValidation,
        manufacturerContactEmail,
        manufacturerContactName,
    ])

    const emailCheck = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(manufacturerContactEmail)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {row.manufacturerName
                        ? 'Use this form to edit a Boat Manufacturer within Osmosis Telematics Platform'
                        : 'Use this form to add a Boat Manufacturer within Osmosis Telematics Platform'}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <UniversalTagToolbar />
            </Grid>

            <Grid item xs={12}>
                <FormSection title="Boat Manufacturer Details">
                    <Grid item xs={12} md={4}>
                        <TextField
                            required={true}
                            label="Manufacturer Name"
                            name="manufacturerName"
                            onChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerName}
                            error={
                                manufacturerNameValidation && !manufacturerName
                            }
                            helperText={
                                manufacturerNameValidation && !manufacturerName
                                    ? 'Please enter a Enter A Manufacturer Name.'
                                    : ''
                            }
                            disabled={permissionsCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required={true}
                            label="Manufacturer Contact Name"
                            name="manufacturerContactName"
                            onChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerContactName}
                            error={
                                manufacturerContactNameValidation &&
                                !manufacturerContactName
                            }
                            helperText={
                                manufacturerContactNameValidation &&
                                !manufacturerContactName
                                    ? 'Please enter a Manufacturer Contact Name.'
                                    : ''
                            }
                            disabled={permissionsCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required={true}
                            label="Manufacturer Contact Email"
                            name="manufacturerContactEmail"
                            onChange={handleChange}
                            fullWidth
                            onBlur={validateOnBlur}
                            value={manufacturerContactEmail}
                            error={
                                manufacturerContactEmailValidation === false &&
                                manufacturerContactEmail
                                    ? !emailCheck()
                                    : manufacturerContactEmailValidation &&
                                      !manufacturerContactEmail
                            }
                            helperText={
                                manufacturerContactEmailValidation === false &&
                                !manufacturerContactEmail
                                    ? manufacturerContactEmailValidation &&
                                      !manufacturerContactEmail
                                    : !emailCheck()
                                    ? 'Please enter a Manufacturer Contact Email.'
                                    : ''
                            }
                            disabled={permissionsCheck}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: -1 }}>
                <BrandConfigurationForm
                    row={row}
                    permissionCheck={permissionsCheck}
                />
            </Grid>

            <Grid item xs={12}>
                <SaveButton
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid && permissionCheck}
                    disabledReset={!isFormDirty && permissionCheck}
                />
            </Grid>
        </Grid>
    )
}

export default BoatManufacturerForm
