import { useContext, useReducer } from 'react'
import {
    Button,
    Card,
    Grid,
    IconButton,
    Tooltip,
    styled,
    CardMedia,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
} from '@mui/material'
import { FormSection } from '../../../UniversalComponents'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import PhotoIcon from '@mui/icons-material/Photo'
import { MuiColorInput } from 'mui-color-input'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CheckIcon from '@mui/icons-material/Check'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

const initialDataSet = {
    primaryColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
    secondaryColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
    tertiaryColorColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
}

const ColorPreview = styled('div')(({ color }) => ({
    width: 30,
    height: 30,
    backgroundColor: color,
    borderRadius: 4,
    border: '1px solid #000',
}))

const ManufacturerConfigurationForm = ({ row, permissionCheck }) => {
    const defaultState = {
        formData: {
            id: row.id,
            _id: row._id,
            logoUrl: row.logo || '',
            loginVideoUrl: row.loginVideoUrl || '',
            primaryColor: row.primaryColor || '',
            secondaryColor: row.secondaryColor || '',
            tertiaryColor: row.tertiaryColor || '',
            eulaFile: {},
            privacyFile: {},
        },
        formValidation: {
            isFormValid: false,
            isFormDirty: false,
        },
        logoImage: {},
        logo: '',
        loginVideo: '',
        loginVideoDetails: '',
        permissionsCheck: permissionCheck,
        eula: [],
        privacyPolicy: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)

    const { formValidation, formData, logo, loginVideo, eula, privacyPolicy } =
        state

    const {
        primaryColor,
        secondaryColor,
        tertiaryColor,
        privacyFile,
        eulaFile,
    } = formData

    const { isFormDirty } = formValidation

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleLogoReset = () =>
        setState({
            ...state,
            ...formData,
            logoImage: defaultState.logoImage,
            logo: defaultState.logo,
            formValidation: defaultState.formValidation,
        })

    const handleVideoReset = () =>
        setState({
            ...state,
            ...formData,
            loginVideoDetails: defaultState.loginVideoDetails,
            loginVideo: defaultState.loginVideo,
            formValidation: defaultState.formValidation,
        })

    const handleDocumentReset = () =>
        setState({
            ...state,
            formData: {
                ...formData,
                privacyFile: defaultState.formData.privacyFile,
                eulaFile: defaultState.formData.eulaFile,
            },
            eula: defaultState.eula,
            privacyPolicy: defaultState.privacyPolicy,
            formValidation: defaultState.formValidation,
        })

    const handleColorReset = () =>
        setState({
            formData: {
                ...formData,
                primaryColor: defaultState.formData.primaryColor,
                secondaryColor: defaultState.formData.secondaryColor,
                tertiaryColor: defaultState.formData.tertiaryColor,
            },
            formValidation: defaultState.formValidation,
        })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            formData: { ...formData, [name]: value },
        })
    }

    const handleFileSelect = (event, isImage = false, isVideo = false) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }

            if (isImage) {
                reader(file, (err, res) => {
                    setState({
                        logo: res,
                        logoImage: file,
                        formValidation: {
                            ...formValidation,
                            isFormDirty: true,
                        },
                    })
                })
            } else if (isVideo) {
                reader(file, (err, res) => {
                    setState({
                        loginVideoDetails: res,
                        loginVideo: res,
                        formValidation: {
                            ...formValidation,
                            isFormDirty: true,
                        },
                    })
                })
            } else {
                setState({
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            }
        }
    }

    const handleEulaUpload = (event: any) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }
            reader(file, (err, res) => {
                setState({
                    eula: res,
                    eulaFile: file,
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            })
        }
    }

    const handlePrivacyUpload = (event: any) => {
        if (event.target.files && event.target.files.length !== 0) {
            const file = event.target.files[0]

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }
            reader(file, (err, res) => {
                setState({
                    privacyPolicy: res,
                    privacyFile: file,
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            })
        }
    }

    const handleColorChange = (name, value) => {
        setState({
            formData: {
                ...formData,
                [name]: value,
            },
            formValidation: {
                ...formValidation,
                isFormDirty: true,
            },
        })
    }

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormSection
                                size={12}
                                title="Manufacturer Logo"
                                reset={
                                    <Tooltip title={'Reset Media'}>
                                        <IconButton onClick={handleLogoReset}>
                                            <RestartAltIcon
                                                sx={{ fontSize: 30 }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                <Grid item xs={12}>
                                    <label htmlFor="raised-button-file">
                                        <Tooltip
                                            title={
                                                logo
                                                    ? 'Replace Logo'
                                                    : 'Upload Logo'
                                            }
                                        >
                                            <center>
                                                {logo ? (
                                                    <Button component="label">
                                                        <Card
                                                            sx={{
                                                                maxWidth: 345,
                                                            }}
                                                        >
                                                            <CardMedia
                                                                component="img"
                                                                height="150"
                                                                image={logo}
                                                                alt="Manfacturer Logo"
                                                            />
                                                            <VisuallyHiddenInput
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleFileSelect(
                                                                        event,
                                                                        true,
                                                                        false
                                                                    )
                                                                }
                                                                type="file"
                                                                accept="image/*"
                                                                id="raised-button-file"
                                                                disabled={
                                                                    permissionCheck
                                                                }
                                                            />
                                                        </Card>
                                                    </Button>
                                                ) : (
                                                    <IconButton component="label">
                                                        <PhotoIcon
                                                            sx={{
                                                                fontSize: 40,
                                                            }}
                                                        />
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event,
                                                                    true,
                                                                    false
                                                                )
                                                            }
                                                            type="file"
                                                            accept="image/*"
                                                            id="raised-button-file"
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </IconButton>
                                                )}
                                            </center>
                                        </Tooltip>
                                    </label>
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={6}>
                            <FormSection
                                title="
                                Manufacturer Login Video"
                                reset={
                                    <Tooltip title={'Reset Media'}>
                                        <IconButton onClick={handleVideoReset}>
                                            <RestartAltIcon
                                                sx={{ fontSize: 30 }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                <Grid item xs={12}>
                                    <label htmlFor="raised-button-file-video">
                                        <Tooltip
                                            title={
                                                loginVideo
                                                    ? 'Replace Current Login Video'
                                                    : 'Upload Login Video'
                                            }
                                        >
                                            <center>
                                                {loginVideo ? (
                                                    <Button component="label">
                                                        <Card
                                                            sx={{
                                                                maxWidth: 345,
                                                            }}
                                                        >
                                                            <CardMedia
                                                                component="video"
                                                                height="150"
                                                                title="Login Video"
                                                                controls
                                                            >
                                                                <source
                                                                    src={
                                                                        loginVideo
                                                                    }
                                                                    type="video/mp4"
                                                                />
                                                                Your browser
                                                                does not support
                                                                the video tag.
                                                            </CardMedia>
                                                            <VisuallyHiddenInput
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleFileSelect(
                                                                        event,
                                                                        false,
                                                                        true
                                                                    )
                                                                }
                                                                type="file"
                                                                accept="video/*"
                                                                id="raised-button-file-video"
                                                                disabled={
                                                                    permissionCheck
                                                                }
                                                            />
                                                        </Card>
                                                    </Button>
                                                ) : (
                                                    <IconButton component="label">
                                                        <VideoLibraryIcon
                                                            sx={{
                                                                fontSize: 40,
                                                            }}
                                                        />
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event,
                                                                    false,
                                                                    true
                                                                )
                                                            }
                                                            type="file"
                                                            accept="video/*"
                                                            id="raised-button-file-video"
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </IconButton>
                                                )}
                                            </center>
                                        </Tooltip>
                                    </label>
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <FormSection
                        title="Manufacturer Documents"
                        spacing={1}
                        reset={
                            <Tooltip title={'Reset Documents'}>
                                <IconButton onClick={handleDocumentReset}>
                                    <RestartAltIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={
                                        <Tooltip title={'Show EULA Preview'}>
                                            <ExpandMoreIcon />
                                        </Tooltip>
                                    }
                                >
                                    <IconButton component="label">
                                        <Tooltip
                                            title={
                                                eula
                                                    ? 'Replace Current EULA'
                                                    : 'Upload EULA'
                                            }
                                        >
                                            <DriveFolderUploadIcon
                                                sx={{ fontSize: 40 }}
                                            />
                                        </Tooltip>

                                        <input
                                            type="file"
                                            accept=".pdf"
                                            onChange={handleEulaUpload}
                                            style={{ display: 'none' }}
                                        />
                                    </IconButton>
                                    <Typography
                                        variant="h5"
                                        color={'primary'}
                                        alignContent="center"
                                    >
                                        EULA PDF
                                        {eula && eula.length !== 0 ? (
                                            <CheckIcon />
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container sx={{ height: '100vh' }}>
                                        <iframe
                                            title="EULA"
                                            width="100%"
                                            height="auto"
                                            style={{ overflow: 'scroll' }}
                                            src={eula}
                                        ></iframe>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={12}></Grid>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={
                                        <Tooltip
                                            title={
                                                'Show Privacy Policy Preview'
                                            }
                                        >
                                            <ExpandMoreIcon />
                                        </Tooltip>
                                    }
                                >
                                    <IconButton component="label">
                                        <Tooltip
                                            title={
                                                eula
                                                    ? 'Replace Current Privacy Policy'
                                                    : 'Upload Private Policy'
                                            }
                                        >
                                            <DriveFolderUploadIcon
                                                sx={{ fontSize: 40 }}
                                            />
                                        </Tooltip>

                                        <input
                                            type="file"
                                            accept=".pdf"
                                            onChange={handlePrivacyUpload}
                                            style={{ display: 'none' }}
                                        />
                                    </IconButton>
                                    <Typography
                                        variant="h5"
                                        color={'primary'}
                                        alignContent={'center'}
                                    >
                                        Privacy Policy PDF
                                        {privacyPolicy &&
                                        privacyPolicy.length !== 0 ? (
                                            <CheckIcon />
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container sx={{ height: '100vh' }}>
                                        <iframe
                                            title="Privacy Policy"
                                            width="100%"
                                            height="auto"
                                            style={{ overflow: 'scroll' }}
                                            src={privacyPolicy}
                                        ></iframe>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={4}>
                    <FormSection
                        title="Manufacturer Color Palette"
                        spacing={1}
                        reset={
                            <Tooltip title={'Reset Color Palette'}>
                                <IconButton onClick={handleColorReset}>
                                    <RestartAltIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <Grid item xs={12} padding={2}>
                            <MuiColorInput
                                value={primaryColor}
                                onChange={(value) =>
                                    handleColorChange('primaryColor', value)
                                }
                                label="Primary Color"
                                name="primaryColor"
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={2}>
                            <MuiColorInput
                                value={secondaryColor}
                                onChange={(value) =>
                                    handleColorChange('secondaryColor', value)
                                }
                                label="Secondary Color"
                                name="secondaryColor"
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} padding={2}>
                            <MuiColorInput
                                value={tertiaryColor}
                                onChange={(value) =>
                                    handleColorChange('tertiaryColor', value)
                                }
                                label="Tertiary Color"
                                name="tertiaryColor"
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ManufacturerConfigurationForm
