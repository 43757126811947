import { Document, Page, pdfjs } from 'react-pdf'
import { useCallback, useState } from 'react'
import { useResizeObserver } from '@wojtekmaj/react-hooks'
import { Grid, Typography } from '@mui/material'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import './styles/universalPdfViewer.css'

/// For external CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

interface UniversalPdfViewerProps {
    url: string
}

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
}

const resizeObserverOptions = {}

const maxWidth = 800

type PDFFile = string | File | null
const UniversalPdfViewer = ({ url }: UniversalPdfViewerProps) => {
    const [file, setFile] = useState<PDFFile>(url)
    const [numPages, setNumPages] = useState<number>()
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
    const [containerWidth, setContainerWidth] = useState<number>()

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries

        if (entry) {
            setContainerWidth(entry.contentRect.width)
        }
    }, [])

    useResizeObserver(containerRef, resizeObserverOptions, onResize)

    function onFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { files } = event.target

        const nextFile = files?.[0]

        if (nextFile) {
            setFile(nextFile)
        }
    }

    function onDocumentLoadSuccess({
        numPages: nextNumPages,
    }: PDFDocumentProxy): void {
        setNumPages(nextNumPages)
    }
    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                className="pdfViewerContainer"
                ref={setContainerRef}
            >
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                >
                    <Typography variant="h4" color={'primary'}>
                        Pages Total: {numPages}
                    </Typography>
                    {Array.from(new Array(numPages), (element, index) => (
                        <>
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={
                                    containerWidth
                                        ? Math.min(containerWidth, maxWidth)
                                        : maxWidth
                                }
                            />
                            <Typography
                                variant="h6"
                                color={'primary'}
                                sx={{ margin: 2, textAlign: 'left' }}
                            >
                                PAGE: {index + 1}
                            </Typography>
                        </>
                    ))}
                </Document>
            </Grid>
        </Grid>
    )
}

export default UniversalPdfViewer
