import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    addEngine,
    getManufacturers,
    getEngineModels,
} from '../../../../apiRoutes'
import { getCalibrationFiles } from '../../../../apiRoutes'
import { useMinimizer } from '../../../../context/MinimizeContext'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { FormSection } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
import { UniversalAutoComplete } from '../../../UniversalComponents/UniversalFormStyles'

const EngineManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    minimizeModal,
    permissionCheck,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { setMinimized, restoredData } = useMinimizer()

    const { username } = currentUser.auth
    const defaultState = {
        formData: {
            manufacturer: row.manufacturerName || '',
            engineModel: row.engineModelName
                ? `${row.engineModelName} - ${row.engineModelYear}`
                : '',
            engineSerial: row.engineSerial || '',
            hardwarePartNumber: row.hardwarePartNumber || '',
            ecmPartNumber: row.ecmPartNumber || '',
            ecmSerial: row.ecmSerial || '',
            calibration: row.calibrationFileName || '',
            updatedAt: row.updatedAt || '',
            updatedBy: row.updatedBy || '',
            archived: row.archived || false,
            id: row.id || '',
        },
        formValidation: {
            notificationNameValidation: false,
            engineSerialValidation: false,
            hardwarePartNumber: false,
            signalNameValidation: false,
            manufacturerValidation: false,
            engineModelValidation: false,
            engineHardwarePartNumberValidation: false,
            ecmSerialValidation: false,
            ecmPartNumberValidation: false,
            calibrationValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        closeModal: false,
        calibrationFiles: [],
        calibrationFileOptions: [],
        manufacturers: [],
        engineModels: [],
        manufacturerOptions: [],
        engineModelOptions: [],
        formCache: {},
        selectedModelFile: undefined,
        permissionsCheck: permissionCheck,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        calibrationFiles,
        calibrationFileOptions,
        formValidation,
        formCache,
        manufacturerOptions,
        engineModelOptions,
        manufacturers,
        engineModels,
    } = state

    const {
        engineModel,
        engineSerial,
        hardwarePartNumber,
        ecmSerial,
        ecmPartNumber,
        manufacturer,
        calibration,
    } = formData

    const {
        manufacturerValidation,
        engineSerialValidation,
        engineModelValidation,
        engineHardwarePartNumberValidation,
        ecmSerialValidation,
        ecmPartNumberValidation,
        calibrationValidation,
        hardwarePartNumberValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleSubmit = async () => {
        try {
            const findCalibration = calibrationFiles.find(
                ({ calibrationFileName }) =>
                    calibrationFileName === formData.calibration
            )

            const findManufacturer = manufacturers.find(
                ({ manufacturerName }) =>
                    manufacturerName === formData.manufacturer
            )

            const findEngineModel = engineModels.find(
                (model) =>
                    `${model.engineModelName} - ${model.engineModelYear}` ===
                    formData.engineModel
            )

            const parsedFormData = {
                ...formData,
                calibration: findCalibration,
                manufacturer: findManufacturer,
                engineModel: findEngineModel,
            }

            let res: any
            {
                res = await addEngine(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `Engine Model: ${data.engineModel} was ${
                                row.id ? 'edited' : 'added'
                            } successfully`,
                            'success'
                        )

                        onClose(row.engineModel, data)
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'manufacturer') {
            const newEngineModelOptions = engineModels
                .filter(
                    (model) => model.manufacturer.manufacturerName === value
                )
                .map(
                    ({ engineModelName, engineModelYear }) =>
                        `${engineModelName} - ${engineModelYear}`
                )

            setState({
                formValidation: {
                    ...formValidation,
                    engineModelValidation: false,
                },
                formData: {
                    ...formData,
                    manufacturer: value,
                    engineModel: '',
                },
                engineModelOptions: newEngineModelOptions,
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
        saveCache({ ...formData, [name]: value })
    }

    const getData = async () => {
        let calibrationFiles: any = []
        let calibrationFileOptions: any = []
        let manufacturers: any = []
        let manufacturerOptions: any = []
        let engineModels: any = []
        let engineModelOptions: any = []

        try {
            const getCalibrationFileData = await getCalibrationFiles(true)
            const getManufacturerData = await getManufacturers(true)
            const getEngineModelData = await getEngineModels(true)

            if (getCalibrationFileData && getCalibrationFileData.length !== 0) {
                calibrationFiles = getCalibrationFileData
                calibrationFileOptions = calibrationFiles.map(
                    ({ calibrationFileName }) => calibrationFileName
                )
            }

            if (getManufacturerData && getManufacturerData.length !== 0) {
                manufacturers = getManufacturerData.filter(
                    (manufacturer) => manufacturer.type === 'engine'
                )
                manufacturerOptions = manufacturers.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }

            if (getEngineModelData && getEngineModelData.length !== 0) {
                engineModels = getEngineModelData
                engineModelOptions = engineModels
                    .filter(
                        (model) =>
                            model.manufacturer.manufacturerName === manufacturer
                    )
                    .map(
                        ({ engineModelName, engineModelYear }) =>
                            `${engineModelName} - ${engineModelYear}`
                    )
            }

            setState({
                calibrationFiles,
                calibrationFileOptions,
                manufacturers,
                manufacturerOptions,
                engineModels,
                engineModelOptions,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !hardwarePartNumberValidation &&
            !manufacturerValidation &&
            !engineModelValidation &&
            !ecmPartNumberValidation &&
            !calibrationValidation &&
            hardwarePartNumber &&
            manufacturer &&
            engineModel &&
            ecmPartNumber &&
            calibration
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerValidation,
        engineModelValidation,
        engineHardwarePartNumberValidation,
        ecmPartNumberValidation,
        calibrationValidation,
        hardwarePartNumberValidation,
        manufacturer,
        engineModel,
        hardwarePartNumber,
        ecmPartNumber,
        calibration,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1, marginTop: 3 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {row.engineModel
                        ? 'Use this form to edit an Engine Model within Osmosis Telematics Platform'
                        : 'Use this form to add an Engine Model within Osmosis Telematics Platform'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <UniversalTagToolbar />
            </Grid>

            <Grid item xs={12} md={8}>
                <FormSection title="Engine Manufacturer Information">
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="manufacturer"
                            options={manufacturerOptions}
                            value={manufacturer}
                            fullWidth
                            disabled={permissionCheck}
                            onChange={(event, newValue) => {
                                const data = {
                                    target: {
                                        name: 'manufacturer',
                                        value: newValue,
                                    },
                                }
                                handleChange(data)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Engine Manufacturer"
                                    name="manufacturer"
                                    onChange={handleChange}
                                    required
                                    disabled={permissionCheck}
                                    value={manufacturer}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={manufacturerValidation}
                                    helperText={
                                        manufacturerValidation
                                            ? 'Please enter a manufacturer name.'
                                            : ''
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="engineModel"
                            options={engineModelOptions}
                            value={engineModel}
                            fullWidth
                            disabled={
                                permissionCheck || manufacturer ? false : true
                            }
                            onChange={(event, newValue) => {
                                const data = {
                                    target: {
                                        name: 'engineModel',
                                        value: newValue,
                                    },
                                }
                                handleChange(data)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Engine Model"
                                    name="engineModel"
                                    onChange={handleChange}
                                    required
                                    disabled={
                                        permissionCheck || manufacturer
                                            ? false
                                            : true
                                    }
                                    value={engineModel}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={engineModelValidation}
                                    helperText={
                                        engineModelValidation
                                            ? 'Please enter a manufacturer name.'
                                            : ''
                                    }
                                />
                            )}
                        />
                    </Grid>
                </FormSection>

                <FormSection title="Engine Information">
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="Engine Serial"
                                    name="engineSerial"
                                    onChange={handleChange}
                                    value={engineSerial}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={engineSerialValidation}
                                    helperText={
                                        engineSerialValidation
                                            ? 'Please enter an Engine Serial.'
                                            : ''
                                    }
                                    disabled={permissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="Engine Hardware Part Number"
                                    name="hardwarePartNumber"
                                    onChange={handleChange}
                                    value={hardwarePartNumber}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={hardwarePartNumberValidation}
                                    helperText={
                                        hardwarePartNumberValidation
                                            ? 'Please enter a hardware part number.'
                                            : ''
                                    }
                                    disabled={permissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="ECM Serial"
                                    name="ecmSerial"
                                    onChange={handleChange}
                                    value={ecmSerial}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={ecmSerialValidation}
                                    helperText={
                                        ecmSerialValidation
                                            ? 'Please enter an ECM Serial.'
                                            : ''
                                    }
                                    disabled={permissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="ECM Part Number"
                                    name="ecmPartNumber"
                                    onChange={handleChange}
                                    value={ecmPartNumber}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={ecmPartNumberValidation}
                                    helperText={
                                        ecmPartNumberValidation
                                            ? 'Please enter an ECM part number.'
                                            : ''
                                    }
                                    disabled={permissionCheck}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormSection title="Engine Calibration">
                    <Grid item xs={12}>
                        <UniversalAutoComplete
                            disabled={permissionCheck}
                            fullWidth
                            options={calibrationFileOptions}
                            value={calibration}
                            handleChange={handleChange}
                            label="Calibration File"
                            name="calibration"
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={calibrationValidation}
                            helperText={
                                calibrationValidation
                                    ? 'Please select a valid calibration file.'
                                    : ''
                            }
                            required
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={12}>
                <FormSection
                    title="Required Fields Are Marked With An *"
                    titleAlignment="center"
                >
                    <Grid item xs={7}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!isFormValid}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            Save & Close
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleReset}
                            disabled={!isFormDirty}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    )
}

export default EngineManagerForm
