import { Grid } from '@mui/material'
import faqPdf from '../../assets/osmosis/Files/Osmosis_FAQ_v2.pdf'

const FAQ = () => {
    return (
        <Grid container sx={{ height: '100vh' }}>
            <iframe
                title="FAQ"
                width="100%"
                height="auto"
                style={{ overflow: 'scroll' }}
                src={faqPdf}
            ></iframe>
        </Grid>
    )
}

export default FAQ
