/* eslint-disable */
import { createContext, useState, useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import themes from './ThemeFiles'

export const ThemeContext = createContext({})
export const ThemeSelector = createContext(null)

interface DefaultState {
    theme?: any
}

export const ThemeBuilder = ({ children }) => {
    const defaultState: DefaultState = {
        theme: {},
    }
    const [state, setNewState] = useState(defaultState)
    const { theme } = state
    const setState = (newState: object) =>
        setNewState({ ...state, ...newState })

    const setTheme = (themeName: string) => {
        const findTheme = themes.find(({ name }) => name === themeName)

        if (findTheme && findTheme.theme) {
            setState({ theme: findTheme.theme })
        } else {
            setState({ theme: themes[0].theme })
        }
    }

    useEffect(() => {
        setTheme('defaultTheme')
    }, [])

    return (
        <ThemeContext.Provider value={theme}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}
