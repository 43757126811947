import { useReducer, useEffect, useContext } from 'react'

import {
    UniversalModal,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalToolBar,
    FormSection,
} from '../../../UniversalComponents'
import { Grid, Typography } from '@mui/material'

import {
    Archive as ArchiveIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import StraightenIcon from '@mui/icons-material/Straighten'
import { Authentication } from '../../../../context/AuthenticationContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import UnitOfMeasurementForm from './UnitOfMeasurementForm'
import {
    deactivateMeasurements,
    deleteMeasurements,
    getMeasurement,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const UnitOfMeasureManager = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        viewArchived: false,
        archive: [],
        viewDeactivated: false,
        activateData: [],
        data: [],
    }

    const [state, setState] = useReducer(reducer, defaultState)

    const { open, row, formCache, viewDeactivated, data, activateData } = state

    const { setMinimized } = useMinimizer()
    const currentUser = useContext(Authentication)
    const setToastMessage = useContext(ToastMessage)
    const { username, userRole, activeManufacturer } = currentUser.auth
    const userPermissions = usePermissions()

    const getData = async (active) => {
        try {
            const measurements = await getMeasurement(active)
            if (measurements && measurements.length !== 0) {
                const unitOfMeasurements = standardColumnMapper(
                    defaultColumns,
                    measurements
                )

                setState({ data: unitOfMeasurements })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }
    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'unitOfMeasurement',
            setState,
            getData,
            route: deactivateMeasurements,
            viewDeactivated,
            setToastMessage,
            page: 'measurements',
        })
    }

    // returnKey is the key of the value wanting to be returned.. example manufacturerName: 'Osmosis'
    // route is the API route function name being imported into index. example deactivateManufacturers
    // page is the object being defined in the API route for example const { manufacturers, updatedBy, activate } = req.body
    // manufactuers is the page

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'unitOfMeasurement',
            setState,
            getData,
            route: deleteMeasurements,
            viewDeactivated,
            setToastMessage,
            page: 'measurements',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Measurements?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.unitOfMeasurement) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.unitOfMeasurement
                ? `Edit: ${formCache.unitOfMeasurement}`
                : 'Add New Unit of Measure',
            link: '/data-hub/signals',
            data: formCache.id
                ? { ...formCache, tab: 'unitOfMeasurement' }
                : {
                      ...formCache,
                      id: 'unitOfMeasurement',
                      tab: 'unitOfMeasurement',
                  },
            id: formCache.id ? formCache.id : 'unitOfMeasurement',
        })
        setState({ open: false })
    }
    const defaultColumns = [
        'unitOfMeasurement',
        'unitOfMeasurementAbrv',
        'updatedBy',
        'updatedAt',
        'User.userEmail',
        'archived',
    ]

    const visibleColumns = ['unitOfMeasurement', 'unitOfMeasurementAbrv']

    const customColumnNames = ['UOM Full', 'UOM Short']

    const buttons = [
        {
            buttonName: 'Add New Unit Of Measurement',
            buttonIcon: <StraightenIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Measurements'
                : 'View Deactivated Measurements',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Delete Sensor',
        //     buttonIcon: <DeleteIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? ' Unit of Measurement'
                                : 'Deactivated Measurements'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={!viewDeactivated ? getData : () => {}}
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Measurements Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        visibleColumns={visibleColumns}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        customColumnNames={customColumnNames}
                        onRowSelectionModelChange={(newSelection) => {
                            setState({
                                activateData: newSelection,
                            })
                        }}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={`${
                    row.unitOfMeasurement
                        ? ` ${row.unitOfMeasurement}`
                        : 'Unit of Measurement Manager'
                }`}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <UnitOfMeasurementForm
                    row={row}
                    formDescription={
                        row.signalName
                            ? 'Use this form to edit an Unit of Measurement within Osmosis Telematics Platform'
                            : 'Use this form to add a new Unit of Measurement within Osmosis Telematics Platform'
                    }
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Signals'
                    )}
                />
            </UniversalModal>
        </Grid>
    )
}

export default UnitOfMeasureManager
