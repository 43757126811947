import { useContext, useEffect, useReducer } from 'react'
import { getLatestPatchNotes, getPatchNotes } from '../../apiRoutes'
import { UniversalModal } from '../../components/UniversalComponents'
import { Typography, Grid, List, ListItem, Button } from '@mui/material'
import { datePickerConverter } from '../../utilities/functionTools'
import DOMPurify from 'dompurify'

const VersionCheckerModal = ({ open, onClose }) => {
    const defaultState = {
        data: {},
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { data } = state

    const getData = async (active: boolean) => {
        try {
            const patchNotes = await getLatestPatchNotes('web')

            if (patchNotes.length !== 0) {
                setState({ data: patchNotes[0] })
            } else {
                setState({ data: {} })
            }
        } catch (err: any) {
            console.error(err, 'error')
        }
    }

    useEffect(() => {
        getData(true)
    }, [open])

    const currentVersion = data?.version
    const patchDate = datePickerConverter(data?.releaseDate)
    const currentNotes = DOMPurify.sanitize(data?.patchDetails)
    const upcomingPatch = data?.upcomingPatchVersion
    const upcomingPatchNotes = data?.upcomingPatchDetails
    return (
        <UniversalModal
            title={`Whats new in version: ${currentVersion} released on ${patchDate}`}
            open={open}
            onClose={onClose}
        >
            <Grid container>
                <Typography
                    variant="h2"
                    color={'primary'}
                    align="left"
                    sx={{ margin: 5 }}
                >
                    {`Patch Notes for Version: ${currentVersion}, Released on: ${patchDate
                        .toString()
                        .substring(0, 17)}`}
                </Typography>
                <Grid item xs={12}>
                    <List
                        sx={{
                            color: 'inherit',
                        }}
                    >
                        {currentNotes && currentNotes.length !== 0 && (
                            <ListItem>
                                <Typography
                                    variant="h4"
                                    color="white"
                                    sx={{ marginLeft: 10 }}
                                    dangerouslySetInnerHTML={{
                                        __html: currentNotes,
                                    }}
                                />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                {upcomingPatch && upcomingPatch.length !== 0 && (
                    <>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="left"
                            sx={{ margin: 5 }}
                        >
                            {`Upcoming for patch: ${upcomingPatch}`}
                        </Typography>
                        <Grid item xs={12}>
                            <List
                                sx={{
                                    color: 'inherit',
                                }}
                            >
                                {upcomingPatchNotes &&
                                    upcomingPatchNotes.length !== 0 && (
                                        <ListItem>
                                            <Typography
                                                variant="h4"
                                                color="white"
                                                align="center"
                                                sx={{ marginLeft: 10 }}
                                            >
                                                {upcomingPatchNotes}
                                            </Typography>
                                        </ListItem>
                                    )}
                            </List>
                        </Grid>
                    </>
                )}
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={onClose}
                    sx={{ margin: 10 }}
                >
                    Close
                </Button>
            </Grid>
        </UniversalModal>
    )
}

export default VersionCheckerModal
