import buildRoute from './buildRoute'

export const getSignals = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signals',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const addSignal = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'signal',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const editSignal = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'update-signal',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
interface SelectionType {
    signals: []
    activate: boolean
}
export const activateSignals = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    signals: []
    activate: boolean
}
export const deleteSignals = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'signal-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const signalRoutes = {
    getSignals,
    addSignal,
    editSignal,
    activateSignals,
    deleteSignals,
}

export default signalRoutes
