import { useReducer, createContext, useContext } from 'react'

type Props = {
    children: React.ReactNode
}

export const Minimizer = createContext({
    minimizedModals: [],
    setMinimized: (minimize: boolean, data: any, clearData?: boolean) => {},
    restoredData: { id: -1, tab: '' },
})

export const MinimizerContext = ({ children }: Props) => {
    const defaultState = {
        minimizedModals: [],
        restoredData: {},
    }
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { minimizedModals, restoredData } = state
    const setMinimized = (
        minimize: boolean,
        data: any,
        clearData?: boolean
    ) => {
        if (minimize) {
            const newMinimizedModals = [...minimizedModals]
            const findDuplicateModal = newMinimizedModals.findIndex(
                (item) => item.id === data.id
            )
            if (findDuplicateModal === -1) {
                newMinimizedModals.push(data)
            } else {
                newMinimizedModals[findDuplicateModal] = data
            }

            setState({ minimizedModals: newMinimizedModals })
        } else {
            const newMinimizedModals = [...minimizedModals]
            if (newMinimizedModals.length !== 0 && !clearData) {
                const restoreData = newMinimizedModals.find(
                    (item) => item.id === data
                )
                const filteredModals = newMinimizedModals.filter(
                    (item) => item.id !== data
                )
                setState({
                    minimizedModals: filteredModals,
                    restoredData: restoreData.data,
                })
            } else {
                const filteredModals = newMinimizedModals.filter(
                    (item) => item.id !== data
                )
                setState({
                    minimizedModals: filteredModals,
                    restoredData: {},
                })
            }
        }
    }
    return (
        <Minimizer.Provider
            value={{ minimizedModals, restoredData, setMinimized }}
        >
            {children}
        </Minimizer.Provider>
    )
}

export const useMinimizer = () => useContext(Minimizer)
