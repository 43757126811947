import buildRoute from './buildRoute'

export const getDealers = async (active: boolean) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'dealers',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addDealer = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'dealer',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const archiveDealers = async (dealer: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'archive-dealer',
            data: dealer,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const deleteDealers = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'dealer-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    dealers: []
    activate: boolean
}

export const activateDealers = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'dealer-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const dealerRoutes = {
    getDealers,
    addDealer,
    archiveDealers,
    deleteDealers,
    activateDealers,
}

export default dealerRoutes
