interface osmosisConfigTypes {
    brand: String
    API_URL: String
}

const { REACT_APP_ENV } = process.env
let API_URL

if (REACT_APP_ENV === 'DEV') {
    API_URL = 'http://localhost:8080'
} else if (REACT_APP_ENV === 'TEST') {
    API_URL = 'https://osmosis-os-api-test.azurewebsites.net'
} else if (REACT_APP_ENV === 'PROD') {
    API_URL = 'https://osmosis-os-api.azurewebsites.net'
} else {
    API_URL = 'https://osmosis-os-api-test.azurewebsites.net'
}
// eslint-disable-next-line
console.info(
    `You're using the ${REACT_APP_ENV} environment with api url: ${API_URL}`
)

const osmosisConfig: osmosisConfigTypes = {
    brand: 'osmosis',
    API_URL,
}

export const osmosisFirebaseConfig = {
    apiKey: 'AIzaSyAMvGRtEe4uRYC6C9fDd6cSV-HA-EWzbP0',
    authDomain: 'osmosis-os.firebaseapp.com',
    projectId: 'osmosis-os',
}

export const osmosisGoogleMapApi = 'AIzaSyAERTQkwwnvEkMk5d5GeTco5setwPSpLHo'

export default osmosisConfig
