import { useReducer, useEffect, useContext } from 'react'
import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalLoadingCircle,
    UniversalExcelExport,
    DataGridLayout,
    FormSection,
} from '../../../UniversalComponents'
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import UniversalCookieCrumb from '../../../UniversalComponents/UniversalCookieCrumb'
import {
    PersonAdd as PersonAddIcon,
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    Restore,
} from '@mui/icons-material/'
import { getUserNotificationSubscriptions } from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import UserNotificationSubscriptionForm from './UserNotificationSubscriptionForm'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import { standardColumnMapper } from '../../../../utilities/functionTools'
import universalExcelExport from '../../../UniversalComponents/UniversalExcelExport'

const today = new Date().toISOString().substring(0, 10)

const UserNotificationSubscription = () => {
    const currentUser = useContext(Authentication)
    const { setUser } = useAuth()
    const { username, userPreferences } = currentUser.auth

    const defaultState = {
        row: {},
        open: false,
        filteredUserNotificationSubscriptions: [],
        excelSelectedRows: [],
        users: {
            userNotificationSubscription: {
                columns: [
                    {
                        field: 'subscriptionName',
                        headerName: 'SUBSCRIPTION NAME',
                        width: 150,
                    },
                    {
                        field: 'updatedAt',
                        headerName: 'UPDATED AT',
                        width: 150,
                    },
                    {
                        field: 'updatedBy',
                        headerName: 'UPDATED BY',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.users
                ?.userNotificationSubscription?.density || 'standard',
    }
    const setToastMessage = useContext(ToastMessage)
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        formCache,
        filteredUserNotificationSubscriptions,
        excelSelectedRows,
        users,
        density,
    } = state

    const { userNotificationSubscription } = users

    const getData = async (viewArchive?: boolean) => {
        try {
            const userNotificationSubscriptions =
                await getUserNotificationSubscriptions()
            if (
                userNotificationSubscriptions &&
                userNotificationSubscriptions.length !== 0
            ) {
                const dataMapping = standardColumnMapper(
                    defaultColumns,
                    userNotificationSubscriptions
                )

                setState({ filteredUserNotificationSubscriptions: dataMapping })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const users =
            userPreferences?.dataTableColumns?.users
                ?.userNotificationSubscription?.columns

        if (Array.isArray(users)) {
            const mapColumns = users.map((col) => ({
                field: col?.field,
                headerName: col?.headerName,
                width: col?.width,
                isVisible: col?.isVisible,
            }))

            const format = {
                userNotificationSubscription: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, users: format })
        } else {
            console.log('No Notes Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'users',
                value: users,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        collectColumnData()
        getData()
    }, [])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.id) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        if (newData !== undefined) {
            return getData()
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.subscriptionName
                ? `Edit: ${formCache.subscriptionName}`
                : 'Add New User',
            link: 'admin/users',
            data: formCache.id
                ? formCache
                : { ...formCache, id: 'subscriptionName' },
            id: formCache.id ? formCache.id : 'subscriptionName',
        })
        setState({ open: false, row: defaultState.row })
    }

    const handleTextFieldChange = (event: any) =>
        setState({ excelExportName: event.target.value })

    const buttons = [
        {
            buttonName: 'Add New User Notification Subscription',
            buttonIcon: <PersonAddIcon />,
            buttonFunction: setModalVisbility,
        },
    ]

    const defaultColumns = [
        'subscriptionName',
        'subscribedUsers',
        'updatedAt',
        'updatedBy',
    ]

    const visibleColumns = ['subscriptionName', 'updatedAt', 'updatedBy']

    const customColumnNames = ['Subscription Name', 'Updated At', 'Updated By']

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = {
            userNotificationSubscription: {
                columns: format,
                density: setDensity,
            },
        }

        setState({
            users: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            users: {
                userNotificationSubscription: {
                    ...userNotificationSubscription,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            User Notification Subscriptions
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={filteredUserNotificationSubscriptions}
                    reloadFunction={getData}
                    customTimeoutMessage="No Archived Files Available"
                >
                    <UniversalDataTable
                        data={filteredUserNotificationSubscriptions}
                        apiDensity={density}
                        apiColumns={
                            users?.userNotificationSubscription?.columns
                        }
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({ archive: newSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={row.id ? `User: ${row.id}` : 'Configure User Account'}
                timeStamp={`${
                    row.userEmail
                        ? ` Modified By: ${row.updatedBy} ${
                              row.updateAt
                                  ? `| Modified Date ${row.updateAt}`
                                  : ''
                          }  `
                        : ` Created by: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <UserNotificationSubscriptionForm
                    row={row}
                    formDescription={
                        'Use this form to add a new user account within the Osmosis Telematics Platform'
                    }
                    onClose={(tableRow: any, newData?: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    minimizeModal={minimizeModal}
                />
            </UniversalModal>
        </Grid>
    )
}

export default UserNotificationSubscription
