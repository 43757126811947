import { Grid, Typography } from '@mui/material'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import {
    Archive as ArchiveIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import { useContext, useEffect, useReducer } from 'react'
import {
    FormSection,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../../UniversalComponents'
import BoatManufacturerForm from './boatManufacturerForm'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    deactivateManufacturers,
    deleteManufacturer,
    getManufacturers,
} from '../../../../apiRoutes'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'
import { permissionsCheck } from '../../../../utilities/functionTools'

const today = new Date().toISOString().substring(0, 10)

const BoatManufacturer = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, activeManufacturer, userRole, userPreferences, _id } =
        currentUser.auth
    const userPermissions = usePermissions()

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        activateData: [],
        viewDeactivated: false,
        data: [],
        boats: {
            boatManufacturers: {
                columns: [
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 150,
                    },
                    {
                        field: 'manufacturerContactName',
                        headerName: 'CONTACT NAME',
                        width: 150,
                    },
                    {
                        field: 'manufacturerContactEmail',
                        headerName: 'EMAIL',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.boats?.boatManufacturers
                ?.density || 'standard',
    }

    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        formCache,
        viewDeactivated,
        data,
        activateData,
        boats,
        density,
    } = state

    const { boatManufacturers } = boats

    const getData = async (active) => {
        try {
            const manufacturers = await getManufacturers(active)

            if (manufacturers && manufacturers.length !== 0) {
                const filterManufacturers = manufacturers.filter(
                    (manufacturer) => manufacturer.type === 'boat'
                )
                const boatManufacturers = standardColumnMapper(
                    defaultColumns,
                    filterManufacturers
                )

                setState({ data: boatManufacturers })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const boats =
            userPreferences?.dataTableColumns?.boats?.boatManufacturers?.columns

        if (Array.isArray(boats)) {
            const mapColumns = boats.map((boat) => ({
                field: boat?.field,
                headerName: boat?.headerName,
                width: boat?.width,
                isVisible: boat.isVisible,
            }))

            const format = {
                boatManufacturers: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, boats: format })
        } else {
            console.log('No Boats Found')
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'boats',
                value: boats,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'manufacturerName',
            setState,
            getData,
            route: deactivateManufacturers,
            viewDeactivated,
            setToastMessage,
            page: 'manufacturers',
        })
    }

    // returnKey is the key of the value wanting to be returned.. example manufacturerName: 'Osmosis'
    // route is the API route function name being imported into index. example deactivateManufacturers
    // page is the object being defined in the API route for example const { manufacturers, updatedBy, activate } = req.body
    // manufactuers is the page

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'manufacturerName',
            setState,
            getData,
            route: deleteManufacturer,
            viewDeactivated,
            setToastMessage,
            page: 'manufacturers',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Manufacturers?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        collectColumnData()
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.manufacturerName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.manufacturerName
                ? `Edit: ${formCache.manufacturerName}`
                : 'Add New Boat Manufacturer',
            link: '/admin/boats',
            data: formCache.id
                ? { ...formCache, tab: 'manufacturer' }
                : { ...formCache, id: 'newManfacturer', tab: 'manufacturer' },
            id: formCache.id ? formCache.id : 'newManufactuer',
        })
        setState({ open: false })
    }

    const defaultColumns = [
        'manufacturerName',
        'manufacturerContactName',
        'manufacturerContactEmail',
        'archived',
        'active',
    ]

    const buttons = [
        {
            buttonName: 'Add Boat Manufacturer',
            buttonIcon: <PrecisionManufacturingIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Boat Manufacturers'
                : 'View Deactivated Boat Manufacturers',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = {
            boatManufacturers: { columns: format, density: setDensity },
        }

        setState({
            boats: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            boats: {
                boatManufacturers: {
                    ...boatManufacturers,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>
            <Grid container sx={{ marginTop: 2, marginBottom: -10 }}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={'primary'}
                            align="center"
                            sx={{ marginTop: -1 }}
                        >
                            {!viewDeactivated
                                ? 'Manufacturers'
                                : 'Deactivated Manufacturers'}
                        </Typography>
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Manufacturers Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={boats?.boatManufacturers?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({ activateData: newSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>

                <UniversalModal
                    title="Boat Manufacturer"
                    open={open}
                    onClose={(tableRow) =>
                        setModalVisbility(tableRow, undefined)
                    }
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 5,
                        marginBottom: 5,
                    }}
                    timeStamp={`${
                        row.updatedBy
                            ? ` ${
                                  row.updatedBy
                                      ? ` Modified By ${row.updatedBy}`
                                      : ''
                              } ${
                                  row.updatedAt
                                      ? `| Modified Date ${row.updatedAt.substring(
                                            0,
                                            10
                                        )}`
                                      : ''
                              }  `
                            : ` Modified By: ${username} | Modified Date: ${today} `
                    }`}
                    onMinimize={minimizeModal}
                    draggable={true}
                    minimizable={true}
                >
                    <BoatManufacturerForm
                        row={row}
                        formDescription="Add a new Boat Manufacturer"
                        onClose={(tableRow: any, newData: any) =>
                            setModalVisbility(tableRow, newData)
                        }
                        permissionCheck={permissionsCheck(
                            userPermissions,
                            'Boats'
                        )}
                    />
                </UniversalModal>
            </Grid>
        </Grid>
    )
}

export default BoatManufacturer
