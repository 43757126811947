import {
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { postSignalFormula } from '../../../../apiRoutes'
import {
    FormSection,
    Autocomplete as UniversalAutocomplete,
    SaveButton,
} from '../../../UniversalComponents'
import { Authentication } from '../../../../context/AuthenticationContext'
import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'

import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
const SignalFormulaManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    permissionCheck,
}) => {
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth
    const setToastMessage = useContext(ToastMessage)

    const defaultState = {
        formData: {
            id: row?.id || '',
            signalFormulaName: row?.signalFormulaName || '',
            signalFormulaType: row?.signalFormulaType || '',
            signalFormula: row?.signalFormula || '',
            customFormula: row?.customFormula || false,
        },
        formValidation: {
            signalFormulaNameValidation: false,
            signalFormulaType: false,
            signalFormulaValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, formValidation } = state

    const {
        signalFormulaName,
        signalFormulaType,
        signalFormula,
        customFormula,
    } = formData

    const {
        signalFormulaNameValidation,
        signalFormulaTypeValidation,
        signalFormulaValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        if (name === 'signalFormula') {
            if (!customFormula) {
                setState({
                    formData: {
                        ...formData,
                        signalFormulaName: value.label,
                        signalFormula: value.formula,
                    },
                })
            } else {
                setState({
                    formData: {
                        ...formData,
                        signalFormula: value,
                    },
                })
            }
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }

        return saveCache({ ...formData, [name]: value })
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
            custom: false,
        })

    const handleSubmit = async () => {
        try {
            const parsedFormData = {
                ...formData,
            }

            let res: any

            res = await postSignalFormula(parsedFormData)

            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Signal: ${data.signalFormulaName} was added successfully`,
                        'success'
                    )

                    onClose(row.signalFormulaName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        saveCache(row)
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !signalFormulaNameValidation &&
            !signalFormulaValidation &&
            !signalFormulaTypeValidation &&
            !signalFormulaValidation &&
            signalFormulaType &&
            signalFormulaName &&
            signalFormula
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        signalFormulaNameValidation,
        signalFormulaValidation,
        signalFormulaType,
        signalFormulaName,
        signalFormula,
        signalFormulaType,
    ])

    const signalFormulaTypes = ['Imperial', 'Metric']
    const signalFormulas: any = [
        { label: 'Farenheit => Celsius', formula: '(n - 32) * 5/9' },
        { label: 'Celsius => Farneheit', formula: 'n * 9/5 + 32' },
        { label: 'Celsius => Kelvin', formula: 'n + 273.15' },
        { label: 'Kelvin => Celisus', formula: 'n - 273.15' },
        { label: 'Farenheit => Kelvin', formula: '(n - 32) * 5/9 + 273.15' },
        { label: 'Kelvin => Farenheit', formula: '(n – 273.15) * 9⁄ 5 + 32' },
        { label: 'Feet => Meters', formula: 'n/3.2808' },
        { label: 'Meters => Feet', formula: 'n * 3.2808' },
        { label: 'Miles => Kilometers', formula: 'n * 1.609344' },
        { label: 'Kilometers => Miles', formula: 'n * 0.621371' },
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 10, marginBottom: -5 }}>
                <Typography align="center" variant="h5" color={'primary'}>
                    {formDescription}
                </Typography>
            </Grid>

            <UniversalTagToolbar />

            <Grid
                container
                spacing={2}
                sx={{ marginTop: -5, marginBottom: -1 }}
            >
                <Grid item xs={12} md={12}>
                    <FormSection title="Signal Formula" titleAlignment="left">
                        <Grid item xs={12} md={8}>
                            <TextField
                                required={true}
                                name="signalFormulaName"
                                label="Signal Formula Name"
                                fullWidth
                                value={signalFormulaName}
                                onChange={handleChange}
                                placeholder="Signal Name will fill in with conversion."
                                disabled={!customFormula}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={signalFormulaNameValidation}
                                helperText={
                                    signalFormulaNameValidation
                                        ? 'Please enter signal formula name.'
                                        : ''
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <UniversalAutocomplete
                                options={signalFormulaTypes}
                                getOptionLabel={(option) => option}
                                value={signalFormulaType}
                                handleChange={handleChange}
                                required={true}
                                label="Signal Formula Type"
                                name="signalFormulaType"
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={signalFormulaTypeValidation}
                                helperText={
                                    signalFormulaTypeValidation
                                        ? 'Please select a signal formula type'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                setState({
                                                    formData: {
                                                        ...formData,
                                                        customFormula:
                                                            !customFormula,
                                                    },
                                                    formValidation: {
                                                        ...formValidation,
                                                        isFormDirty: true,
                                                    },
                                                })
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                            id="switch.customFormula"
                                            name="switch.customFormula"
                                            checked={customFormula}
                                            key="switch.customFormula"
                                        />
                                    }
                                    label="Custom Formula"
                                    key="switch.gpsEnabled"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {!customFormula ? (
                                <UniversalAutocomplete
                                    options={signalFormulas}
                                    getOptionLabel={(option) => option.label}
                                    value={
                                        customFormula
                                            ? 'Custom'
                                            : signalFormulaName
                                    }
                                    handleChange={handleChange}
                                    required={true}
                                    label="Signal Formula"
                                    name="signalFormula"
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={signalFormulaValidation}
                                    helperText={
                                        signalFormulaValidation
                                            ? 'Please select a signal formula or write a custom signal formula.'
                                            : ''
                                    }
                                />
                            ) : (
                                <TextField
                                    name="signalFormula"
                                    label="Signal Formula"
                                    fullWidth
                                    value={signalFormula}
                                    onChange={(e: any) =>
                                        setState({
                                            formData: {
                                                ...formData,
                                                signalFormula: e.target.value,
                                            },
                                        })
                                    }
                                    disabled={permissionCheck}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={signalFormulaValidation}
                                    helperText={
                                        signalFormulaValidation
                                            ? 'Please select a signal formula or write a custom signal formula.'
                                            : ''
                                    }
                                />
                            )}
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>

            <Grid container sx={{ marginTop: 1 }}>
                <SaveButton
                    handleSubmit={handleSubmit || permissionCheck}
                    handleReset={handleReset || permissionCheck}
                    disabledSave={!isFormValid}
                    disabledReset={!isFormDirty}
                />
            </Grid>
        </Grid>
    )
}

export default SignalFormulaManagerForm
