import buildRoute from './buildRoute'

export const getPatchNotes = async (active, patchNoteType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'patch-notes',
            data: { active, patchNoteType },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const getLatestPatchNotes = async (patchNoteType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'get-latest-patch-note',
            data: { patchNoteType },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addPatchNote = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'patch-note',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deactivatePatchNote = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'patchNotes-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deletePatchNote = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'patchNotes-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const patchNoteRoutes = {
    getPatchNotes,
    addPatchNote,
    deactivatePatchNote,
    deletePatchNote,
    getLatestPatchNotes,
}

export default patchNoteRoutes
