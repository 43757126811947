import osmosisConfig from './osmosisConfig'

let currentConfig = osmosisConfig

const findCurrentConfig = (brand: string) => {
    switch (brand) {
        case 'osmosis':
            currentConfig = osmosisConfig
            break
        default:
            currentConfig = osmosisConfig
    }

    return currentConfig
}

findCurrentConfig('osmosis')

export default currentConfig
