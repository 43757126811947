import { Grid } from '@mui/material'
import userGuidePDF from '../../assets/osmosis/Files/userGuide.pdf'

const UserGuidePdf = () => {
    return (
        <Grid container sx={{ height: '100vh' }}>
            <iframe
                title="User Guide"
                width="100%"
                height="auto"
                style={{ overflow: 'scroll' }}
                src={userGuidePDF}
            ></iframe>
        </Grid>
    )
}

export default UserGuidePdf
